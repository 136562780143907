import Vue from 'vue'
import Router from 'vue-router'
import mainAuth from './auth';

import NotFound from './views/NotFound.vue'
import NoAccess from './views/NoAccess.vue'
//import SigninOidc from './views/SigninOidc.vue'
//import SignoutOidc from './views/SignoutOidc.vue'
//import SilentRefresh from './views/SilentRefresh.vue'

//import Default from './views/Default.vue'
//import Index from './views/Index.vue'
import Help from './views/Help.vue'
//import InnLayout from './views/InnLayout.vue'
//import InnIndex from './views/InnIndex.vue'
//import AgentLayout from './views/AgentLayout.vue'
//import AgentIndex from './views/AgentIndex.vue'
//import AgentInnLayout from './views/AgentInnLayout.vue'
import AgentHome from './views/Agent/Home.vue'
import AgentEdit from './views/Agent/AgentEdit.vue'

//import Home from './pages/Home.vue'
//import MenuList from './pages/Menus.vue'
//import UserList from './pages/Users.vue'
//import TagList from './pages/Tags.vue'
////import PanoList from './pages/Panos.vue'
//import TourList from './pages/Manyous.vue'
//import GalleryList from './pages/Gallerys.vue'
//import CommentList from './pages/Comments.vue'
//import AudioList from './pages/Audios.vue'
//import TopItemList from './pages/Topitems.vue'

//import CardLogList from './pages/CardLogs.vue'
//import CardList from './pages/Cards.vue'
//import FacilityList from './pages/Facilitys.vue'
////import RoomList from './pages/Rooms.vue'
//import RoomStockLogList from './pages/RoomStockLogs.vue'
//import PanoList from './views/Pano/List.vue'
//import PanoEdit from './views/Pano/Edit.vue'
//import PanoSet from './views/Pano/Set.vue'
//import RoomApplyList from './pages/roomapply/RoomApplys.vue'
//import RoomApplyItemList from './pages/roomapply/RoomApplyItems.vue'

import InnHome from './views/Inn/Home.vue'
import InnList from './views/Inn/List.vue'
import InnEdit from './views/Inn/Edit.vue'
import InnView from './views/Inn/View.vue'
import InnTour from './views/Inn/Tour.vue'
import InnPay from './views/Inn/Pay.vue'
import InnLicenses from './views/Inn/Licenses.vue'
import InnRoomList from './views/Inn/Room.vue'
import InnRoomEdit from './views/Inn/RoomEdit.vue'
import InnLib from './views/Inn/Lib.vue'
import InnTag from './views/Inn/Tag.vue'
import InnOrderList from './views/Inn/Order.vue'
import RoomStockList from './views/Inn/RoomStock.vue'
import PanoList from './views/Pano/List.vue'
import PanoEdit from './views/Pano/Edit.vue'
import PanoFullScreen from './views/PanoFullScreen.vue'
import BlogList from './views/Blog/List.vue'
import BlogEdit from './views/Blog/Edit.vue'
import BlogView from './views/Blog/View.vue'
import InnInnInfoList from './views/Inn/InnInfo/List.vue'
import InnInnInfoEdit from './views/Inn/InnInfo/Edit.vue'
import InnInnInfoView from './views/Inn/InnInfo/View.vue'
import InnInfoList from './views/InnInfo/List.vue'
import InnInfoEdit from './views/InnInfo/Edit.vue'
import InnInfoView from './views/InnInfo/View.vue'
import UserList from './views/Inn/User.vue'
import OrderList from './views/Order/List.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "default" */ './views/Default.vue'),
      meta: {
        authName: mainAuth.authName
      },
      children: [
        {
          path: '',
          name: 'Index',
          component: () => import(/* webpackChunkName: "index" */ './views/Index.vue'),
          meta: {
            authName: mainAuth.authName
          },
        },
        {
          path: 'Inn',
          name: 'InnIndex',
          component: () => import(/* webpackChunkName: "inn-index" */ './views/InnIndex.vue'),
          meta: {
            authName: mainAuth.authName
          },
        },
        {
          path: 'Inn/:innId',
          component: () => import(/* webpackChunkName: "inn-layout" */ './views/InnLayout.vue'),
          meta: {
            authName: mainAuth.authName
          },
          beforeEnter: (to, from, next) => {
            console.log('beforeEnter', to, from)
            if (from.params.agentId) {
              next({ name: 'Agent' + to.name, query: to.query, params: to.params })
              return
            }
            next()
          },
          children: [
            {
              path: '',
              name: 'InnHome',
              component: () => import(/* webpackChunkName: "inn-home" */ './views/Inn/Home.vue'),
              meta: { authName: mainAuth.authName, title: '主页' }
            },
            {
              path: 'Help',
              name: 'Help',
              component: Help,
              meta: { authName: mainAuth.authName, title: '帮助' }
            },
            {
              path: 'Lib',
              name: 'InnLibList',
              component: InnLib,
              meta: { authName: mainAuth.authName, title: '素材库管理' }
            },
            {
              path: 'Tag',
              name: 'InnTagList',
              component: InnTag,
              meta: { authName: mainAuth.authName, title: '标签管理' }
            },
            {
              path: 'Edit',
              name: 'InnEdit',
              component: InnEdit,
              meta: { authName: mainAuth.authName, title: '客栈编辑' }
            },
            {
              path: 'View',
              name: 'InnView',
              component: InnView,
              meta: { authName: mainAuth.authName, title: '客栈查看' }
            },
            {
              path: 'Licenses',
              name: 'InnLicenses',
              component: InnLicenses,
              meta: { authName: mainAuth.authName, title: '客栈证件' }
            },
            {
              path: 'Room',
              name: 'InnRoomList',
              component: InnRoomList,
              meta: { authName: mainAuth.authName, title: '房间管理' }
            },
            {
              path: 'Room/:roomId/Edit',
              name: 'InnRoomEdit',
              component: InnRoomEdit,
              meta: { authName: mainAuth.authName, title: '房间编辑' }
            },
            {
              path: 'Room/Create',
              name: 'InnRoomCreate',
              component: InnRoomEdit,
              meta: { authName: mainAuth.authName, title: '房间创建' }
            },
            {
              path: 'RoomStock/:roomId',
              name: 'RoomStockList',
              component: RoomStockList,
              meta: { authName: mainAuth.authName, title: '房间库存管理' }
            },
            {
              path: 'Pano',
              name: 'InnPanoList',
              component: PanoList,
              meta: { authName: mainAuth.authName, title: '全景管理' }
            },
            {
              path: 'Pano/Edit/:panoId',
              name: 'PanoEdit',
              component: PanoEdit,
              meta: { authName: mainAuth.authName, title: '全景编辑' }
            },
            {
              path: 'Blog',
              name: 'InnBlogList',
              component: BlogList,
              meta: { authName: mainAuth.authName, title: '博文管理' }
            },
            {
              path: 'Blog/Create',
              name: 'BlogCreate',
              component: BlogEdit,
              meta: { authName: mainAuth.authName, title: '博文创建' }
            },
            {
              path: 'Blog/Edit/:blogId',
              name: 'BlogEdit',
              component: BlogEdit,
              meta: { authName: mainAuth.authName, title: '博文编辑' }
            },
            {
              path: 'Blog/:blogId',
              name: 'BlogView',
              component: BlogView,
              meta: { authName: mainAuth.authName, title: '博文查看' }
            },
            {
              path: 'InnTour',
              name: 'InnTour',
              component: InnTour,
              meta: { authName: mainAuth.authName, title: '客栈漫游' }
            },
            {
              path: 'InnOrder',
              name: 'InnOrderList',
              component: InnOrderList,
              meta: { authName: mainAuth.authName, title: '客栈订单' }
            },
            {
              path: 'Order',
              name: 'OrderList',
              component: OrderList,
              meta: { authName: mainAuth.authName, title: '我的订单' }
            },
            {
              path: 'InnInnInfo',
              name: 'InnInnInfoList',
              component: InnInnInfoList,
              meta: { authName: mainAuth.authName, title: '信息管理' }
            },
            {
              path: 'InnInnInfo/Edit',
              name: 'InnInnInfoCreate',
              component: InnInnInfoEdit,
              meta: { authName: mainAuth.authName, title: '新建信息' }
            },
            {
              path: 'InnInnInfo/Edit/:infoId',
              name: 'InnInnInfoEdit',
              component: InnInnInfoEdit,
              meta: { authName: mainAuth.authName, title: '信息编辑' }
            },
            {
              path: 'InnInnInfo/:infoId',
              name: 'InnInnInfoView',
              component: InnInnInfoView,
              meta: { authName: mainAuth.authName, title: '信息查看' }
            },
            {
              path: 'InnInfo/:id',
              name: 'InnInfoList',
              component: InnInfoList,
              meta: { authName: mainAuth.authName, title: '信息管理' }
            },
            {
              path: 'InnInfo/Edit',
              name: 'InnInfoCreate',
              component: InnInfoEdit,
              meta: { authName: mainAuth.authName, title: '新建信息' }
            },
            {
              path: 'InnInfo/Edit/:infoId',
              name: 'InnInfoEdit',
              component: InnInfoEdit,
              meta: { authName: mainAuth.authName, title: '信息编辑' }
            },
            {
              path: 'InnInfo/:infoId',
              name: 'InnInfoView',
              component: InnInfoView,
              meta: { authName: mainAuth.authName, title: '信息查看' }
            },
            {
              path: 'User',
              name: 'UserList',
              component: UserList,
              meta: { authName: mainAuth.authName, title: '用户列表' }
            },
          ]
        },
        {
          path: 'Agent',
          name: 'AgentIndex',
          component: () => import(/* webpackChunkName: "agent-index" */ './views/AgentIndex.vue'),
          meta: {
            authName: mainAuth.authName
          },
        },
        {
          path: 'Agent/:agentId',
          component: () => import(/* webpackChunkName: "agent-layout" */ './views/AgentLayout.vue'),
          meta: {
            authName: mainAuth.authName
          },
          children: [
            {
              path: '',
              name: 'AgentHome',
              component: () => import(/* webpackChunkName: "agent-home" */ './views/Agent/Home.vue'),
              meta: { authName: mainAuth.authName, title: '主页' }
            },
            {
              path: 'Help',
              name: 'AgentHelp',
              component: Help,
              meta: { authName: mainAuth.authName, title: '帮助' }
            },
            {
              path: 'Edit',
              name: 'AgentEdit',
              component: AgentEdit,
              meta: { authName: mainAuth.authName, title: '代理设置' }
            },
            {
              path: 'List',
              name: 'InnList',
              component: InnList,
              meta: { authName: mainAuth.authName, title: '客栈管理' }
            },
            {
              path: 'Create',
              name: 'InnCreate',
              component: InnEdit,
              meta: { authName: mainAuth.authName, title: '新建客栈' }
            },

          ]
        },
        {
          path: 'Agent/:agentId/Inn/:innId',
          component: () => import(/* webpackChunkName: "agent-inn-layout" */ './views/AgentInnLayout.vue'),
          meta: {
            authName: mainAuth.authName
          },
          children: [
            {
              path: '',
              name: 'AgentInnHome',
              component: () => import(/* webpackChunkName: "agent-inn-home" */ './views/Inn/Home.vue'),
              meta: { authName: mainAuth.authName, title: '主页' },
            },
            {
              path: 'Lib',
              name: 'AgentInnLibList',
              component: InnLib,
              meta: { authName: mainAuth.authName, title: '素材库管理' }
            },
            {
              path: 'Tag',
              name: 'AgentInnTagList',
              component: InnTag,
              meta: { authName: mainAuth.authName, title: '标签管理' }
            },
            {
              path: 'Edit',
              name: 'AgentInnEdit',
              component: InnEdit,
              meta: { authName: mainAuth.authName, title: '客栈编辑' }
            },
            {
              path: 'View',
              name: 'AgentInnView',
              component: InnView,
              meta: { authName: mainAuth.authName, title: '客栈查看' }
            },
            {
              path: 'Licenses',
              name: 'AgentInnLicenses',
              component: InnLicenses,
              meta: { authName: mainAuth.authName, title: '客栈证件' }
            },
            {
              path: 'Room',
              name: 'AgentInnRoomList',
              component: InnRoomList,
              meta: { authName: mainAuth.authName, title: '房间管理' }
            },
            {
              path: 'Room/:roomId/Edit',
              name: 'AgentInnRoomEdit',
              component: InnRoomEdit,
              meta: { authName: mainAuth.authName, title: '房间编辑' }
            },
            {
              path: 'Room/Create',
              name: 'AgentInnRoomCreate',
              component: InnRoomEdit,
              meta: { authName: mainAuth.authName, title: '房间创建' }
            },
            {
              path: 'RoomStock/:roomId',
              name: 'AgentRoomStockList',
              component: RoomStockList,
              meta: { authName: mainAuth.authName, title: '房间库存管理' }
            },
            {
              path: 'Pano',
              name: 'AgentInnPanoList',
              component: PanoList,
              meta: { authName: mainAuth.authName, title: '全景管理' }
            },
            {
              path: 'Pano/Edit/:panoId',
              name: 'AgentPanoEdit',
              component: PanoEdit,
              meta: { authName: mainAuth.authName, title: '全景编辑' }
            },
            {
              path: 'Blog',
              name: 'AgentInnBlogList',
              component: BlogList,
              meta: { authName: mainAuth.authName, title: '博文管理' }
            },
            {
              path: 'Blog/Create',
              name: 'AgentBlogCreate',
              component: BlogEdit,
              meta: { authName: mainAuth.authName, title: '博文创建' }
            },
            {
              path: 'Blog/Edit/:blogId',
              name: 'AgentBlogEdit',
              component: BlogEdit,
              meta: { authName: mainAuth.authName, title: '博文编辑' }
            },
            {
              path: 'Blog/:blogId',
              name: 'AgentBlogView',
              component: BlogView,
              meta: { authName: mainAuth.authName, title: '博文查看' }
            },
            {
              path: 'Order',
              name: 'AgentInnOrderList',
              component: InnOrderList,
              meta: { authName: mainAuth.authName, title: '订单管理' }
            },
            {
              path: 'InnInnInfo',
              name: 'AgentInnInnInfoList',
              component: InnInnInfoList,
              meta: { authName: mainAuth.authName, title: '信息管理' }
            },
            {
              path: 'InnInnInfo/Edit',
              name: 'AgentInnInnInfoCreate',
              component: InnInnInfoEdit,
              meta: { authName: mainAuth.authName, title: '新建信息' }
            },
            {
              path: 'InnInnInfo/Edit/:infoId',
              name: 'AgentInnInnInfoEdit',
              component: InnInnInfoEdit,
              meta: { authName: mainAuth.authName, title: '信息编辑' }
            },
            {
              path: 'InnInnInfo/:infoId',
              name: 'AgentInnInnInfoView',
              component: InnInnInfoView,
              meta: { authName: mainAuth.authName, title: '信息查看' }
            },
            {
              path: 'InnInfo/:id',
              name: 'AgentInnInfoList',
              component: InnInfoList,
              meta: { authName: mainAuth.authName, title: '信息管理' }
            },
            {
              path: 'InnInfo/Edit',
              name: 'AgentInnInfoCreate',
              component: InnInfoEdit,
              meta: { authName: mainAuth.authName, title: '新建信息' }
            },
            {
              path: 'InnInfo/Edit/:infoId',
              name: 'AgentInnInfoEdit',
              component: InnInfoEdit,
              meta: { authName: mainAuth.authName, title: '信息编辑' }
            },
            {
              path: 'InnInfo/:infoId',
              name: 'AgentInnInfoView',
              component: InnInfoView,
              meta: { authName: mainAuth.authName, title: '信息查看' }
            },
            {
              path: 'User',
              name: 'AgentUserList',
              component: UserList,
              meta: { authName: mainAuth.authName, title: '用户列表' }
            },
          ]
        },
        {
          path: 'Public',
          component: () => import(/* webpackChunkName: "public-layout" */ './views/PublicLayout.vue'),
          meta: {
            authName: mainAuth.authName
          },
          children: [
            {
              path: '',
              name: 'PublicHome',
              component: () => import(/* webpackChunkName: "public-home" */ './views/Public/Home.vue'),
              meta: { authName: mainAuth.authName, title: '主页' }
            },
            {
              path: 'Pano/:category',
              name: 'PublicPanoList',
              component: () => import(/* webpackChunkName: "street-list" */ './views/Public/PanoList.vue'),
              meta: { authName: mainAuth.authName, title: '街景管理' }
            },
            {
              path: 'Pano/:category/Edit/:panoId',
              name: 'PublicPanoEdit',
              component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/PanoEdit.vue'),
              meta: { authName: mainAuth.authName, title: '街景编辑' }
            },
            {
              path: 'Tour/:category',
              name: 'PublicTourList',
              component: () => import(/* webpackChunkName: "street-list" */ './views/Public/TourList.vue'),
              meta: { authName: mainAuth.authName, title: '漫游管理' }
            },
            {
              path: 'Tour/:category/Edit/:tourId',
              name: 'PublicTourEdit',
              component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/TourEdit.vue'),
              meta: { authName: mainAuth.authName, title: '漫游编辑' }
            },
            {
              path: 'Tour/:category/Create',
              name: 'PublicTourCreate',
              component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/TourEdit.vue'),
              meta: { authName: mainAuth.authName, title: '漫游创建' }
            },
          ]
        },
        {
          path: 'Pay/Inn',
          name: 'InnPay',
          component: InnPay,
          meta: { authName: mainAuth.authName, title: '客栈缴费' }
        },
        {
          path: 'Create',
          name: 'CreateInn',
          component: () => import(/* webpackChunkName: "inn-create" */ './views/Inn/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '创建客栈' },
          beforeEnter: (to, from, next) => {
            console.log('beforeEnter', to, from)
            if (to.query.ReferralId) {
              next()
              return
            } else {
              next({ name: 'NoAccess' })
            }
          },
        },
        {
          path: 'NoAccess',
          name: 'NoAccess',
          component: NoAccess,
          meta: { title: '无权访问' }
        },
      ]
    },
    {
      path: '/Pano/:panoId',
      name: 'PanoFullScreen',
      component: PanoFullScreen,
      meta: { authName: mainAuth.authName, title: '全景查看' }
    },
    //{
    //    path: '/Pay/:innId',
    //    name: 'Pay',
    //    component: Pay,
    //    meta: { authName: mainAuth.authName, title: '支付' }
    //},
    //{
    //    path: '/signin-oidc',
    //    component: SigninOidc,
    //    meta: { authName: mainAuth.authName, title: '登录' }
    //},
    //{
    //    path: '/signout-oidc',
    //    name: 'SignOut',
    //    component: SignoutOidc,
    //    meta: { authName: mainAuth.authName, title: '注销' }
    //},
    //{
    //    path: '/silent-refresh',
    //    component: SilentRefresh,
    //    meta: { authName: mainAuth.authName, title: '刷新 Token' }
    //},
    //{
    //    path: '*',
    //    component: Default,
    //    children: [
    //        {
    //            path: '',
    //            name: 'NotFound',
    //            component: NotFound,
    //            meta: { authName: mainAuth.authName, title: '404 文件未找到' }
    //        },
    //    ]
    //},
  ],
  //linkExactActiveClass: 'active'
})

mainAuth.useRouter(router);

export default router;
