<template lang="html">
    <div>
        <div class="card card-primary card-outline box-primary">
            <!--<div class="overlay" v-if="$store.state.loading">
                <i class="fa fa-refresh fa-spin"></i>
            </div>-->
            <div class="card-header p-1">
                <h3 class="card-title">信息查看</h3>
            </div>
            <div class="card-body">
                <span v-if="updateing">加载中</span>
                <template>
                    {{form.text}}
                </template>
            </div>
            <div class="card-footer">
            </div>
        </div>
        <div class="card-footer">
            <button type="button" class="btn btn-default" @click.prevent="Close">返回</button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                id: this.$route.params.infoId,
                form: {
                    guid: null,
                    categoryGUID: null,
                    innId: this.$route.params.innId,
                    title: '',
                    text: '',
                    description: '',
                    writer: '',
                    writerFrom: '',
                    addTime: null,
                    tags: [],
                    images: [],
                    attachments: [],
                    cover: null,
                    closed: false,
                },
                //distortion: 0,
            }
        },
        created() {
            this.loadData()

        },
        mounted() {
        },
        computed: {
        },
        watch: {
        },
        methods: {
            loadData() {
                var _this = this
                console.log(this.id)
                if (this.id) {
                    this.$axios.get('/Api/InnAdmin/InnInfos/' + this.id)
                        .then(function (response) {
                            //console.log(response)
                            let val = response.data
                            _this.form = val;
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            },
            Close() {
                this.$router.push({ name: 'InnInnInfoList', params: {  } })
            },
            tagsCheckOnChange(list) {
                console.log(list)
                this.form.tags = [...list]
            },
        },
        watch: {
        },

    }
</script>
