<template lang="html">
  <div class="card card-primary card-outline">
    <form @submit.prevent>
      <div class="card-header p-1">
        <h3 class="card-title">上传证件</h3>
      </div>
      <div class="card-body">
        <label>身份证</label>
        <FileBox :file="form.idCard" v-if="form.idCard" removeIcon :onRemove="formRemove('idCard')"></FileBox>
        <div v-else>
          <UploadSingleBox action="/Api/Admin/Files/Secret/Upload"
                           :multiple="true"
                           :maxSize="2"
                           :maxWidth="2500"
                           :maxHeight="2500"
                           :compress="true"
                           :isPano="false"
                           :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                           :partSizeMB="10"
                           :surlheight="100"
                           :surlwidth="200"
                           :maxUpper="3"
                           :uploadFinished="idCardUploadFinished">
            <template v-slot:default="{file,index,deleteitem}">
              <div class="card-tools">
                {{file.name}}
                <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
              </div>
              <a :href="file.surl" target="_blank">
                <img :src="file.surl" />
              </a>
              <div class="progress">
                <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
              </div>
            </template>
          </UploadSingleBox>
        </div>

        <label>营业执照</label>
        <FileBox :file="form.businessLicense" v-if="form.businessLicense" removeIcon :onRemove="formRemove('businessLicense')"></FileBox>
        <div v-else>
          <UploadSingleBox action="/Api/Admin/Files/Secret/Upload"
                           :multiple="true"
                           :maxSize="2"
                           :maxWidth="2500"
                           :maxHeight="2500"
                           :compress="true"
                           :isPano="false"
                           :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                           :partSizeMB="10"
                           :surlheight="100"
                           :surlwidth="200"
                           :maxUpper="3"
                           :uploadFinished="businessLicenseUploadFinished">
            <template v-slot:default="{file,index,deleteitem}">
              <div class="card-tools">
                {{file.name}}
                <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
              </div>
              <a :href="file.surl" target="_blank">
                <img :src="file.surl" />
              </a>
              <div class="progress">
                <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
              </div>
            </template>
          </UploadSingleBox>
        </div>
        <label>特殊行业许可证</label>
        <FileBox :file="form.specialLicense" v-if="form.specialLicense" removeIcon :onRemove="formRemove('specialLicense')"></FileBox>
        <div v-else>
          <UploadSingleBox action="/Api/Admin/Files/Secret/Upload"
                           :multiple="true"
                           :maxSize="2"
                           :maxWidth="2500"
                           :maxHeight="2500"
                           :compress="true"
                           :isPano="false"
                           :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                           :partSizeMB="10"
                           :surlheight="100"
                           :surlwidth="200"
                           :maxUpper="3"
                           :uploadFinished="specialLicenseUploadFinished">
            <template v-slot:default="{file,index,deleteitem}">
              <div class="card-tools">
                {{file.name}}
                <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
              </div>
              <a :href="file.surl" target="_blank">
                <img :src="file.surl" />
              </a>
              <div class="progress">
                <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
              </div>
            </template>
          </UploadSingleBox>
        </div>
        <label>消防许可证</label>
        <FileBox :file="form.fireLicense" v-if="form.fireLicense" removeIcon :onRemove="formRemove('fireLicense')"></FileBox>
        <div v-else>
          <UploadSingleBox action="/Api/Admin/Files/Secret/Upload"
                           :multiple="true"
                           :maxSize="2"
                           :maxWidth="2500"
                           :maxHeight="2500"
                           :compress="true"
                           :isPano="false"
                           :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                           :partSizeMB="10"
                           :surlheight="100"
                           :surlwidth="200"
                           :maxUpper="3"
                           :uploadFinished="fireLicenseUploadFinished">
            <template v-slot:default="{file,index,deleteitem}">
              <div class="card-tools">
                {{file.name}}
                <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
              </div>
              <a :href="file.surl" target="_blank">
                <img :src="file.surl" />
              </a>
              <div class="progress">
                <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
              </div>
            </template>
          </UploadSingleBox>
        </div>
        <label>卫生许可证</label>
        <FileBox :file="form.healthLicense" v-if="form.healthLicense" removeIcon :onRemove="formRemove('healthLicense')"></FileBox>
        <div v-else>
          <UploadSingleBox action="/Api/Admin/Files/Secret/Upload"
                           :multiple="true"
                           :maxSize="2"
                           :maxWidth="2500"
                           :maxHeight="2500"
                           :compress="true"
                           :isPano="false"
                           :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                           :partSizeMB="10"
                           :surlheight="100"
                           :surlwidth="200"
                           :maxUpper="3"
                           :uploadFinished="healthLicenseUploadFinished">
            <template v-slot:default="{file,index,deleteitem}">
              <div class="card-tools">
                {{file.name}}
                <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
              </div>
              <a :href="file.surl" target="_blank">
                <img :src="file.surl" />
              </a>
              <div class="progress">
                <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
              </div>
            </template>
          </UploadSingleBox>
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-default" @click.prevent="Close">取消</button>
        <button type="button" @click.prevent="Update(form.id)" class="btn btn-primary" :disabled="Updateing">更新</button>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import {
    FileBox,
    UploadSingleBox,
  } from 'common'
  //import FileBox from '@/components/FileBox.vue'
  //import UploadSingleBox from '@/components/UploadSingleBox.vue'
  //import Modal from '@/components/Modal.vue'
  import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(?::[0-9]{1,5})?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/)

  export default {
    components: {
      FileBox,
      UploadSingleBox,
    },
    data() {
      return {
        id: this.$route.params.innId,
        form: {
          id: '',
          title: '',
          description: '',
          address: {
            area: null,
            street: '',
            tel: '',
            mobile: '',
            fullName: ''
          },
          expiryTime: '',
          panoUrl: '',
          lng: 0,
          lat: 0,
          facilitys: [],
          businessLicense: null,
          idCard: null,
          specialLicense: null,
          fireLicense: null,
          healthLicense: null,
          closed: false,
        },
        progress: 0,
        showProgress: false,
        showProgressErro: false,
        ProgressErro: '',
        //aMap: null,
        dontchangemap: 0,
        clng: 0,
        clat: 0,
        facilitys1: [],
        facilitys2: [],
        types: [],
        levels: [],
        //qrcode: null,
        //qrcodeLoading: false,
        areas: {
          province: null,
          provinces: null,
          city: null,
          citys: null,
          district: null,
          districts: null
        },
        fields: [
          { key: 'more', label: '操作' },
          { key: 'id', label: 'Id' },
          { key: 'title', label: '客栈名称' },
          { key: 'lnglat', label: '经纬度' },
          { key: 'phone', label: '联系方式' },
          { key: 'areaId', label: '区域' },
          { key: 'address', label: '地址' },
          { key: 'userId', label: '用户Id' },
          { key: 'createId', label: '创建Id' },
          { key: 'expiryTime', label: '到期时间' },
          { key: 'addTime', label: '注册时间', sortable: true }
        ],
        material: null,
        //pager: {
        //  prePageSize: [10, 20, 50, 100],
        //  pageIndex: 1,
        //  pageSize: 1,
        //  totalPages: 0,
        //  totalRecords: 0,
        //  data: []
        //},
        query: {
          prePageSize: [10, 20, 50, 100],
          totalPages: 0,
          totalRecords: 0,

          pageIndex: 1,
          pageSize: 20,

          sort: "addTime",
          order: true,
          fields: 'cardNo',
          key: null,
          roles: "",
          data: []
        },
        dragData: {
          lng: null,
          lat: null,
          address: null,
          nearestJunction: null,
          nearestRoad: null,
          nearestPOI: null
        },

        zhssloading: false,
        zbfwloading: false,
        Updateing: false,
      }
    },
    validations: {
      areas: {
        province: {
          required,
        },
        city: {
          required,
        },
        district: {
          required,
        },
      },
      form: {
        title: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(30),
        },
        address: {
          area: {
            required
          },
          mobile: {
            required,
            minLength: minLength(11),
            maxLength: maxLength(11),
            isMobile
          },
          tel: {
            required,
            isTel
          },
          fullName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(10),
          },
          street: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(200),
          }
        },
        reserveUrl: {
          isUrl
        },
      }
    },
    //created() {
    //  console.info('created')
    //  //if (this.form.id == null || this.form.id.length == 0) {
    //  //  this.loadData()
    //  //}
    //  //this.$store.state.pageinfo.main.title = "用户管理"
    //  //this.setPageInfo("用户管理")

    //},
    computed: {
      //SBComponent: function () {
      //  return 'mapDrag'
      //}
      //...mapGetters([
      //  'inns'
      //]),
      //...mapState({
      //  //tableData: state => state.users.main.data,//绑定store.state.direction到组件，之后可用this.direction获取
      //  //list: state => state.loading
      //}),
      //list() {
      //  this.pager.pageIndex = this.query.pageIndex
      //  this.pager.pageSize = this.query.pageSize
      //  this.pager.totalPages = this.query.totalPages
      //  this.pager.totalRecords = this.query.totalRecords
      //  //this.pager.data = this.query.data
      //  return this.pager.data
      //}
    },
    methods: {
      formRemove(pname) {
        var _this = this
        return () => {
          console.log('formRemove', pname)
          this.form[pname] = null;
        }
      },
      status(validation) {
        return {
          error: validation.$error,
          dirty: validation.$dirty
        }
      },
      //changelng() {
      //  test = clng
      //  this.form.lat = clng
      //},
      //changelat() {
      //  test = clat
      //  this.form.lat = clat
      //},
      ...mapActions([
        'setPageInfo',
      ]),
      //dragMap(data) {
      //  this.dragData = {
      //    lng: data.position.lng,
      //    lat: data.position.lat,
      //    address: data.address,
      //    nearestJunction: data.nearestJunction,
      //    nearestRoad: data.nearestRoad,
      //    nearestPOI: data.nearestPOI
      //  }
      //},
      //文件上传
      //upload: function () {
      //  var _this = this
      //  _this.showProgress = true
      //  _this.showProgressErro = false
      //  var url = 'https://localhost:5003' + "/Api/Inn/Files/UploadPano"
      //  var token = $('#access_token').val()
      //  var files = $('#files')[0].files[0]
      //  console.log(files)
      //  var data = new FormData()
      //  //data.append("articleGUID", articleGUID); //文章 Id
      //  data.append("files", files)

      //  var config = {
      //    onUploadProgress: progressEvent => {
      //      var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
      //      this.progress = complete
      //      console.info('#', complete)
      //      console.info('#', progressEvent)
      //      console.info('#', progressEvent.loaded)
      //    }
      //  }
      //  this.$axios.post(url, data, config)
      //    .then(function (response) {
      //      console.log('#', response)
      //      _this.showProgressErro = true
      //      if (true) {
      //        _this.showProgress = false
      //        _this.ProgressErro = '上传成功'
      //        _this.ProgressErro = '<img style="max-width:200px;max-height:200px" src=http://localhost:5001' + response.data.url + ' />'
      //      } else {
      //        _this.ProgressErro = response.data
      //      }
      //    }).catch(function (error) {
      //      console.log(error)
      //    })
      //},
      initData() {
        //this.initMap(0, 0)
        this.getProvince();
      },
      loadData() {
        let _this = this
        this.zhssloading = true;
        this.$axios.get('/Api/InnAdmin/Facilitys/All', { params: { category: '综合设施' } })
          .then(res => {
            _this.facilitys1 = res.data;
            _this.zhssloading = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.zhssloading = false;
          })
        this.zbfwloading = true;
        this.$axios.get('/Api/InnAdmin/Facilitys/All', { params: { category: '周边服务' } })
          .then(res => { _this.facilitys2 = res.data })
          .catch(function (error) {
            console.log(error)
          })
        this.$axios.get('/Api/InnAdmin/Inns/Types')
          .then(res => { _this.types = res.data })
          .catch(function (error) {
            console.log(error)
          })
        this.$axios.get('/Api/InnAdmin/Inns/Levels')
          .then(res => { _this.levels = res.data })
          .catch(function (error) {
            console.log(error)
          })
        if (!this.id || this.id.length == 0) {
          this.initData();
          return;
        }
        this.$axios.get('/Api/InnAdmin/Inns/' + this.id)
          .then(function (response) {
            //console.log(response)
            let d = response.data
            //_this.form.id = d.id
            //_this.form.title = d.title
            //_this.form.description = d.description
            //_this.form.expiryTime = d.expiryTime
            //_this.form.lng = d.lng
            //_this.form.lat = d.lat
            _this.form = d;
            _this.clng = d.lng;
            _this.clat = d.lat;
            _this.dontchangemap = 1;
            //console.log('loadData', d.lng, d.lat)
            //初始化地图
            //_this.initMap(d.lng, d.lat)
            //初始化区域
            let areaint = _this.form.address.area
            if (areaint > 100000 && areaint < 900000) {
              var province = Math.floor(areaint / 10000) * 10000;
              console.log(province);
              _this.getProvince(province);
              var city = Math.floor(areaint / 100) * 100;
              console.log(city);
              _this.getCity(province, city);
              _this.getDistrict(city, areaint);
            } else {
              _this.getProvince();
            }
          })
          .catch(function (error) {
            console.log(error)
          })


      },
      // 搜索
      handleSearch() {
        if (this.searchKey) {
          this.placeSearch.search(this.searchKey)
        }
      },

      mapOnChange(lng, lat) {
        console.log(lng, lat)
        this.clng = lng;
        this.clat = lat;
        this.form.lng = lng;
        this.form.lat = lat;
      },
      checkOnChange1(list) {
        console.log(list)
        this.form.facilitys = [...list]
      },
      checkOnChange2(list) {
        console.log(list)
        this.form.facilitys = [...list]
      },
      getProvince: function (id) {
        var _this = this;
        this.$axios.get('/Api/Areas')
          .then(function (response) {
            _this.areas.provinces = response.data;
            if (id != null) {
              _this.areas.province = id;
            } else {
              _this.areas.province = null;
              _this.getCity(_this.areas.province);
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      getCity: function (pid, id) {
        var _this = this;
        if (pid != null) {
          this.$axios.get('/Api/Areas?ParentId=' + pid)
            .then(function (response) {
              _this.areas.citys = response.data;
              if (id != null) {
                _this.areas.city = id;
              } else {
                _this.areas.city = null;
                _this.getDistrict(_this.areas.city);
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        }
        else {
          _this.areas.city = null;
          _this.getDistrict(_this.areas.city);
        }
      },
      getDistrict: function (pid, id) {
        var _this = this;
        if (pid != null) {
          this.$axios.get('/Api/Areas?ParentId=' + pid)
            .then(function (response) {
              _this.areas.districts = response.data;
              if (id != null) {
                _this.areas.district = id;
              } else {
                _this.areas.district = null;
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        }
        else {
          _this.areas.district = null;
        }
      },
      Create() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let _this = this
        let d = this.form
        delete d.id
        _this.Updateing = true;
        this.$axios.post('/Api/InnAdmin/Inns', d)
          .then(function (response) {
            console.log(response)
            _this.id = response.data.id;
            _this.Updateing = false;
            _this.Close()
          })
          .catch(function (error) {
            _this.Updateing = false;
            console.log(error)
          })
      },
      Delete(id, i) {
        let _this = this
        this.$axios.delete('/Api/InnAdmin/Inns/' + id)
          .then(function (response) {
            _this.Close()
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      Update(id) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let _this = this
        _this.Updateing = true;
        this.$axios.put('/Api/InnAdmin/Inns/' + id, this.form)
          .then(function (response) {
            _this.Close()
            _this.Updateing = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.Updateing = false;
          })
      },
      Close() {
        this.$router.push({ name: 'InnView' })
      },
      onSelected(select) {
        console.log(select)
        this.material = select
        this.form.panoUrl = select.firstImage.url
        this.form.PanoId = select.guid
      },
      showPanoLibDialog() {
        this.$refs.PanoLib.showDialog();
      },
      idCardUploadFinished(file) {
        this.form.idCard = file;
        console.log('inCard', file)
      },
      businessLicenseUploadFinished(file) {
        this.form.businessLicense = file;
        console.log('businessLicense', file)
      },
      specialLicenseUploadFinished(file) {
        this.form.specialLicense = file;
        console.log('specialLicense', file)
      },

      fireLicenseUploadFinished(file) {
        this.form.fireLicense = file;
        console.log('fireLicense', file)
      },

      healthLicenseUploadFinished(file) {
        this.form.healthLicense = file;
        console.log('healthLicense', file)
      },

    },
    created() {
      this.loadData()
    },
    mounted() {
      var _this = this
    },

    watch: {
      'form.address.area': function (val, oldval) {
        if (this.dontchangemap > 0) {
          this.dontchangemap--;
          return;
        }
        this.$refs.myMapbox.mapSetCity(val);
      }
    },
  }
</script>
<style lang="css">
</style>
