<template lang="html">
  <div class="card card-primary card-outline">
    <!--<div class="overlay" v-if="$store.state.loading">
        <i class="fa fa-refresh fa-spin"></i>
    </div>-->
    <div class="card-header p-1">
      <h3 class="card-title">房间编辑</h3>
    </div>
    <div class="card-body">
      <div>
        <form @submit.prevent>
          <div class="form-group">
            <label for="title">房间名称</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入房间名称">
            <div class="invalid-feedback">
              <span v-if="!$v.form.title.required">请输入房间名称</span>
              <span v-if="!$v.form.title.minLength">房间名称至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.form.title.maxLength">房间名称最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <div class="form-group">
            <label for="Description">描述</label>
            <!--<textarea class="form-control" id="Description" v-model.trim="form.description" placeholder="请输入房间描述"></textarea>-->
            <!--<PellEditor v-model.trim="form.description"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />-->
            <AutoTextarea v-model="form.description" :maxHeight="200" :cssStyle="{width: '100%'}"></AutoTextarea>
          </div>
          <div class="form-group">
            <label for="RoomSize">房间面积 M<sup>3</sup></label>
            <div class="input-group">
              <input type="number" class="form-control" :class="{ 'is-invalid': $v.form.roomSize.$error }" id="RoomSize" v-model.trim.lazy="$v.form.roomSize.$model" placeholder="请输入房间房间面积">
              <div class="input-group-append">
                <span class="btn btn-outline-primary" v-on:click="$v.form.roomSize.$model-=1"><i class="fa fa-minus"></i></span>
                <span class="btn btn-outline-primary" v-on:click="$v.form.roomSize.$model=+$v.form.roomSize.$model+1"><i class="fa fa-plus"></i></span>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.form.roomSize.required">请输入房间面积</span>
                <span v-if="!$v.form.roomSize.decimal">请输入房间面积</span>
                <span v-if="!$v.form.roomSize.between">房间面积有效范围 {{$v.form.roomSize.$params.between.min}} - {{$v.form.roomSize.$params.between.max}}</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="Floor">楼层</label>
            <div class="input-group">
              <input type="number" class="form-control" :class="{ 'is-invalid': $v.form.floor.$error }" id="Floor" v-model.trim.lazy="$v.form.floor.$model" placeholder="请输入房间楼层">
              <div class="input-group-append">
                <span class="btn btn-outline-primary" v-on:click="$v.form.floor.$model-=1"><i class="fa fa-minus"></i></span>
                <span class="btn btn-outline-primary" v-on:click="$v.form.floor.$model=+$v.form.floor.$model+1"><i class="fa fa-plus"></i></span>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.form.floor.required">请输入房间楼层</span>
                <span v-if="!$v.form.floor.integer">请输入整数</span>
                <span v-if="!$v.form.floor.between">房间楼层有效范围 {{$v.form.floor.$params.between.min}} ～ {{$v.form.floor.$params.between.max}}</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="Floor">价格</label>
            <div class="input-group">
              <input type="number" class="form-control" :class="{ 'is-invalid': $v.form.price.$error }" id="Price" v-model.trim.lazy="$v.form.price.$model" placeholder="请输入房间价格">
              <div class="input-group-append">
                <span class="btn btn-outline-primary" v-on:click="$v.form.price.$model-=1"><i class="fa fa-minus"></i></span>
                <span class="btn btn-outline-primary" v-on:click="$v.form.price.$model=+$v.form.price.$model+1"><i class="fa fa-plus"></i></span>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.form.price.required">请输入房间价格</span>
                <span v-if="!$v.form.price.decimal">必须为数字</span>
                <span v-if="!$v.form.price.between">房间价格有效范围 {{$v.form.price.$params.between.min}} ～ {{$v.form.price.$params.between.max}}</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="HasWindow">是否有窗户</label>
            <input type="checkbox" id="HasWindow" v-model.trim="form.hasWindow" placeholder="是否有窗户">
          </div>
          <div class="form-group">
            <label>房型</label>
            <select class="form-control" v-model="form.roomType">
              <option v-for="i in roomTypes" :value="i.value">{{i.key}}</option>
            </select>
          </div>
          <div>
            <BedSelect :checkList="beds" checkprop="id" :selected="form.beds" :onChange="onBed">

            </BedSelect>
          </div>
          <div class="form-group">
            <label for="AllowAddBed">允许加床</label>
            <input type="checkbox" id="AllowAddBed" v-model.trim="form.allowAddBed" placeholder="允许加床">
          </div>
          <div class="form-group">
            <label for="reserveUrl">预订链接</label>
            <input type="url" class="form-control" :class="{ 'is-invalid': $v.form.reserveUrl.$error }" id="reserveUrl" v-model.trim="$v.form.reserveUrl.$model" placeholder="请输入预订链接">
            <div class="invalid-feedback">
              <span v-if="!$v.form.reserveUrl.isUrl">不是有效的 Url 格式</span>
            </div>
          </div>

          <!--<div class="form-group">
              <label for="Code">房间编码</label>
              <input type="text" class="form-control" id="Code" v-model.trim="form.code" placeholder="请输入房间编码">
          </div>-->
          <CollapedBox collapseIcon>
            <template v-slot:header>
              设置全景
            </template>
            <div>
              <a class="btn btn-primary" @click="showPanoLibDialog()">请选择全景</a>
              <PanoLib @submit="onSelected" libUrl="/Api/InnAdmin/Panos" :params="{innid:$route.params.innId}" ref="PanoLib"></PanoLib>
              <div>
                <img v-if="form.panoUrl" class="img-fluid" :src="form.panoUrl.replace('/0/0/0/0/', '/600/0/0/0/')" style="max-height:400px" />
              </div>
            </div>
          </CollapedBox>
          <CollapedBox collapseIcon expandCard>
            <template v-slot:header>
              客房设施
            </template>
            <CheckBox checkprop="id"
                      :checkList="roomFacilitys"
                      :selected="form.facilitys"
                      :onChange="checkOnChange1">

            </CheckBox>
          </CollapedBox>
          <div class="form-group">
            <label for="Closed">关闭房间</label>
            <input type="checkbox" id="Closed" v-model.trim="form.closed" placeholder="关闭房间">
          </div>
        </form>
      </div>

    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="this.$store.state.loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <!-- end loading -->
    <Footer>
      <button type="button" class="btn btn-default mr-1" @click="cancel">取消</button>
      <template v-if="form.id==null||form.id.length==0">
        <button type="button" @click.prevent="Create" class="btn btn-primary">添加</button>
      </template>
      <template v-else>
        <button type="button" @click.prevent="Update(form.id)" class="btn btn-primary">更新</button>
      </template>
    </Footer>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import {
    PanoLib,
    BedSelect,
    CollapedBox,
    CheckBox,
    Footer,
    PellEditor,
    AutoTextarea
  } from 'common'
  //import PanoLib from '@/components/PanoLib.vue'
  //import BedSelect from '@/components/bedSelect.vue'
  //import CollapedBox from '@/components/CollapedBox.vue'
  //import CheckBox from '@/components/CheckBox.vue'
  //import Footer from '@/components/Footer.vue'
  //import PellEditor from '@/components/PellEditor.vue'
  import { required, minLength, maxLength, between, numeric, decimal, integer, helpers } from 'vuelidate/lib/validators'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(?::[0-9]{1,5})?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/)

  export default {
    components: {
      PanoLib,
      BedSelect,
      CollapedBox,
      CheckBox,
      Footer,
      PellEditor,
      AutoTextarea
    },
    data() {
      return {
        roomId: this.$route.params.roomId,
        innId: this.$route.params.innId,
        form: {
          id: '',
          title: '',
          description: '',
          roomType: 1,
          point: 1,
          roomSize: 0,
          floor: 0,
          price: 0,
          code: '',
          innId: this.$route.params.innId,
          panoUrl: null,
          PanoId: null,
          facilitys: [],
          closed: false,
        },
        beds: null,
        roomFacilitys: [],
        roomTypes: [],
        material: null,
        options: {
          whiteList: {
            p: [],
            b: [],
            strong: [],
            i: [],
            u: [],
            strike: [],
            br: [],
            //h1: [],
            //h2: [],
            //blockquote: [],
            //hr: [],
            ol: [],
            ul: [],
            li: [],
            //code: [],
            //a: ['href'],
            //img: ['src'],
            sub: [],
            sup: [],
          },
          stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
          stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
        }
      }
    },
    validations: {
      form: {
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        roomSize: {
          //required,
          decimal,
          between: between(0, 9999)
        },
        floor: {
          //required,
          integer,
          between: between(-20, 99)
        },
        price: {
          required,
          decimal,
          between: between(0, 99999)
        },
        reserveUrl: {
          isUrl
        },
      }
    },
    //created() {
    //    this.loadData()
    //},
    mounted() {
      this.loadData()
    },
    computed: {
    },
    methods: {
      ...mapActions([
        'setPageInfo',
      ]),
      loadData() {
        this.$axios.get('/Api/InnAdmin/Facilitys/All', { params: { category: '客房设施' } })
          .then(res => {
            this.roomFacilitys = res.data
          })
          .catch((error) => {
            console.log(error)
          })
        if (this.roomId) {
          this.$axios.get(`/Api/InnAdmin/Rooms/${this.$route.params.innId}/${this.roomId}`).then((res) => {
            this.form = res.data;
            for (var i in this.form.beds) {
              this.form.beds[i].id = this.form.beds[i].bedId
            }
          })

        } else {
          this.Reset()
        }
        this.getBeds()
        this.getRoomTypes()
      },
      getBeds() {
        this.$axios.get('/Api/InnAdmin/Beds/All').then((res) => {
          this.beds = res.data;
        })
      },
      getRoomTypes() {
        this.$axios.get('/Api/InnAdmin/Rooms/Types').then((res) => {
          this.roomTypes = res.data;
        })
      },
      Create() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let d = this.form
        for (var i in d.beds) {
          d.beds[i].bedid = d.beds[i].id
        }
        delete d.id
        console.log(d)
        this.$axios.post(`/Api/InnAdmin/Rooms/${this.$route.params.innId}`, d)
          .then((response) => {
            //console.log(response)
            //_this.loadData()
            //$('#dialog').modal('hide')
            //_this.Reset()
            this.cancel()
          })
          .catch((error) => {
            console.log(error)
          })
      },
      //Delete(id, i) {
      //    let _this = this
      //    this.$axios.delete('/Api/InnAdmin/Rooms/' + id)
      //        .then(function (response) {
      //            console.log(response)
      //            _this.query.data.splice(i, 1)
      //        })
      //        .catch(function (error) {
      //            console.log(error)
      //        })
      //},
      Update(id) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let d = this.form

        for (var i in d.beds) {
          d.beds[i].bedId = d.beds[i].id
        }
        this.$axios.put(`/Api/InnAdmin/Rooms/${this.$route.params.innId}/${id}`, d)
          .then((response) => {
            //_this.loadData()
            //$('#dialog').modal('hide')
            //_this.Reset()
            //console.log(response)
            this.cancel()
          })
          .catch((error) => {
            console.log(error)
          })
      },
      Reset() {
        this.form = this.newForm()
        this.form.innId = this.innId;
      },
      newForm() {
        return {
          beds: [],
          code: "",
          description: "",
          id: "",
          imageId: null,
          innId: "",
          panoId: null,
          panoUrl: null,
          point: 0,
          roomSize: 0,
          floor: 0,
          price: 0,
          roomType: 0,
          title: "",
          facilitys: []
        }
      },
      onSelected(select) {
        console.log(select)
        this.material = select
        this.form.panoUrl = select.firstImage.url
        this.form.PanoId = select.guid
      },
      showPanoLibDialog() {
        this.$refs.PanoLib.showDialog();
      },
      checkOnChange1(list) {
        console.log(list)
        this.form.facilitys = [...list]
      },
      onBed(beds) {
        var list = [...beds];
        this.form.beds = list;
      },
      cancel() {
        this.$router.push({ name: 'InnRoomList' })
      }
    },
  }
</script>
