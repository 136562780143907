<template lang="html">
    <div>
        <ckeditor ref="ckeditor"
                  :value="value"
                  :editor="editor"
                  :config="editorConfig"
                  @ready="onEditorReady"
                  @focus="onEditorFocus"
                  @blur="onEditorBlur"
                  @input="onEditorInput"
                  @destroy="onEditorDestroy">
        </ckeditor>
    </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
    import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
    import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
    import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
    import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
    import Link from '@ckeditor/ckeditor5-link/src/link';
    import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
    import Font from '@ckeditor/ckeditor5-font/src/font';
    import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
    import Heading from '@ckeditor/ckeditor5-heading/src/heading';
    import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
    import Indent from '@ckeditor/ckeditor5-indent/src/indent';
    import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
    import Image from '@ckeditor/ckeditor5-image/src/image';
    import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
    import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
    import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
    import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
    import ImageUpload from '@ckeditor/ckeditor5-image/src/imagestyle';
    import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
    import Table from '@ckeditor/ckeditor5-table/src/table';
    import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
    import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
    import List from '@ckeditor/ckeditor5-list/src/list';
    import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
    import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
    //import Mention from '@ckeditor/ckeditor5-mention/src/mention';
    import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
    import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
    import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
    //import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
    import SimpleUploadAdapter from '@/lib/UploadAdapter';
    //import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
    //import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn.js';

    export default {
        name: 'Editor',
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                //type: String,
                required: true
            },
            placeholder: String,
            toolbarViewportTopOffset: {
                type: Number,
                default: 56
            },
            toolbarItems: {
                type: Array,
                default: function () {
                    return [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline', 'strikethrough', 'subscript', 'superscript',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'alignment',
                        'outdent', 'indent',
                        'bulletedList', 'numberedList',
                        //'highlight',
                        '|',
                        'link',
                        'imageUpload',
                        'ckfinder',
                        'mediaEmbed',
                        'insertTable',
                        '|',
                        'blockQuote', 'code',
                        'removeFormat',
                        'undo',
                        'redo'
                    ]
                }
            },
            fontSizes: {
                type: Array,
                default: function () {
                    return [
                        'default',
                        12,
                        16,
                        18,
                        22
                    ]
                }
            },
            fontFamilys: {
                type: Array,
                default: function () {
                    return [
                        'default',
                        '宋体, 新宋体',
                        '黑体, 微软雅黑',
                        '楷体, 楷体_GB2312',
                        '仿宋, 仿宋_GB2312',
                        'Arial, Helvetica, sans-serif',
                        'Courier New, Courier, monospace',
                        'Georgia, serif',
                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                        'Tahoma, Geneva, sans-serif',
                        'Times New Roman, Times, serif',
                        'Trebuchet MS, Helvetica, sans-serif',
                        'Verdana, Geneva, sans-serif'
                    ]
                }
            },
            onChange: {
                type: Function,
            },
            onUploadComplete: {
                type: Function,
                default: () => {
                }
            }
        },
        data() {
            return {
                editor: ClassicEditor,
                editorData: this.value,
                editorConfig: {
                    //language: 'zh-cn',
                    placeholder: this.placeholder,
                    plugins: [
                        Essentials,
                        Bold,
                        Italic,
                        Underline, Strikethrough, Code, Subscript, Superscript,
                        Link,
                        Paragraph,
                        Font,
                        FontFamily,
                        Heading,
                        Highlight,
                        Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageUpload,
                        RemoveFormat,
                        Table, TableToolbar,
                        Alignment,
                        List,
                        BlockQuote,
                        MediaEmbed,
                        //Mention,
                        Indent, IndentBlock,
                        PasteFromOffice,
                        Base64UploadAdapter,
                        SimpleUploadAdapter,
                        EasyImage,
                        //CKFinder
                    ],
                    toolbar: {
                        viewportTopOffset: this.toolbarViewportTopOffset,
                        items: this.toolbarItems
                    },
                    fontSize: {
                        options: this.fontSizes
                    },
                    fontFamily: {
                        options: this.fontFamilys
                    },
                    table: {
                        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                    },
                    indentBlock: {
                        offset: 1,
                        unit: 'em'
                    },
                    image: {
                        // You need to configure the image toolbar, too, so it uses the new style buttons.
                        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

                        styles: [
                            // This option is equal to a situation where no style is applied.
                            'full',

                            // This represents an image aligned to the left.
                            'alignLeft',

                            // This represents an image aligned to the right.
                            'alignRight'
                        ]
                    },
                    simpleUpload: {
                        // The URL the images are uploaded to.
                        uploadUrl: window.global.API_BASE_URL + '/Api/Admin/Files/Upload',

                        // Headers sent along with the XMLHttpRequest to the upload server.
                        headers: {
                            'X-CSRF-TOKEN': 'CSFR-Token',
                            Authorization: 'Bearer ' + this.$store.state.accessToken
                        },
                        uploadFunc: this.uploadFunc,
                        onUploadComplete: this.onUploadComplete
                    },
                    //cloudServices: {
                    //    tokenUrl: window.global.API_BASE_URL + '/Api/My',
                    //    uploadUrl: window.global.API_BASE_URL + '/Api/Admin/File/Upload'
                    //},
                    //ckfinder: {
                    //    // Upload the images to the server using the CKFinder QuickUpload command.
                    //    uploadUrl: window.global.API_BASE_URL + '/Api/Admin/Files/Upload',

                    //    // Define the CKFinder configuration (if necessary).
                    //    options: {
                    //        resourceType: 'Images',
                    //        openerMethod: 'popup'
                    //    }
                    //}
                },
                ckeditor: null,
            };
        },
        mounted() {
        },
        methods: {
            uploadFunc(resolve, reject, file) {
                console.log('文件上传测试', file)
                this.$emit('uploadfile', resolve, reject, file)
            },
            imageInsert(url) {
                if (this.ckeditor) {
                    this.ckeditor.execute('imageInsert', { source: url });
                }
            },
            linkInsert(url, text) {
                if (this.ckeditor) {
                    if (!text) {
                        text = url
                    }
                    this.ckeditor.model.change(writer => {
                        console.log('linkInsert')
                        const select = this.ckeditor.model.document.selection
                        console.log(select.isCollapsed)
                        const linkCommend = ckeditor.commands.get('link')
                        if (!select.isCollapsed) {
                            linkCommend.execute(url, {
                                linkIsExternal: false
                            });
                        } else {
                            var start = this.ckeditor.model.document.selection.getFirstPosition()
                            if (start.path[0] == 0 && start.path[1] == 0) {
                                writer.setSelection(writer.createRange(start, start));
                            }
                            writer.insertText(text, start);
                            var end = this.ckeditor.model.document.selection.getLastPosition()
                            console.log(start, end);
                            const range = writer.createRange(start, end);
                            writer.setSelection(range);
                            linkCommend.execute(url, {
                                linkIsExternal: false
                            });
                        }
                    });
                }
            },
            onEditorReady(editor) {
                this.ckeditor = editor
                window.ckeditor = editor
                console.log('Editor is ready.', { editor });
                //editor.ui.getEditableElement().parentElement.insertBefore(
                //    editor.ui.view.toolbar.element,
                //    editor.ui.getEditableElement()
                //);
            },
            onEditorFocus(event, editor) {
                console.log('Editor focused.', { event, editor });
            },
            onEditorBlur(event, editor) {
                console.log('Editor blurred.', { event, editor });
            },
            onEditorInput(data, event, editor) {
                console.log('Editor data input.', { event, editor, data });
                this.$emit('change', data)
                if (this.onChange) {
                    this.onChange(data)
                }
            },
            onEditorDestroy(editor) {
                console.log('Editor destroyed.', { editor });
            },

        },

    };
</script>

<style lang="css">
</style>
