<template>
    <div class="wrapper">
        <va-navibar :hasMenu="false"></va-navibar>
        <div class="error-page">
            <h2 class="headline text-warning"> 404</h2>

            <div class="error-content">
                <h3><i class="fas fa-exclamation-triangle text-warning"></i> 哎呀！ 网页未找到。</h3>

                <p>
                    我们找不到您要访问的页面。
                    同时，您可以<router-link :to="{ name: 'Index'}">返回首页</router-link>。
                </p>

                <!--<form class="search-form">
                    <div class="input-group">
                        <input type="text" name="search" class="form-control" placeholder="Search">

                        <div class="input-group-append">
                            <button type="submit" name="submit" class="btn btn-warning">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>-->
            </div>
            <!-- /.error-content -->
        </div>
        <!-- /.error-page -->
    </div>
</template>

<script>
    import VANaviBar from '@/components/NaviBar.vue'
    export default {
        components: {
            'va-navibar': VANaviBar,
        },
    }
</script>

<style>
</style>
