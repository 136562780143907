<template lang="html">
  <div>
    <div class="card card-primary card-outline box-primary">
      <!--<div class="overlay" v-if="$store.state.loading">
          <i class="fa fa-refresh fa-spin"></i>
      </div>-->
      <div class="card-header p-1">
        <h3 class="card-title">信息查看</h3>
      </div>
      <div class="card-body">
        <span v-if="loading">加载中</span>
        <div v-html="form.text">
        </div>
      </div>
      <div class="card-footer">
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default" @click.prevent="Close">返回</button>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        id: this.$route.params.infoId,
        form: {
          guid: null,
          categoryGUID: null,
          innId: this.$route.params.innId,
          title: '',
          text: '',
          description: '',
          writer: '',
          writerFrom: '',
          addTime: null,
          tags: [],
          images: [],
          attachments: [],
          cover: null,
          closed: false,
        },
        loading: false,
        //distortion: 0,
      }
    },
    created() {
      this.loadData()

    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
      loadData() {
        this.loading = true
        console.log(this.id)
        if (this.id) {
          this.$axios.get('/Api/InnAdmin/InnInfos/' + this.id)
            .then((response) => {
              //console.log(response)
              let val = response.data
              this.form = val;
              this.loading = false
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
            })
        }
      },
      Close() {
        this.$router.push({ name: 'InnInfoList', params: { id: this.form.categoryGUID } })
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
    },
    watch: {
    },

  }
</script>
