<template lang="html">
  <div class="card card-primary card-outline UserList">
    <div class="card-header p-2">
      <h3 class="card-title">用户管理</h3>
      <!-- /.btn-group -->
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls">
        <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="create()" title="新建用户"><i class="fa fa-plus"></i></button>
        <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        <!-- Check all button -->
        <div class="float-right">
          <div class="form-inline">
            <div class="form-group form-group-sm">
              <label>角色</label>
              <select class="form-control form-control-sm" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                <option value="">所有</option>
                <option v-for="i in roles" v-bind:value="i">{{i}}</option>
              </select>
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button class="btn btn-default btn-xs mx-1" title="查看" @click="view(props.row.id)">
            <i class="fas fa-search"></i>
          </button>
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="View">
      <template slot="header">
        <h4 class="modal-title">{{ item.id!=null ? '修改用户':  '新建用户' }}</h4>
      </template>
      <form class="form-horizontal">
        <div class="card-body">
          <div class="form-group">
            <label for="editUserName" class="col-sm-2 control-label">用户名</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="editUserName" placeholder="用户名" v-model.trim="item.userName">
            </div>
          </div>
          <div class="form-group">
            <label for="editNickName" class="col-sm-2 control-label">昵称</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="editNickName" placeholder="昵称" v-model.trim="item.nickName">
            </div>
          </div>
          <div class="form-group">
            <label for="editEmail" class="col-sm-2 control-label">电子邮件</label>
            <div class="col-sm-10">
              <input type="email" class="form-control" id="editEmail" placeholder="电子邮件" v-model.trim="item.email">
            </div>
          </div>
          <div class="form-group">
            <label for="editPhone" class="col-sm-2 control-label">手机号码</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="editPhone" placeholder="手机号码" v-model.trim="item.phoneNumber">
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-offset-2 col-sm-10">
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="item.closed"> 关闭
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
      </template>
    </Modal>
    <!-- /.modal -->
  </div>

</template>

<script>
  import { Modal } from 'common'
  //import Modal from '@/components/Modal'

  export default {
    components: {
      Modal,
    },
    data() {
      return {
        list: [],
        roles: [],
        item: {
          userId: null,
          id: null,
          userName: null,
          password: null,
          email: null,
          phoneNumber: null,
          nickName: null,
          closed: false,
          roles: [],
        },
        columns: [
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
          { name: 'userName', label: '用户名', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap" },
          { name: 'nickName', label: '昵称', column_classes: "text-nowrap", row_classes: "text-nowrap" },
          { name: 'email', label: '电子邮件', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap", row_classes: "text-nowrap" },
          { name: 'phoneNumber', label: '手机号码', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap" },
          { name: 'addTime', label: '注册时间', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 20,
          sort: "UserId",
          order: "desc",
          fields: 'UserName,Email,PhoneNumber',
          key: null,
          roles: ""
        },
        loading: false,
        updateing: false,
      }
    },
    created() {
      this.loadData()
    },
    computed: {
    },
    methods: {
      loadData() {
        var _this = this;
        this.loading = true
        var config = {
          params: {
            innId: this.$route.params.innId,
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
            roles: this.query.roles
          }
        };
        this.$axios.get('/Api/InnAdmin/Inns/Users', config).then(function (res) {
          //console.log(res.data)
          _this.list = res.data.data
          _this.pager.pageIndex = res.data.pageIndex
          _this.pager.pageSize = res.data.pageSize
          _this.pager.totalPages = res.data.totalPages
          _this.pager.totalRecords = res.data.totalRecords
          _this.loading = false
        })

      },
      search() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      onChangeQuery(queryParams) {
        console.log(queryParams.sort[0])
        var sort = queryParams.sort[0]
        this.query.sort = sort.name
        this.query.order = sort.order
        this.loadData()
      },
      loadUser(id) {
        this.loading = true
        let self = this
        this.$axios.get('/Api/InnAdmin/Inns/Users/' + id)
          .then(function (response) {
            //console.log(response)
            let d = response.data
            self.item.id = d.id
            self.item.userId = d.userId
            self.item.userName = d.userName
            self.item.nickName = d.nickName
            self.item.email = d.email
            self.item.phoneNumber = d.phoneNumber
            self.item.closed = d.closed
            self.item.roles = d.roles
            self.loading = false
          })
          .catch(function (error) {
            console.log(error)
            self.loading = false
          })
      },
      reset() {
        let self = this
        self.item.id = null
        self.item.userId = null
        self.item.userName = null
        self.item.nickName = null
        self.item.password = null
        self.item.email = null
        self.item.phoneNumber = null
        self.item.closed = false
        self.item.roles = []
      },
      view(id) {
        console.log(id)
        this.$refs.View.show()
        this.loadUser(id)
      },
    },
  }
</script>

<style lang="css">
  .UserList .c1 {
    width: 90px;
  }

  .UserList .c2 {
    width: 60px;
  }

  .UserList .c_dt {
    width: 155px;
  }
</style>
