<template lang="html">
  <div v-if="loading">
    <i class="fas fa-spinner fa-pulse"></i>
    加载中…
  </div>
  <div v-else class="card card-primary card-outline">
    <form @submit.prevent>
      <div class="card-header p-1">
        <h3 class="card-title">客栈信息</h3>
      </div>
      <div class="card-body p-0">
        <CollapedBox expandCard>
          <template v-slot:header>
            基本设置
          </template>
          <div class="form-group has-feedback">
            <label for="Title">客栈名称</label>
            <div>{{form.title}}</div>
          </div>
          <div class="form-group">
            <label for="Description">简介</label>
            <!--<article v-html="form.description">
            </article>-->
            <AutoTextareaView :value="form.description" :cssStyle="{width: '100%'}"></AutoTextareaView>
          </div>
          <div class="form-group">
            <label for="SpecialService">服务</label>
            <!--<article v-html="form.specialService">
            </article>-->
            <AutoTextareaView :value="form.specialService" :cssStyle="{width: '100%'}"></AutoTextareaView>
          </div>
          <div class="form-group">
            <label for="Tips">提示</label>
            <!--<article v-html="form.tips">
            </article>-->
            <AutoTextareaView :value="form.tips" :cssStyle="{width: '100%'}"></AutoTextareaView>
          </div>

          <div class="form-group">
            <label for="Type">类型</label>
            <div>
              {{form.typeName}}
            </div>
          </div>
          <div class="form-group">
            <label for="Level">档次</label>
            <div>
              {{form.levelName}}
            </div>
          </div>
          <div class="form-group">
            <label for="Brand">品牌</label>
            <div>
              {{form.brandName}}
            </div>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.isChain" placeholder="连锁" disabled>连锁
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.isForeigner" placeholder="接待外宾" disabled>接待外宾
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.isHkMoTw" placeholder="接待港澳台宾客" disabled>接待港澳台宾客
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.notElderly" placeholder="不接待老人" disabled>不接待老人
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.notChild" placeholder="不接待小孩" disabled>不接待小孩
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.notPet" placeholder="不接待宠物" disabled>不接待宠物
            </label>

          </div>

          <div class="form-group">
            <label for="reserveUrl">预订链接</label>
            <div>
              {{form.reserveUrl}}
            </div>
          </div>
          <div class="form-group">
            <label for="ownerSummary">店主介绍</label>
            <div>
              {{form.ownerSummary}}
            </div>
          </div>
          <div class="form-group">
            <label for="ExpiryTime">有效期</label>
            <div>
              {{form.expiryTime}}
            </div>
          </div>

        </CollapedBox>

        <CollapedBox expandCard>
          <template v-slot:header>
            联系信息
          </template>
          <div>
            <div class="form-group has-feedback">
              <label for="fullName">联系人</label>
              <div>
                {{form.address.fullName}}
              </div>
            </div>
            <div class="form-group has-feedback">
              <label for="mobile">手机</label>
              <div>
                {{form.address.mobile}}
              </div>
            </div>
            <div class="form-group has-feedback">
              <label for="tel">电话</label>
              <div>
                {{form.address.tel}}
              </div>
            </div>
            <div class="form-group has-feedback">
              <label for="mobile">地址</label>
              <div>
                {{form.address.areaName}} {{form.address.street}}
              </div>
            </div>
          </div>
        </CollapedBox>
        <div>
          <router-link v-if="form.panoid" :to="{name: 'PanoFullScreen', params: { panoId: form.panoid }}">
            <div>
              <div>已选全景</div>
              <img :src="(form.panoUrl||'').replace('/0/0/0/0/', '/600/0/0/0/')" />
            </div>
          </router-link>
          <div v-else>
            未选全景
          </div>
        </div>
        <FacilityTXT :facilitys="form.facilitys">
        </FacilityTXT>
        <CollapedBox ref="AddInnUser" maximizeIcon @maximized="showQrcode('AddInnUser')">
          <template v-slot:header>
            店铺二维码
          </template>
          <img v-if="qrcodeType=='AddInnUser'&&!qrcodeLoading" :src="qrcode" class="img-fluid" />
          <h4 v-else class="text-center">
            <i class="fas fa-sync fa-spin"></i>
          </h4>
        </CollapedBox>
        <CollapedBox ref="InnInvite" maximizeIcon @maximized="showQrcode('InnInvite')">
          <template v-slot:header>
            邀请注册
          </template>
          <img v-if="qrcodeType=='InnInvite'&&!qrcodeLoading" :src="qrcode" class="img-fluid" />
          <h4 v-else class="text-center">
            <i class="fas fa-sync fa-spin"></i>
          </h4>
        </CollapedBox>
        <CollapedBox v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||isOwner" ref="SetInnMaster" maximizeIcon @maximized="form.user?null:showQrcode('SetInnMaster')">
          <template v-slot:header>
            设置店主（老板）
          </template>
          <div v-if="form.user&&qrcodeType!='SetInnMaster'">
            <div>
              <table class="table table-bordered table-sm table-hover ">
                <tbody>
                  <tr v-for="e in [form.user]">
                    <td>
                      <div style="width:25px;">
                        <img v-if="e.avatarUrl" class="img-fluid" :src="e.avatarUrl.replace('/0/0/0/0/', '/50/50/1/0/')">
                        <img v-else class="img-fluid" src="/images/avatar-default.jpg">
                      </div>
                    </td>
                    <td>{{e.userName}}</td>
                    <td>{{e.nickName||e.name}}</td>
                    <td>{{e.phoneNumber}}</td>
                    <!--<td class="text-center"><i class="fas  fa-edit"></i></td>-->
                  </tr>
                </tbody>
              </table>
            </div>
            <!--{{form.user}}-->
            <button class="btn btn-primary" @click="showQrcode('SetInnMaster')">修改</button>
          </div>
          <template v-else>
            <h4 v-if="qrcodeLoading" class="text-center">
              <i class="fas fa-sync fa-spin"></i>
            </h4>
            <img v-else :src="qrcode" class="img-fluid" />
          </template>
          <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetInnMaster')">绑定二维码</button>-->
        </CollapedBox>
        <CollapedBox v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||isOwner" ref="SetInnEditor" maximizeIcon @maximized="form.editorUser?null:showQrcode('SetInnEditor')">
          <template v-slot:header>
            设置编辑（摄影师）
          </template>
          <div v-if="form.editorUser&&qrcodeType!='SetInnEditor'">
            <div>
              <table class="table table-bordered table-sm table-hover ">
                <tbody>
                  <tr v-for="e in [form.editorUser]">
                    <td>
                      <div style="width:25px;">
                        <img v-if="e.avatarUrl" class="img-fluid" :src="e.avatarUrl.replace('/0/0/0/0/', '/50/50/1/0/')">
                        <img v-else class="img-fluid" src="/images/avatar-default.jpg">
                      </div>
                    </td>
                    <td>{{e.userName}}</td>
                    <td>{{e.nickName||e.name}}</td>
                    <td>{{e.phoneNumber}}</td>
                    <!--<td class="text-center"><i class="fas  fa-edit"></i></td>-->
                  </tr>
                </tbody>
              </table>
            </div>
            <!--{{form.editorUser}}-->
            <button class="btn btn-primary" @click="showQrcode('SetInnEditor')">修改</button>
            <button class="btn btn-primary" @click="deleteEditorUser">删除</button>
          </div>
          <template v-else>
            <h4 v-if="qrcodeLoading" class="text-center">
              <i class="fas fa-sync fa-spin"></i>
            </h4>
            <img v-else :src="qrcode" class="img-fluid" />
          </template>
          <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetInnEditor')">绑定二维码</button>-->
        </CollapedBox>
        <CollapedBox v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||isOwner" ref="SetInnService" maximizeIcon @maximized="form.employees.length!=0?null:showQrcode('SetInnEmployee')">
          <template v-slot:header>
            设置客服（店员）
          </template>
          <template v-if="qrcodeType=='SetInnEmployee'">
            <h4 v-if="qrcodeLoading" class="text-center">
              <i class="fas fa-sync fa-spin"></i>
            </h4>
            <img v-else :src="qrcode" class="img-fluid" />
          </template>
          <div v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||isOwner">
            <button class="btn btn-primary" @click="showQrcode('SetInnEmployee');">添加客服</button>
          </div>
          <div>
            <table class="table table-bordered table-sm table-hover ">
              <tbody>
                <tr v-for="e in form.employees">
                  <td>
                    <div style="width:25px;">
                      <img v-if="e.avatarUrl" class="img-fluid" :src="e.avatarUrl.replace('/0/0/0/0/', '/50/50/1/0/')">
                      <img v-else class="img-fluid" src="/images/avatar-default.jpg">
                    </div>
                  </td>
                  <td>{{e.userName}}</td>
                  <td>{{e.nickName||e.name}}</td>
                  <td>{{e.phoneNumber}}</td>
                  <!--<td class="text-center"><i class="fas  fa-edit"></i></td>-->
                </tr>
              </tbody>
            </table>
          </div>
          <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetInnService')">绑定二维码</button>-->
        </CollapedBox>
        <CollapedBox ref="SetInnService" collapseIcon>
          <template v-slot:header>
            相关证件
          </template>
          <div class="form-group">
            <router-link :to="{name:'InnLicenses'}">
              <button class="btn btn-primary">上传证件</button>
            </router-link>
          </div>
          <div class="form-group">
            <label>身份证</label>
            <FileBox v-if="form.idCard" :file="form.idCard"></FileBox>
          </div>
          <div class="form-group">
            <label>营业执照</label>
            <FileBox v-if="form.businessLicense" :file="form.businessLicense"></FileBox>
          </div>
          <div class="form-group">
            <label>特殊行业许可证</label>
            <FileBox v-if="form.specialLicense" :file="form.specialLicense"></FileBox>
          </div>
          <div class="form-group">
            <label>消防许可证</label>
            <FileBox v-if="form.fireLicense" :file="form.fireLicense"></FileBox>
          </div>
          <div class="form-group">
            <label>卫生许可证</label>
            <FileBox v-if="form.healthLicense" :file="form.healthLicense"></FileBox>
          </div>
        </CollapedBox>
        <div style="height:calc(100vh - 170px);max-height:350px">
          <aMap ref="map" :mapConfig="{zoom:16}"></aMap>
        </div>

      </div>
    </form>
    <Footer>
      <div>
        <router-link :to="{name:'InnPay',query:{innId:id}}" class="mr-2">
          <button class="btn btn-danger">支付年费</button>
        </router-link>
        <router-link v-if="$inRole('管理员')||$inRole('客栈编辑')||$inRole('客栈代理')||$inRole('客栈代理员工')||isOwner" :to="{name:'InnEdit'}">
          <button class="btn btn-primary">修改</button>
        </router-link>
      </div>
    </Footer>
  </div>
</template>

<script>
  import {
    aMap,
    PanoLib,
    CollapedBox,
    FacilityTXT,
    Footer,
    FileBox,
    AutoTextareaView,
  } from 'common'

  export default {
    components: {
      aMap,
      PanoLib,
      CollapedBox,
      FacilityTXT,
      Footer,
      FileBox,
      AutoTextareaView,
    },
    data() {
      return {
        id: this.$route.params.innId,
        form: {
          id: '',
          title: '',
          description: '',
          address: {
            area: null,
            street: '',
            tel: '',
            mobile: '',
            fullName: ''
          },
          expiryTime: '',
          panoUrl: '',
          lng: 0,
          lat: 0,
          facilitys: [],
          businessLicense: null,
          idCard: null,
          specialLicense: null,
          fireLicense: null,
          healthLicense: null,
          closed: false,
        },
        qrcodeLoading: false,
        qrcode: null,
        qrcodeType: null,
        editEditorUser: false,
        isOwner: false,
        isEmployee: false,
        loading: false,
      }
    },
    computed: {
    },
    created() {
      //this.loadData()
      this.getIsOwner()
      this.getIsEmployee()
    },
    mounted() {
      console.log(this.$refs)
      //$(this.$refs.AddInnUser.$el).on('maximized.lte.cardwidget', () => {
      //  this.showQrcode('AddInnUser')
      //})
      //$(this.$refs.InnInvite.$el).on('maximized.lte.cardwidget', () => {
      //  this.showQrcode('InnInvite')
      //})
      //$(this.$refs.SetInnMaster.$el).on('maximized.lte.cardwidget', () => {
      //  this.showQrcode('SetInnMaster')
      //})
      //$(this.$refs.SetInnEditor.$el).on('maximized.lte.cardwidget', () => {
      //  this.showQrcode('SetInnEditor')
      //})
      //$(this.$refs.SetInnService.$el).on('maximized.lte.cardwidget', () => {
      //  this.showQrcode('SetInnEmployee')
      //})

      var p = [];
      p.push(this.loadData())
      p.push(new Promise((resolve, reject) => {
        this.$refs.map.$once('MapReady', resolve)
      }))
      Promise.all(p).then(this.setMark)
    },
    methods: {
      deleteEditorUser() {

      },
      setMark() {
        console.log(123, this.form)
        if (this.form) {
          if (this.form.lat == 0 && this.form.lng == 0) {
            return;
          }
          this.$refs.map.addMark('markpoint', {
            gpsLat: this.form.lat,
            gpsLng: this.form.lng
          })
          this.$refs.map.setCenterLngLat(this.form.lng, this.form.lat, 'GPS')
        }
      },
      initData() {
      },
      loadData() {
        this.loading = true
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Inns/${this.id}` : `/Api/InnAdmin/Inns/${this.id}`;
        return this.$axios.get(url)
          .then((response) => {
            console.log(12, response)
            let d = response.data
            this.form = d;
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      },
      getIsOwner() {
        this.$axios.get(`/Api/InnAdmin/Inns/IsOwner/${this.$route.params.innId}`)
          .then((response) => {
            this.isOwner = response.data;
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getIsEmployee() {
        this.$axios.get(`/Api/InnAdmin/Inns/IsEmployee/${this.$route.params.innId}`)
          .then((response) => {
            this.isEmployee = response.data;
          })
          .catch((error) => {
            console.log(error)
          })
      },
      showQrcode(QRpath) {
        this.qrcodeType = QRpath
        this.qrcodeLoading = true;
        this.qrcode = null;
        this.$axios.get(`/Api/Weixin/QRCode/${QRpath}/${this.id}`)
          .then((response) => {
            this.qrcodeLoading = false;
            this.qrcode = `${window.global.API_BASE_URL}/Api/QRCode?url=${response.data.url}`
          })
          .catch((error) => {
            console.error(error)
          })
      },
      //hideQrcode() {
      //    //this.$bus.$emit('qrcodeModal-modal-hide', {});
      //    this.$refs.qrcodeModal.hide()

      //},
    },
    watch: {
    },

  }
</script>
<style lang="css">
</style>
