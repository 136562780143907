<template lang="html">
  <div v-if="loading">
    <i class="fas fa-spinner fa-pulse"></i>
    加载中…
  </div>
  <div v-else class="panoedit" style="position:fixed;top:0;left:0;right:0;bottom:0;z-index:1050">
    <div class="panosize" :class="makeSizeClass(viewType)">
      <Krpano ref="Krpano" :panoId="panoId" height="100%" :onKrpanoReady="onKrpanoReady" :krpanoSettings="krpanoSetting" :sceneVars="sceneVars" :onLoadComplete="onXmlComplete">
        <div class="frame">
          <button v-if="viewType!=null && viewType!='setLinkView'" @click="viewType=null" type="button" class="btn text-shadow" style="position:absolute;top:0;right:0;">
            <i class="fas fa-times"></i>
          </button>
          <template v-if="viewType=='hotspot'">
            <div class="PanoHotspotEdit toolbar">
              <template v-for="(item,index) in form.hotspots">
                <template v-if="index==editHotspotIndex">
                  <div class="text-shadow" style="pointer-events:none;font-size:12px;">当前热点：{{item.title}}</div>

                  <div v-if="showRange=='scale'" class="rangeBar">
                    <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-default" @click="item.scale=numberMath(item.scale,-0.05,1,0.05).toFixed(2);setHotspot(item)"><i class="fas fa-minus"></i></button>
                      <button type="button" class="btn btn-default" @click="item.scale=0.5;setHotspot(item)">复位</button>
                      <button type="button" class="btn btn-default" @click="item.scale=numberMath(item.scale,0.05,1,0.05).toFixed(2);setHotspot(item)"><i class="fas fa-plus"></i></button>
                    </div>
                    <div class="my-2">
                      <input type="range" class="custom-range" v-model="item.scale" @change="setHotspot(item)" @dblclick="item.scale=0.5;setHotspot(item)" step="0.01" min="0.05" max="1" />
                    </div>
                  </div>
                  <div v-if="showRange=='rotate'" class="rangeBar">
                    <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-default" @click="item.rotate=numberMath(item.rotate,-5,180,-180).toFixed(0);setHotspot(item)"><i class="fas fa-minus"></i></button>
                      <button type="button" class="btn btn-default" @click="item.rotate=0;setHotspot(item)">复位</button>
                      <button type="button" class="btn btn-default" @click="item.rotate=numberMath(item.rotate,5,180,-180).toFixed(0);setHotspot(item)"><i class="fas fa-plus"></i></button>
                    </div>
                    <div class="my-2">
                      <input type="range" class="custom-range" v-model="item.rotate" @change="setHotspot(item)" @dblclick="item.rotate=0;setHotspot(item)" step="1" min="-180" max="180" />
                    </div>
                  </div>
                  <div class="text-right">
                    <div class="btn-group btn-group-sm" role="group" aria-label="Basic example" style="width:100%">
                      <button type="button" class="btn" :class="{'btn-primary':showRange=='scale','btn-default':showRange!='scale'}" @click="showRange=='scale'?showRange=null:showRange='scale'" @dblclick="item.scale=0.5;setHotspot(item)">缩放：{{item.scale}}</button>
                      <button type="button" class="btn" :class="{'btn-primary':showRange=='rotate','btn-default':showRange!='rotate'}" @click="showRange=='rotate'?showRange=null:showRange='rotate'" @dblclick="item.rotate=0;setHotspot(item)">旋转：{{item.rotate}}°</button>
                      <button type="button" class="btn btn-default" @click="showHotspotIconLibDialog(index)">图标</button>
                      <button type="button" class="btn btn-warning" @click="showHotspotLinkDialog(index)">编辑</button>
                    </div>
                  </div>
                  <div class="text-right">
                    <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-default" @click="item.distorted=!item.distorted;setHotspot(item)">扭曲：{{item.distorted?'是':'否'}}</button>
                      <button type="button" class="btn btn-default" @click="item.zoom=!item.zoom;setHotspot(item)">跟随缩放：{{item.zoom?'是':'否'}}</button>
                      <button type="button" class="btn btn-default" @click="item.showTitle=!item.showTitle;setHotspot(item)">显示名称：{{item.showTitle?'是':'否'}}</button>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <ul class="list-unstyled text-shadow toolbar" style="position:absolute; top:35px;left:0;right: 2.5rem;overflow:auto;white-space:nowrap;font-size:14px;margin:0;opacity:0.75;">
              <li v-for="(item,index) in form.hotspots" class="hotspotItem" style="pointer-events:auto;width: 52px;" :class="{'active':editHotspotIndex==index}">
                <a @click="setEditHotspot(index)">
                  <div style="text-align:center;font-size:0;"><HotspotIconView :icon="item.icon" bgcolor="#ccc"></HotspotIconView></div>
                  <div class="text-truncate" style="text-align:center;font-size:12px;">
                    {{item.title||'未命名'}}
                  </div>
                </a>
              </li>
              <li style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;">
                <a @click="addNewHotspot()">
                  <div style="text-align:center">
                    <div class="HotspotIconView" style="width:50px;height:50px;background-color:#ccc;    display: inline-block;">
                      <i class="fas fa-plus fa-2x" style="line-height:50px;"></i>
                    </div>
                  </div>
                  <div style="text-align:center;font-size:12px;">
                    添加热点
                  </div>
                </a>
              </li>
            </ul>
          </template>
          <div v-if="viewType!='setLinkView'" style="position:absolute; top:10px;left:20px;right: 25px;pointer-events:none;" class="toolbar text-shadow text-center text-truncate">
            {{form.title}}
          </div>
          <template v-if="viewType==null">
            <div style="position:absolute; bottom:0;left:0;right: 0;pointer-events:none;" class="toolbar">
              <div>
                <div class="text-shadow" style="display:inline-block;pointer-events:auto">
                  <ul class="links list-unstyled">
                    <li v-for="(i,index) in form.links">
                      <a v-if="i.type=='Url'" @click="editLink(index)" :class="{ 'text-danger': $v.form.links.$each[index].$invalid }"><i class="fas fa-external-link-square-alt fa-2x"></i>{{i.title}}</a>
                      <a v-else-if="i.type=='Email'" @click="editLink(index)" :class="{ 'text-danger': $v.form.links.$each[index].$invalid }"><i class="fas fa-envelope-square fa-2x"></i>{{i.title}}</a>
                      <a v-else-if="i.type=='Tel'" @click="editLink(index)" :class="{ 'text-danger': $v.form.links.$each[index].$invalid }"><i class="fas fa-phone-square fa-2x"></i>{{i.title}}</a>
                    </li>
                    <li v-if="form.links.length<$v.form.links.$params.maxLength.max">
                      <a @click="addLink(form.links.length)"><i class="far fa-plus-square fa-2x"></i>添加链接</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap">
                <button type="button" class="btn btn-sm btn-danger mr-1" style="pointer-events:auto" @click.prevent="cancel">取消</button>
                <button type="button" class="btn btn-sm btn-default mr-1" style="pointer-events:auto" @click.prevent="$refs.baseSetting.show=true">设置</button>
                <button type="button" class="btn btn-sm btn-default mr-1" style="pointer-events:auto" @click.prevent="viewType='saveView'">封面</button>
                <button type="button" class="btn btn-sm btn-default mr-1" style="pointer-events:auto" @click.prevent="viewType='setView';recoverView()">视角</button>
                <button type="button" class="btn btn-sm btn-default mr-1" style="pointer-events:auto" @click.prevent="viewType='hotspot'">热点<span class="badge badge-pill badge-primary">{{form.hotspots.length}}</span></button>
                <button type="button" class="btn btn-sm btn-primary" style="pointer-events:auto" @click.prevent="form.private=false;Update()" :disabled="updateing">保存</button>
              </div>
            </div>
          </template>
          <template v-if="viewType=='setView'">
            <div style="position:absolute; bottom:0;left:0;right: 0;pointer-events:none;" class="toolbar">
              <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap">
                <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="viewType=null">取消</button>
                <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="recoverView">恢复</button>
                <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="resetView">重置</button>
                <button type="button" class="btn btn-sm btn-primary" style="pointer-events:auto" @click.prevent="setPanoView">保存视角</button>
              </div>
            </div>
            <div style="position:absolute; bottom:40px;left:10px;pointer-events:none;" class="toolbar text-shadow">
              <div>视场:<span>{{view.fov}}</span></div>
              <div>水平:<span>{{view.hLookAt}}</span></div>
              <div>垂直:<span>{{view.vLookAt}}</span></div>
            </div>
            <div style="position:absolute;right:5px;top:30px;bottom:50px;" class="toolbar">
              <div class="text-shadow" style="display:inline-block;height:100%;font-size:12px;writing-mode:vertical-lr;letter-spacing:5px;text-align:center">
                垂直视角限制
              </div>
              <vue-slider v-model="vLookAtRange"
                          direction="ttb"
                          :min="-90"
                          :max="90"
                          :enable-cross="false"
                          :interval="0.1"
                          :contained="true"
                          :drag-on-click="true"
                          :tooltip="'always'"
                          style="display: inline-block;position:absolute;right:15px;top:5px;bottom:5px;">
                <template v-slot:dot="{ value, index, focus }">
                  <template v-if="index==1">
                    <div class="custom-dot"></div>
                  </template>
                </template>
                <template v-slot:tooltip="{ value, index, focus }">
                  <template v-if="index==1">
                    <div class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-left" style="background-color:pink;border-color:pink;color:black;">
                      <span class="vue-slider-dot-tooltip-text">{{ value }}</span>
                    </div>
                  </template>
                </template>
              </vue-slider>
            </div>
          </template>
          <template v-if="viewType=='setLinkView'">
            <div style="position:absolute; top:10px;left:0;right: 0;pointer-events:none;" class="toolbar text-shadow text-center">
              {{((linkdata||{}).linkItem||{}).title}}
            </div>
            <div style="position:absolute; bottom:40px;left:10px;pointer-events:none;" class="toolbar text-shadow">
              <div>视场:<span>{{view.fov}}</span></div>
              <div>水平:<span>{{view.hLookAt}}</span></div>
              <div>垂直:<span>{{view.vLookAt}}</span></div>
            </div>
            <div style="position:absolute; bottom:0;left:0;right: 0;pointer-events:none;" class="toolbar">
              <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap" class="toolbar">
                <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="outLinkView">取消</button>
                <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="recoverLinkView">恢复</button>
                <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="resetView">重置</button>
                <button type="button" class="btn btn-sm btn-primary mr-2" style="pointer-events:auto" @click.prevent="saveLinkView">设置目标视角</button>
              </div>
            </div>
          </template>
          <div v-if="viewType=='saveView'" style="position:absolute; bottom:0;left:0;right: 0;pointer-events:none;" class="toolbar">
            <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap">
              <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="viewType=null">取消</button>
              <div class="btn-group" style="pointer-events:auto" role="group">
                <button type="button" class=" btn btn-sm btn-default mr-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  视角
                </button>
                <div class="dropdown-menu">
                  <input type="button" class="dropdown-item" value="常规视角" @click="view_normal()" />
                  <input type="button" class="dropdown-item" value="鱼眼视角" @click="view_fisheye()" />
                  <input type="button" class="dropdown-item" value="立体视角" @click="view_stereographic()" />
                  <input type="button" class="dropdown-item" value="构架视角" @click="view_architectural()" />
                  <input type="button" class="dropdown-item" value="超广角视角" @click="view_pannini()" />
                  <input type="button" class="dropdown-item" value="小行星视角" @click="view_littleplanet()" />
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click="resetPanoView()">复位</button>
              <button type="button" class="btn btn-sm btn-primary mr-2" style="pointer-events:auto" @click="krpano_makeScreenshot(UploadPanoCover)">保存封面</button>
            </div>
          </div>
        </div>
      </Krpano>
    </div>
    <HotspotIconLib ref="HotspotIconLib" libUrl="/Api/InnAdmin/HotspotIcons" :onSelected="HotspotIconLibSelected"></HotspotIconLib>
    <SimpleModal ref="HotspotLink" :zIndex="1040">
      <div style="position:relative">
        <h6>编辑热点链接</h6>
        <div class="" style="position:absolute;top:-5px;right:0;padding:5px" @click="$refs.HotspotLink.show=false">
          <i class="fa fa-times"></i>
        </div>

        <div v-if="linkdata">
          <div class="form-group">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" style="font-weight:700">名称</span>
              </div>
              <input class="form-control" type="text" v-model.trim="linkdata.title" @change="setHotspot(linkdata)" />
            </div>
            <span class="text-danger"></span>
          </div>
          <div class="form-group">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" style="font-weight:700">类型</span>
              </div>
              <select class="form-control" id="Type" v-model:value="linkdata.linkType">
                <option v-for="i in types" :value="i.value">{{i.key}}</option>
              </select>
            </div>
            <span class="text-danger"></span>
          </div>
          <template v-if="linkdata.linkType=='Pano'">
            <button type="button" class="btn btn-outline-primary mb-1" @click="showPanoLibDialog()">{{linkdata.linkItem?`全景:${linkdata.linkItem.title}`:'选择全景'}}</button>
            <div v-if="linkdata.linkItem">
              <template v-for="item in [linkdata.linkItem]">
                <button type="button" class="btn btn-outline-primary mb-1" @click="SetLinkView(linkdata.linkItemGUID)">设置目标全景视角</button>
                <div>
                  <span>视场:<span>{{linkdata.linkFOV}}</span></span>&nbsp;
                  <span>水平:<span>{{linkdata.linkHLookAt}}</span></span>&nbsp;
                  <span>垂直:<span>{{linkdata.linkVLookAt}}</span></span>
                </div>
              </template>
            </div>
          </template>
          <div v-if="linkdata.linkType=='File'">
            <button v-if="!linkdata.file" type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
            <div v-if="linkdata.file&&linkdata.file.isImage">
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="linkdata.file=null" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <a>
                <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;background-position:center" :style="{'background-image':'url(' + linkdata.file.url + ')'}"></div>
              </a>
            </div>
            <div v-else-if="linkdata.file&&linkdata.file.isVideo">
              {{linkdata.file.name}}
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="linkdata.file=null" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <video style="width:100%;" :src="linkdata.file.url" :poster="((linkdata.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
            </div>
            <div v-else-if="linkdata.file&&linkdata.file.isAudio">
              <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
            </div>
          </div>
          <div v-if="linkdata.linkType!='Pano'">
            <label>内容</label>
            <PellEditor v-model.trim="linkdata.description"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
          </div>
          <template v-if="linkdata.linkType=='Link'">
            <label>链接</label>
            <input v-if="$inRole('管理员')||$inRole('VIP')" class="form-control" type="url" v-model.trim="linkdata.url" placeholder="请输入 Url 地址" />
            <input v-else class="form-control" value="需要开通 VIP" disabled />
          </template>
        </div>
        <div style="display:flex;justify-content:flex-end;padding: 10px 0 0;">
          <button class="btn btn-danger btn-sm mr-2" @click="showDeleteHotspot()">删除</button>
          <button type="button" class="btn btn-primary btn-sm" @click="$refs.HotspotLink.show=false">确定</button>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="deleteHotspot" :zIndex="1040">
      <div>
        <h5>删除热点</h5>
      </div>
      确定删除此热点？
      <div style="display:flex;justify-content:flex-end;">
        <button type="button" class="btn btn-primary btn-sm mr-2" @click="$refs.deleteHotspot.show=false">取消</button>
        <button class="btn btn-danger btn-sm" @click="deleteHotspot(linkdata)">删除</button>
      </div>
    </SimpleModal>
    <SimpleModal ref="baseSetting" :zIndex="1040">
      <div style="position:relative">
        <h6>
          基本设置
        </h6>
        <div class="" style="position:absolute;top:0;right:0;padding:5px" @click="$refs.baseSetting.show=false">
          <i class="fa fa-times"></i>
        </div>
        <div>
          <div class="card-body p-2">
            <!--<div class="form-group">
                <img class="img-fluid" :src="form.firstImage.url.replace('/0/0/0/0/', '/600/0/0/0/')" />
            </div>-->
            <div class="form-group">
              <label for="title">标题</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入标题">
              <div class="invalid-feedback">
                <span v-if="!$v.form.title.required">请输入标题</span>
                <span v-if="!$v.form.title.minLength">标题至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
                <span v-if="!$v.form.title.maxLength">标题最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
              </div>
            </div>

            <div class="form-group">
              <label for="panoText">内容</label>
              <!--<textarea type="text" class="form-control" id="panoText" placeholder="输入内容" v-model:text="form.text"></textarea>-->
              <PellEditor v-model.trim="form.text"
                          :options="options"
                          :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
            </div>
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchLittlePlanetIntro" v-model="form.littlePlanetIntro">
              <label class="custom-control-label" for="customSwitchLittlePlanetIntro">小行星视图开场</label>
            </div>
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchPrivate" v-model="form.private">
              <label class="custom-control-label" for="customSwitchPrivate">私有</label>
            </div>
            <div class="form-group">
              <label>天气效果</label>
              <select class="form-control" v-model="form.weather">
                <option v-for="i in weathers" :value="i.key">{{i.key}}</option>
              </select>
            </div>
            <button v-if="form.audio" type="button" class="btn btn-outline-primary mb-1" @click="showAudioLibDialog()">音乐:{{form.audio.name}}</button>
            <button v-else type="button" class="btn btn-outline-primary mb-1" @click="showAudioLibDialog()">选择背景音乐</button>
            <div v-if="form.audio" class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customAudioAutoPlay" v-model="form.audioAutoPlay">
              <label class="custom-control-label" for="customAudioAutoPlay">自动播放音乐</label>
            </div>
            <div v-if="false" class="form-group">
              <label>自动漫游模式</label>
              <select class="form-control" v-model="form.autoTourType">
                <option value=0>附近全景</option>
                <option value=1>本人当天</option>
              </select>
            </div>
            <div class="form-group">
              <label for="panoTitle">公共标签</label>
              <CheckBox checkprop="name"
                        :checkList="publicTags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
            </div>
            <div class="form-group">
              <label for="panoTitle">私有标签</label>
              <CheckBox checkprop="name"
                        :checkList="privateTags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
            </div>
            <div class="text-right">
              <button type="button" class="btn btn-primary btn-sm" @click="$refs.baseSetting.show=false">确定</button>
            </div>
          </div>
        </div>

      </div>
    </SimpleModal>
    <SimpleModal ref="hotspotitem" :onHide="hotspotitem_onHide" :zIndex="1040">
      <div v-if="showitem">
        <div v-if="showitem.linkType=='File'">
          <div v-if="showitem.file">
            <div v-if="showitem.file.isImage" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <img :src="showitem.file.url.replace('/0/0/0/0/', '/600/0/0/0/')" />
            </div>
            <div v-else-if="showitem.file.isAudio" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <audio :src="showitem.file.url" controls />
            </div>
            <div v-else-if="showitem.file.isVideo" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <video style="width:100%;" :src="showitem.file.url" :poster="((showitem.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
            </div>
            <div v-else>
              <pre>{{showitem}}</pre>
            </div>
          </div>
          <div v-else>
            <pre>{{showitem}}</pre>
          </div>
        </div>
        <div v-else-if="showitem.linkType=='Text'" class="panoinfo">
          <h4>{{showitem.title}}</h4>
          <p>{{showitem.description}}</p>
        </div>
        <div v-else-if="showitem.linkType=='Link'" class="panoinfo">
          <h4>{{showitem.title}}</h4>
          <p>
            这是个外部链接
          </p>
          <a :href="showitem.url" target="_blank">{{showitem.url}}</a>
          <a :href="showitem.url" target="_blank">点击此处打开</a>
        </div>
        <div v-else>
          <pre>{{showitem}}</pre>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="linkEdit" @Hide="linkEditIndex=null" :zIndex="1040">
      <div v-if="linkEditIndex!=null">
        <div v-for="i in [$v.form.links.$each.$iter[linkEditIndex]]">
          <div v-if="i">
            <div class="form-group">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="font-weight:700">类型</span>
                </div>
                <select class="form-control" id="Type" v-model:value="i.$model.type" @change="linkChangeType(linkEditIndex)">
                  <option value="Url">链接</option>
                  <option value="Email">邮件</option>
                  <option value="Tel">电话</option>
                </select>
              </div>
              <span class="text-danger"></span>
            </div>
            <div class="form-group">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="font-weight:700">名称</span>
                </div>
                <input type="text" class="form-control" placeholder="名称" maxlength="4" :class="{ 'is-invalid': i.title.$error }" v-model.trim:value="i.title.$model" />
              </div>
              <span class="text-danger"></span>
              <span class="text-danger" v-if="!i.title.required">请输入名称</span>
              <span class="text-danger" v-if="!i.title.minLength">名称至少 {{i.title.$params.minLength.min}} 个字符</span>
              <span class="text-danger" v-if="!i.title.maxLength">名称最多 {{i.title.$params.maxLength.max}} 个字符</span>
            </div>
            <div class="form-group">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="font-weight:700">链接</span>
                </div>
                <template v-if="$inRole('管理员')||$inRole('Vip')">
                  <input v-if="i.$model.type=='Url'" type="url" class="form-control" placeholder="链接地址" :class="{ 'is-invalid': i.link.$error }" v-model.trim:value="i.link.$model" />
                </template>
                <template v-else>
                  <input v-if="i.$model.type=='Url'" class="form-control" :class="{ 'is-invalid': i.link.$error }" value="需要开通 VIP" disabled />
                </template>
                <input v-if="i.$model.type=='Email'" type="email" class="form-control" placeholder="邮件地址" :class="{ 'is-invalid': i.link.$error }" v-model.trim:value="i.link.$model" />
                <input v-if="i.$model.type=='Tel'" type="tel" class="form-control" placeholder="电话号码" :class="{ 'is-invalid': i.link.$error }" v-model.trim:value="i.link.$model" />
              </div>
              <span class="text-danger"></span>
              <span class="text-danger" v-if="!i.link.required">请输入内容</span>
              <span class="text-danger" v-else-if="!i.link.minLength">至少 {{i.link.$params.minLength.min}} 个字符</span>
              <span class="text-danger" v-else-if="!i.link.maxLength">最多 {{i.link.$params.maxLength.max}} 个字符</span>
              <span class="text-danger" v-else-if="!i.link.isLink">格式不正确</span>
            </div>
          </div>
          <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap">
            <button type="button" class="btn btn-sm btn-danger mr-2" style="pointer-events:auto" @click.prevent="deleteLink(linkEditIndex)">删除</button>
            <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="$refs.linkEdit.show=false" :disabled="i.link.$invalid">确定</button>
          </div>
        </div>
      </div>
    </SimpleModal>
    <PanoLib ref="PanoLib" :libUrl="libPanosUrl" :params="{innId:$route.params.innId}" @submit="hotspotPanoLibSelected"></PanoLib>
    <FileLib ref="FileLib" :params="{innId:$route.params.innId}" :libUrl="`/Api/InnAdmin/Libs/${$route.params.innId}`" :panosUploadUrl="`/Api/InnAdmin/Panos/${$route.params.innId}/Upload`" :filesUploadUrl="`/Api/InnAdmin/Libs/${$route.params.innId}/Upload`" :tagsUrl="`/Api/InnAdmin/Tags/All/${$route.params.innId}`" fileUrl="/Api/InnAdmin/Files" panoUrl="/Api/InnAdmin/Panos" @submit="FileLibSelected"></FileLib>
    <AudioLib ref="AudioLib" :params="{innId:$route.params.innId}" libUrl="/Api/InnAdmin/Audios" :onSelected="AudioLibSelected"></AudioLib>
    <BreakLeave v-if="cantleave"></BreakLeave>
  </div>
</template>

<script>
  import { uuid } from 'vue-uuid'
  import {
    BreakLeave,
    AudioLib,
    PellEditor,
    SimpleModal,
    Krpano,
    HotspotIconLib,
    HotspotIconView,
    FileLib,
    PanoLib,
    CheckBox,
  } from 'common'
  //import AudioLib from '@/components/AudioLib.vue'
  //import BreakLeave from '@/components/BreakLeave.vue'
  //import SimpleModal from '@/components/SimpleModal.vue'
  //import PellEditor from '@/components/PellEditor.vue'
  //import Krpano from '@/components/Krpano'
  //import HotspotIconLib from '@/components/HotspotIconLib.vue'
  //import HotspotIconView from '@/components/HotspotIconView.vue'
  //import FileLib from '@/components/FileLib.vue'
  //import PanoLib from '@/components/PanoLib.vue'
  //import CheckBox from '@/components/CheckBox.vue'
  import VueSlider from 'vue-slider-component'
  import { required, minLength, maxLength } from 'vuelidate/lib/validators'

  export default {
    components: {
      BreakLeave,
      AudioLib,
      PellEditor,
      SimpleModal,
      Krpano,
      HotspotIconLib,
      HotspotIconView,
      FileLib,
      PanoLib,
      VueSlider,
      CheckBox,
    },
    data() {
      return {
        krpano: null,
        cantleave: true,
        publicTags: [],
        privateTags: [],
        selectedIndex: null,
        form: null,
        FileMultipleLibOnSubmit: null,
        PanoMultipleLibOnSubmit: null,
        FileMultipleLibType: null,
        updateing: false,
        baseSettingShow: false,
        showMap: false,
        showNewLocation: false,
        NewLocationP: { lat: 0, lng: 0 },
        krpano: null,
        editHotspotIndex: null,
        linkEditIndex: null,
        linkdata: null,
        types: [
          {
            key: '全景',
            value: 'Pano',
          },
          {
            key: '其他素材',
            value: 'File',
          },
          {
            key: '文字',
            value: 'Text',
          },
          {
            key: '链接',
            value: 'Link',
          },
        ],
        view: {
          fov: 120,
          hLookAt: 0,
          vLookAt: 0
        },
        krpanoSetting: {
          vars: {
            'events.onviewchange': 'js(getPanoHotspotView());',
            //'events.onnewscene': 'js(boxPanoHotspotOnnewscene())',
          }
        },
        sceneVars: {
          'view.fovmin': 70,
          'view.fovmax': 140,
          //'view.maxpixelzoom': 2.0,
          'view.fovtype': 'MFOV',
        },
        showPano: false,
        showRange: null,
        options: {
          whiteList: {
            p: [],
            b: [],
            strong: [],
            i: [],
            u: [],
            strike: [],
            br: [],
            //h1: [],
            //h2: [],
            //blockquote: [],
            //hr: [],
            ol: [],
            ul: [],
            li: [],
            //code: [],
            //a: ['href'],
            //img: ['src'],
            sub: [],
            sup: [],
          },
          stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
          stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
        },
        params: [],
        panoId: this.$route.params.panoId,
        fov: 120,
        hLookAt: 0,
        vLookAt: 0,
        viewType: null,
        showitem: null,
        blendModes: [
          { name: '无', blend: null },
          { name: '淡入淡出', blend: 'BLEND(1.0, easeInCubic)' },
          { name: '缩放', blend: 'ZOOMBLEND(2.0, 2.0, easeInOutSine)' },
          { name: '黑出', blend: 'COLORBLEND(2.0, 0x000000, easeOutSine)' },
          { name: '闪光', blend: 'LIGHTBLEND(1.0, 0xFFFFFF, 2.0, linear)' },
          { name: '从右到左', blend: 'SLIDEBLEND(1.0, 0.0, 0.2, linear)' },
          { name: '从上到下', blend: 'SLIDEBLEND(1.0, 90.0, 0.01, linear)' },
          { name: '斜角', blend: 'SLIDEBLEND(1.0, 135.0, 0.4, linear)' },
          { name: '圆形打开', blend: 'OPENBLEND(1.0, 0.0, 0.2, 0.0, linear)' },
          { name: '垂直打开', blend: 'OPENBLEND(0.7, 1.0, 0.1, 0.0, linear)' },
          { name: '水平打开', blend: 'OPENBLEND(1.0, -1.0, 0.3, 0.0, linear)' },
          { name: '椭圆缩放', blend: 'OPENBLEND(1.0, -0.5, 0.3, 0.8, linear)' },
        ],
        roomQuery: {
          pageIndex: 1,
          pageSize: 20,
          sort: "addTime",
          order: 'desc',
          fields: 'Title',
          key: null,
        },
        krpanoInterface: null,
        groups: [],
        inns: {},
        roomId: null,
        loading: false,
        rooms: {},
        vLookAtRange: [-90, 0, 90],
        libPanosUrl: window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Panos` : `/Api/InnAdmin/Panos`,
      }
    },
    validations() {
      return {
        form: {
          title: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50)
          },
          links: {
            maxLength: maxLength(3),
            $each: {
              title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(4)
              },
              link: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(250),
                isLink: (value, p) => {
                  if (p.type == 'Url') {
                    if (value) {
                      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})\b(:[0-9]{1,5})?([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                    } else {
                      return false
                    }
                  }
                  if (p.type == 'Email') {
                    if (value) {
                      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
                    } else {
                      return false
                    }
                  }
                  if (p.type == 'Tel') {
                    if (value) {
                      return /^((0\d{2,3}-\d{7,8})|(1[35847]\d{9}))$/.test(value)
                    } else {
                      return false
                    }
                  }
                  return true
                }
              }
            }
          },
          hotspots: {
            maxLength: maxLength(20),
            $each: {
              url: {
                isUrl: (value, p) => {
                  if (p.linkType == 'Link') {
                    if (value) {
                      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})\b(:[0-9]{1,5})?([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                    } else {
                      return false
                    }
                  }
                  return true
                }
              }
            }
          }
        }
      }
    },
    created() {
      this.loadData()
      //this.loadWeatherTypes()
      //this.loadTags()
      //window.testVue = this
      window.getPanoHotspotView = this.getPanoView
      window.dragHotspot = this.dragHotspot
      window.clickHotspot = this.clickHotspot
      window.AnimationCrop = this.AnimationCrop
    },
    mounted() {
      addEventListener('resize', () => {
        this.$forceUpdate()
      })
    },
    computed: {
    },
    methods: {
      isingroup(guid) {
        for (var i in this.groups) {
          if (this.groups[i] == guid)
            return true
        }
        return false
      },
      SetLinkView(guid) {
        this.editHotspotIndex
        var item = this.linkdata.linkItem
        item.fov = this.linkdata.linkFOV
        item.hLookAt = this.linkdata.linkHLookAt
        item.vLookAt = this.linkdata.linkVLookAt
        this.getItem(item)
        this.$refs.HotspotLink.show = false
        this.viewType = 'setLinkView'
      },
      getItem(item) {
        this.sceneVars = {
          'view.fov': item.fov,
          'view.hlookat': item.hLookAt,
          'view.vlookat': item.vLookAt,
          'view.fovmin': 70,
          'view.fovmax': 140,
          //'view.maxpixelzoom': 2.0,
          'view.fovtype': 'MFOV',
          'view.vlookatmin': item.vLookAtMin,
          'view.vlookatmax': item.vLookAtMax,
        }
        this.panoId = item.guid
        this.hotspots = item.hotspots
      },
      saveLinkView() {
        if (this.krpano) {
          this.linkdata.linkFOV = Number(this.krpano.get("view.fov")).toFixed(2)
          this.linkdata.linkHLookAt = this.math360(Number(this.krpano.get("view.hlookat"))).toFixed(2)
          this.linkdata.linkVLookAt = Number(this.krpano.get("view.vlookat")).toFixed(2)
          this.$message({
            message: '视角保存成功',
            type: 'info',
            zIndex: 9999,
          })
          this.outLinkView()
        }
      },
      outLinkView() {
        this.viewType = 'hotspot'
        this.getItem(this.form)
        this.$refs.HotspotLink.show = true
        this.$forceUpdate()
      },
      saveitemPanoView() {
        if (this.krpano) {
          this.form.fov = Number(this.krpano.get("view.fov")).toFixed(2)
          this.form.hLookAt = this.math360(Number(this.krpano.get("view.hlookat"))).toFixed(2)
          this.form.vLookAt = Number(this.krpano.get("view.vlookat")).toFixed(2)
          this.$message({
            message: '视角保存成功',
            type: 'info',
            zIndex: 9999,
          })
        }
      },
      math360(i) {
        while (i < 0 || i >= 360) {
          i = i > 0 ? i - 360 : i + 360
        }
        return i
      },
      editLink(linkIndex) {
        this.linkEditIndex = linkIndex
        this.$refs.linkEdit.show = true
      },
      addLink(linkIndex) {
        var item = this.newLink()
        this.form.links.splice(linkIndex, 0, item)
        this.linkEditIndex = linkIndex
        this.$refs.linkEdit.show = true
      },
      newLink() {
        return {
          guid: null,
          title: '我的电话',
          link: '',
          type: 'Tel',
        }
      },
      deleteLink(linkIndex) {
        this.$refs.linkEdit.show = false
        this.$nextTick(() => {
          this.form.links.splice(linkIndex, 1)
        })
      },
      linkChangeType(index) {
        console.log('linkChangeType', index)
        const d = { 'Url': '我的网站', 'Email': '我的邮箱', 'Tel': '我的电话' }
        var v = false
        for (var i in d) {
          if (this.form.links[index].title == d[i]) {
            v = true
            break
          }
        }
        if (v) {
          this.form.links[index].title = d[this.form.links[index].type]
        }
      },
      linkimg(type) {
        switch (type) {
          case 0:
            return 'fas fa-link'
            break;
          case 1:
            return 'fas fa-envelope'
            break;
          case 2:
            return 'fas fa-phone'
            break;
          default:
        }
        return 'fa fa-link'
      },
      hotspotitem_onHide() {
        console.log('hide')
        this.showitem = null
      },
      onXmlComplete() {
        if (this.viewType == 'setLinkView') {
          this.removeAllhotspot()
          return
        }
        setTimeout(this.setHotspots, 100)
        //this.$nextTick(this.setHotspots)
        this.krpano.set('view.limitview', 'range')
        this.krpano.set('view.vlookatmin', this.form.vLookAtMin)
        this.krpano.set('view.vlookatmax', this.form.vLookAtMax)
        this.krpano.set('view.vlookat', this.form.vLookAt)
        this.krpano.set('view.hlookat', this.form.hLookAt)
        this.krpano.set('view.fov', this.form.fov)
        console.log(this.form)
      },
      HotspotIconLibSelected(select) {
        this.form.hotspots[this.gethotspotindex].icon = select;
        this.setHotspot(this.form.hotspots[this.gethotspotindex])
      },
      addNewHotspot() {
        if (this.krpano) {
          this.$refs.HotspotIconLib.showDialog((select) => {
            var hotspot = this.newHotspot()
            hotspot.icon = select
            this.setHotspot(hotspot)
            this.editHotspotIndex = this.form.hotspots.push(hotspot) - 1;
            this.showHotspotLinkDialog(this.editHotspotIndex)
          });
        }
      },
      newHotspot() {
        var guid = uuid.v1();
        return {
          addTime: '',
          ath: this.krpano ? this.krpano.get('view.hlookat') : 0,
          atv: this.krpano ? this.krpano.get('view.vlookat') : 0,
          description: "",
          distorted: false,
          file: null,
          gpsLat: 0,
          gpsLng: 0,
          guid: guid,
          icon: {
            guid: null,
            width: 0,
            height: 0,
            fps: 0,
            isAnimation: false,
            edge: 'top',
            imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            //enableText: true,
            textOffsetX: 0,
            textOffsetY: 0,
            textEdge: 'bottom'
          },
          linkFOV: 120,
          linkHLookAt: 0,
          linkItemGUID: null,
          linkType: 'Pano',
          linkVLookAt: 0,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          showTitle: true,
          sortID: 2,
          title: "",
          type: 'Image',
          url: null,
          zoom: false,
        }
      },
      setHotspot(hotspot) {
        const item = hotspot
        if (!item) {
          return;
        }
        const hotspotSettings = {
          name: `hotspot_${item.guid}`,
          ath: item.ath,
          atv: item.atv,
          url: item.icon.imageUrl,
          distorted: item.distorted,
          scale: item.scale,
          scalechildren: true,
          zoom: item.zoom,
          rotate: item.rotate,
          edge: item.icon.edge,
          scalechildren: false,
          //alpha: 0.7,
          onloaded: item.icon.isAnimation ? `js( ${this.$refs.Krpano.getFuncName(this.AnimationCrop)}(${item.icon.width},${item.icon.height},${item.icon.fps},${item.guid}))` : null,
          onclick: `js(clickHotspot(${item.guid}))`,
          ondown: `js(dragHotspot(${item.guid}));`
        }
        this.removeHotspot(hotspotSettings.name)
        this.addHotspot(hotspotSettings)
        this.removeLayer('hotspotTxt_' + item.guid)
        if (item.showTitle && item.title != null && item.title != '') {
          const layerSettings = {
            name: `hotspotTxt_${item.guid}`,
            type: 'text',
            html: item.title,
            vcenter: 'true',
            padding: '0',
            wordwrap: 'false',
            mergedalpha: 'true',
            distorted: item.distorted,
            css: 'font-size:12px; color:#FFF;text-shadow:0 0 2px #000, 0 0 3px #010101, 0 0 3px #020202;',
            bg: 'false',
            parent: `hotspot[hotspot_${item.guid}]`,
            zoom: item.zoom,
            rotate: -item.rotate,
            align: this.calcEdge(item.icon.edge, 0),
            onclick: `js(clickHotspot(${item.guid}))`,
            ondown: `js(dragHotspot(${item.guid}));`,
            //scale: item.scale,
          }
          let p = [item.icon.textOffsetX, item.icon.textOffsetY, (parseFloat(item.rotate)) * Math.PI / 180]
          p = this.changeAngle(p)
          if (item.distorted) {
            layerSettings.ox = p[0]
            layerSettings.oy = p[1]
          } else {
            layerSettings.ox = p[0] * item.scale
            layerSettings.oy = p[1] * item.scale
          }
          layerSettings.edge = this.calcEdge(item.icon.textEdge, parseFloat(item.rotate))
          this.addLayer(layerSettings)
        }
      },
      setHotspots() {
        const hotspots = this.form.hotspots
        for (const i in hotspots) {
          this.setHotspot(hotspots[i])
        }
      },
      addLayer(layerSetting) {
        let name = ''
        if (layerSetting.name) {
          name = layerSetting.name
          delete layerSetting.name
        } else {
          name = 'layer_' + Math.random()
        }
        this.krpano.call('addlayer(' + name + ')')
        for (const i in layerSetting) {
          this.krpano.set('layer[' + name + '].' + i, layerSetting[i])
        }
      },
      removeLayer(name) {
        this.krpano.call('removelayer(' + name + ',true)')
      },
      dragHotspot(id) {
        var name = "hotspot_" + id
        var name2 = "hotspotTxt_" + id
        var ath = this.krpano.get(`hotspot[${name}].ath`)
        var atv = this.krpano.get(`hotspot[${name}].atv`)
        var spheretoscreen = this.krpano.spheretoscreen(ath, atv)
        var hotspotcenterx = spheretoscreen.x
        var hotspotcentery = spheretoscreen.y
        var stagex = this.krpano.get(`mouse.stagex`)
        var stagey = this.krpano.get(`mouse.stagey`)
        var drag_adjustx = stagex - hotspotcenterx
        var drag_adjusty = stagey - hotspotcentery
        var t = setInterval(() => {
          if (this.krpano.get(`hotspot[${name}].pressed`) || this.krpano.get(`layer[${name2}].pressed`)) {
            var stagex = this.krpano.get(`mouse.stagex`)
            var stagey = this.krpano.get(`mouse.stagey`)
            var dx = stagex - drag_adjustx
            var dy = stagey - drag_adjusty
            var screentosphere = this.krpano.screentosphere(dx, dy);
            var ath = screentosphere.x
            var atv = screentosphere.y
            this.krpano.set(`hotspot[${name}].ath`, ath)
            this.krpano.set(`hotspot[${name}].atv`, atv)
            this.updateHotspot(id, ath, atv)
          } else {
            clearInterval(t)
          }
        }, 10)
      },
      clickHotspot(id) {
        if (this.viewType == 'hotspot') {
          for (var i in this.form.hotspots) {
            if (this.form.hotspots[i].guid == id) {
              if (this.editHotspotIndex == i) {
                this.showHotspotLinkDialog(i)
              } else {
                this.editHotspotIndex = i
              }
            }
          }
          console.log(id)
        }
      },
      AnimationCrop(framewidth, frameheight, framerate, guid) {
        const caller = this.krpano.get(`hotspot[hotspot_${guid}]`)
        //define local variables
        const xframes = (caller.imagewidth / framewidth) || 0
        const frames = xframes * ((caller.imageheight / frameheight) || 0)
        let frame = 0
        //set the first frame
        this.krpano.set(`hotspot[hotspot_${guid}].crop`, `0|0|${framewidth}|${frameheight}`)
        //do the animation
        var ai = setInterval(() => {
          if (caller.loaded) {
            frame++
            if (frame >= frames) {
              frame = 0
            }
            let xpos = frame % xframes
            let ypos = Math.floor(frame / xframes)
            xpos *= framewidth
            ypos *= frameheight
            this.krpano.set(`hotspot[hotspot_${guid}].crop`, `${xpos}|${ypos}|${framewidth}|${frameheight}`)
          } else {
            clearInterval(ai)
          }
        }, 1000 / framerate)
      },

      changeAngle(param) {
        if (param[0] != 0 || param[1] != 0) {
          var x = param[0];
          var y = param[1];
          var tha1 = param[2];

          var value = Math.sqrt(x * x + y * y);

          var cos1 = x / value;
          var sin1 = y / value;

          var cos2 = Math.cos(tha1);
          var sin2 = Math.sin(tha1);

          var cos3 = cos1 * cos2 - sin1 * sin2;
          var sin3 = sin1 * cos2 + cos1 * sin2;
          param[0] = (value * cos3).toFixed(2);
          param[1] = (value * sin3).toFixed(2);
          return param;
        }
        return param
      },
      calcEdge(edge, angle) {
        var e;
        var fx = ['top', 'righttop', 'right', 'rightbottom', 'bottom', 'leftbottom', 'left', 'lefttop']
        e = fx.findIndex((val) => val == edge)
        if (e < 0) {
          return edge;
        }
        //switch (edge) {
        //    case 'top': e = 0; break//'lefttop', 'left', 'leftbottom', , 'center', 'bottom', 'righttop', 'right', 'rightbottom'
        //    case 'righttop': e = 1; break
        //    case 'right': e = 2; break
        //    case 'rightbottom': e = 3; break
        //    case 'bottom': e = 4; break
        //    case 'leftbottom': e = 5; break
        //    case 'left': e = 6; break
        //    case 'lefttop': e = 7; break
        //    default:
        //        return edge;
        //}
        var offset = 22.5
        var c = parseFloat(angle) + offset
        var f = Math.floor(c / 45);
        f = e + f
        do {
          if (f >= 0 && f < 8) {
            break;
          }
          if (f < 0) {
            f += 8;
          }
          if (f >= 8) {
            f -= 8;
          }
        } while (true);
        return fx[f]
      },
      setEditHotspot(index) {
        this.editHotspotIndex = index;
        this.getHotspot(this.form.hotspots[index])
      },
      showDeleteHotspot() {
        this.$refs.deleteHotspot.show = true
      },
      deleteHotspot(hotspot) {
        var index = -1
        for (var i in this.form.hotspots) {
          if (this.form.hotspots[i].guid == hotspot.guid) {
            index = i;
          }
        }
        if (index < 0) {
          return;
        }
        this.krpano.call(`removeHotspot(hotspot_${hotspot.guid})`);
        this.krpano.call(`removelayer(hotspotTxt_${hotspot.guid})`);

        this.form.hotspots.splice(index, 1);

        this.$refs.deleteHotspot.show = false
        this.$refs.HotspotLink.show = false
      },
      getHotspot(hotspot) {
        this.krpano.set("view.hlookat", hotspot.ath);
        this.krpano.set("view.vLookAt", hotspot.atv);
      },
      updateHotspot(guid, ath, atv) {
        for (var i in this.form.hotspots) {
          if (this.form.hotspots[i].guid == guid) {
            this.form.editHotspotIndex = i;
            this.form.hotspots[i].ath = ath;
            this.form.hotspots[i].atv = atv;
          }
        }
      },

      addHotspot(hotspotSetting) {
        let name = ''
        if (hotspotSetting.name) {
          name = hotspotSetting.name
          delete hotspotSetting.name
        } else {
          name = 'hotspot_' + Math.random()
        }
        this.krpano.call('addhotspot(' + name + ')')
        for (const i in hotspotSetting) {
          this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i])
        }
      },
      removeHotspot(name) {
        this.krpano.call('removehotspot(' + name + ',true)')
      },
      removeAllhotspot() {
        this.krpano.call('loop(hotspot.count GT 0, removeHotspot(0) );')
      },
      onKrpanoReady(krpano) {
        this.krpano = krpano
        if (this.form) {
          this.sceneVars['view.vlookatmin'] = this.form.vLookAtMin
          this.sceneVars['view.vlookatmax'] = this.form.vLookAtMax
        }

      },
      showHotspotIconLibDialog(index) {
        this.gethotspotindex = index;
        this.$refs.HotspotIconLib.showDialog();
      },
      cMarker(p) {
        this.NewLocationP.lat = p.lat
        this.NewLocationP.lng = p.lng
      },
      changeMarker(p) {
        this.form.gpsLat = p.lat
        this.form.gpsLng = p.lng
      },
      loadWeatherTypes() {
        this.$axios.get('/Api/InnAdmin/Panos/WeatherTypes')
          .then((response) => {
            //console.log(response)
            this.weathers = response.data
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      loadData() {
        this.loading = true;
        this.loadWeatherTypes()
        this.$axios.get(`/Api/InnAdmin/Panos/${this.$route.params.panoId}`)
          .then((response) => {
            let val = response.data
            if (val.vLookAtMin == 0 && val.vLookAtMax == 0) {
              val.vLookAtMin = -90
              val.vLookAtMax = 90
            }
            this.vLookAtRange = [val.vLookAtMin, val.vLookAt, val.vLookAtMax]
            if (val.fov == 0) {
              val.fov = 120
            }
            if (val.title == '无标题') val.title = null;
            //this.form.hotspots = val.hotspots
            this.form = val;
            this.loading = false;
            this.loadTags()

          })
          .catch((error) => {
            console.log(error)
            this.loading = false;
          })
      },
      loadTags() {
        var tagtype = '全景';
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get(`/Api/InnAdmin/Tags/All/${this.$route.params.innId}`, { params: { type: tagtype } })
          .then((response) => {
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loadWeatherTypes() {
        let self = this
        this.$axios.get('/Api/InnAdmin/Panos/WeatherTypes')
          .then((response) => {
            //console.log(response)
            self.weathers = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
      cancel() {
        if (confirm("确定退出吗，未保存的内容将丢失")) {
          this.Close()
        } else {
          return
        }
      },
      Close() {
        this.cantleave = false
        this.$nextTick(() => {
          this.$router.go(-1)
          //this.$router.push({ name: 'PanoList' })
        })
      },
      verify() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          if (this.$v.form.title.$invalid) {
            this.$message({
              message: '请输入标题',
              type: 'warning',
              zIndex: 9999,
            })
            this.$refs.baseSetting.show = true
          }
          return false
        }
        return true
      },
      Update() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          for (var j in this.$v.form.hotspots.$each.$iter) {
            if (!this.$v.form.hotspots.$each.$iter[j].url.isUrl) {
              this.$message({
                message: `热点“${this.$v.form.hotspots.$each.$iter[j].$model.title || `${Number(j) + 1}`}”的 Url 不是有效的格式`,
                type: 'info',
                zIndex: 9999,
              })
            }
          }
          return
        }
        this.updateing = true;
        this.$axios.put(`/Api/InnAdmin/Panos/${this.form.guid}` , this.form)
          .then((response) => {
            this.$message({
              message: '保存成功',
              type: 'info',
              zIndex: 9999,
            })
            this.Close()
            //this.updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false;
          })
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
      FileLibSelected(select) {
        this.form.file = select;
        this.selectedIndex = null;
      },
      showAudioLibDialog() {
        this.$refs.AudioLib.showDialog();
      },
      AudioLibSelected(select) {
        this.form.audio = select;
      },
      showHotspotLinkDialog(index) {
        this.gethotspotindex = index;
        this.linkdata = this.form.hotspots[index]
        this.$refs.HotspotLink.show = true
      },
      showPanoLibDialog() {
        this.$refs.PanoLib.showDialog();
      },
      hotspotPanoLibSelected(select) {
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.linkItemGUID = select.guid;
        this.linkdata.linkItem = select;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.title
          this.setHotspot(this.linkdata)
        }
        console.log(select)
        this.$refs.PanoLib.show = false;
      },
      showFileLibDialog() {
        this.$refs.FileLib.showDialog();
      },
      FileLibSelected(select) {
        if (select.guid == this.panoId) {
          this.$message({
            message: '您不能选择自身全景',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.file = select;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.name
          this.setHotspot(this.linkdata)
        }
      },
      savePanoView(fov, hLookAt, vLookAt) {
        this.linkdata.linkFOV = fov
        this.linkdata.linkHLookAt = hLookAt
        this.linkdata.linkVLookAt = vLookAt
      },
      numberMath(val, step, max, min) {
        val = Number(val)
        step = Number(step)
        val += step
        if (max && val > max) {
          val = max;
        }
        if (min && val < min) {
          val = min;
        }
        return Number(val);
      },
      UploadPanoCover(blob) {
        this.UpdateCover(blob, '/Api/InnAdmin/Panos/Cover/' + this.panoId)
      },
      UpdateCover(blob, url) {
        if (this.updateing) {
          return
        }
        this.updateing = true;
        if (blob) {
          let data = new FormData();
          //data.append('ArticleGUID', this.form.guid);
          data.append('file', blob, "Cover.jpg");
          var config = {
            url: url,
            method: 'POST',
            data: data,
          }
          this.$axios.request(config)
            .then((response) => {
              console.log('#', response)
              this.form.cover = response.data.cover
              this.updateing = false;
              this.$message({
                message: '保存成功',
                type: 'info',
                zIndex: 9999,
              })
              this.viewType = null
              this.resetPanoView()
            })
            .catch((error) => {
              console.error(error)
              this.updateing = false;
            })
        } else {
          console.error('缺少blob')
        }
      },
      krpano_makeScreenshot(func) {
        if (this.krpano) {
          //console.log(this.krpano.call(`set(Screenshot, krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg'))`))
          //console.log(this.krpano.call(`screenshotcanvas = krpano.webGL.makeScreenshot(0, 0, true, "canvas", 0, null, sizeinfo, makeScreenshotCache);`))
          //console.log(this.krpano.get('screenshotcanvas'))
          //var img = this.krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg')
          //console.log(img)
          if (this.krpanoInterface) {
            var img = this.krpanoInterface.webGL.makeScreenshot(1000, 500, false, "canvas")
            console.log(img)
            img.toBlob((blob) => {
              console.log(blob)
              if (func) {
                func(blob)
              }
            }, "image/jpeg", 0.95);
          } else {
            this.krpano.call('addplugin(pluginInterface)')
            window.krpanopluginready = () => {
              this.krpanoInterface = this.krpano.get('plugin[pluginInterface].krpanointerface')
              console.log(this.krpanoInterface)
              //this.krpano.call('removeplugin(pluginInterface,true)')

              var img = this.krpanoInterface.webGL.makeScreenshot(1000, 500, false, "canvas")
              console.log(img)
              img.toBlob((blob) => {
                console.log(blob)
                if (func) {
                  func(blob)
                }
              }, "image/jpeg", 0.95);

            }
            this.krpano.set('plugin[pluginInterface].onloaded', `js(krpanopluginready());`)
            this.krpano.set('plugin[pluginInterface].url', '%SWFPATH%/plugins/pluginInterface.js')
          }
        }
      },
      view_look_straight() {
        var view_vlookat = this.krpano.get('view.vlookat')
        if (view_vlookat < -80 || view_vlookat > 80) {
          this.krpano.call('tween(view.vlookat, 0.0, 1.0, easeInOutSine);')
          this.krpano.call('tween(view.fov,     100, distance(150,0.8));')
        }
      },
      view_normal() {//一般视角
        if (this.krpano) {
          //this.krpano.call("skin_view_normal();");
          this.view_look_straight()
          this.krpano.call('tween(view.architectural,  0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.pannini,        0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.distortion,     0.0, distance(1.0,0.5));')
        }
      },
      view_fisheye() {//鱼眼视角
        if (this.krpano) {
          //this.krpano.call("skin_view_fisheye();");
          this.view_look_straight()
          this.krpano.call('tween(view.architectural,  0.0,  distance(1.0,0.5));')
          this.krpano.call('tween(view.pannini,        0.0,  distance(1.0,0.5));')
          this.krpano.call('tween(view.distortion,     0.35, distance(1.0,0.5));')
        }
      },
      view_stereographic() {//立体视角
        if (this.krpano) {
          //this.krpano.call("skin_view_stereographic();");
          this.view_look_straight()
          this.krpano.call('tween(view.architectural,  1.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.pannini,        0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.distortion,     0.0, distance(1.0,0.5));')

        }
      },
      view_architectural() {//构架视角
        if (this.krpano) {
          //this.krpano.call("skin_view_architectural();");
          this.view_look_straight()
          this.krpano.call('tween(view.architectural,  0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.pannini,        0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.distortion,     1.0, distance(1.0,0.8));')
        }
      },
      view_pannini() {//超广角视角
        if (this.krpano) {
          //this.krpano.call("skin_view_pannini();");
          this.view_look_straight()
          this.krpano.call('tween(view.architectural,  0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.pannini,        0.0, distance(1.0,0.8));')
          if (this.krpano.get('view.distortion') < 0.1) {
            this.krpano.call('tween(view.distortion,     0.0, distance(1.0,0.8));')
          }
        }
      },
      view_littleplanet() {//小行星视角
        if (this.krpano) {
          //this.krpano.call("skin_view_littleplanet();");
          this.krpano.call('tween(view.architectural,  0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.pannini,        0.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.distortion,     1.0, distance(1.0,0.5));')
          this.krpano.call('tween(view.fov,     150, distance(150,0.8));')
          this.krpano.call('tween(view.vlookat,  90, distance(100,0.8));')
          var new_hlookat = this.krpano.get('view.hlookat')
          this.krpano.call(`tween(view.hlookat,${new_hlookat}, distance(100,0.8));`)
        }
      },
      getPanoView() {
        if (this.krpano) {
          this.view.fov = Number(this.krpano.get("view.fov")).toFixed(1)
          this.view.hLookAt = this.math360(Number(this.krpano.get("view.hlookat"))).toFixed(1)
          this.view.vLookAt = Number(this.krpano.get("view.vlookat")).toFixed(1)
          if (this.view.vLookAt > this.vLookAtRange[2]) {
            this.view.vLookAt = this.vLookAtRange[2]
          }
          if (this.view.vLookAt < this.vLookAtRange[0]) {
            this.view.vLookAt = this.vLookAtRange[0]
          }
          this.vLookAtRange = [this.vLookAtRange[0], this.view.vLookAt, this.vLookAtRange[2]]
        }
      },
      recoverView() {
        if (this.krpano) {
          this.vLookAtRange = [this.form.vLookAtMin, this.form.vLookAt, this.form.vLookAtMax]
          this.krpano.set('view.limitview', 'range')
          this.krpano.set('view.vlookatmin', this.vLookAtRange[0])
          this.krpano.set('view.vlookatmax', this.vLookAtRange[2])
          this.view.fov = this.form.fov
          this.krpano.set('view.fov', this.view.fov)
          this.view.hLookAt = this.form.hLookAt
          this.krpano.set('view.hLookAt', this.view.hLookAt)
          this.view.vLookAt = this.form.vLookAt
          this.krpano.set('view.vLookAt', this.view.vLookAt)
        }
      },
      recoverLinkView() {
        if (this.krpano) {
          this.view.fov = this.linkdata.linkFOV
          this.krpano.set('view.fov', this.view.fov)
          this.view.hLookAt = this.linkdata.linkHLookAt
          this.krpano.set('view.hLookAt', this.view.hLookAt)
          this.view.vLookAt = this.linkdata.linkVLookAt
          this.krpano.set('view.vLookAt', this.view.vLookAt)
        }
      },
      resetView() {
        if (this.krpano) {
          this.vLookAtRange = [-90, 0, 90]
          this.krpano.set('view.limitview', 'range')
          this.krpano.set('view.vlookatmin', this.vLookAtRange[0])
          this.krpano.set('view.vlookatmax', this.vLookAtRange[2])
          this.view.fov = 120
          this.krpano.set('view.fov', this.view.fov)
          this.view.hLookAt = 0
          this.krpano.set('view.hLookAt', this.view.hLookAt)
          this.view.vLookAt = 0
          this.krpano.set('view.vLookAt', this.view.vLookAt)
        }
      },
      setPanoView() {
        this.form.vLookAtMin = this.vLookAtRange[0]
        this.form.vLookAtMax = this.vLookAtRange[2]
        if (this.krpano) {
          this.form.fov = Number(this.krpano.get("view.fov")).toFixed(2)
          this.form.hLookAt = this.math360(Number(this.krpano.get("view.hlookat"))).toFixed(2)
          this.form.vLookAt = Number(this.krpano.get("view.vlookat")).toFixed(2)
        }
        this.viewType = null
      },
      resetPanoView() {
        console.log(123)
        if (this.krpano) {
          //console.info('resetPanoView#')
          //var krpano = document.getElementById("pano")
          //this.view.fov = 120
          //this.view.hLookAt = 0
          //this.view.vLookAt = 0
          //this.krpano.set("view.fov", this.view.fov)
          //this.krpano.set("view.hlookat", this.view.hLookAt)
          //this.krpano.set("view.vlookat", this.view.vLookAt)
          this.krpano.call(`tween(view.fov,       120, distance(150,0.8));`)
          this.krpano.call(`tween(view.vlookat,   0, distance(100,0.8));`)
          this.krpano.set("view.hlookat", this.pano.hLookAt)
          this.krpano.call(`tween(view.hlookat,   0, distance(100,0.8));`)
        }
      },
      fileOrBlobToDataURL(obj, cb) {
        var a = new FileReader();
        a.readAsDataURL(obj);
        a.onload = function (e) {
          cb(e.target.result);
        };
      },
      makeSizeClass(viewType) {
        if (viewType == 'saveView') {
          if (window.screen.height * 2 > window.screen.width) {
            return "portrait"
          } else {
            return "landscape"
          }
        }
        return "nothing"
      },
    },
    computed: {
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      }
    },

    watch: {
      vLookAtRange: function (newval, oldval) {
        if (this.krpano) {
          var min = Math.min(newval[0], newval[2])
          var max = Math.max(newval[0], newval[2])
          this.krpano.set('view.limitview', 'range')
          this.krpano.set('view.vlookatmin', min)
          this.krpano.set('view.vlookatmax', max)
          //this.form.vLookAtMin = min
          //this.form.vLookAtMax = max
          this.krpano.set('view.vlookat', newval[1])
        }
      }
    },
  }
</script>
<style scoped>
  .panosize {
    width: 100%;
    height: 100%;
  }

    .panosize.portrait {
      width: 100%;
      height: 50vw;
    }

    .panosize.landscape {
      width: 200vh;
      height: 100%;
    }

  .custom-dot {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: pink;
    transition: all .3s;
  }

  .toolbar {
    opacity: 0.85;
  }

  #boxPanoHotspot .active {
    border: 1px solid #000;
  }

  #boxPanoHotspot .panobox {
    width: 100%;
  }

  .frame {
    pointer-events: none !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

    .frame > * {
      pointer-events: auto;
    }

  .panoedit {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .panoedit .arrowbtn {
      background-color: transparent;
      border: 0;
      border-radius: 4px;
      color: #fff;
      outline: 0;
    }

    .panoedit .PanoHotspotEdit {
      position: absolute;
      right: 0;
      bottom: 0;
      pointer-events: none !important;
      margin: 5px;
    }

      .panoedit .PanoHotspotEdit > * {
        pointer-events: auto;
        margin: 5px 0;
      }

    .panoedit .links {
      list-style: none;
      font-size: 12px;
      margin: 0;
    }

      .panoedit .links li {
        display: inline-block;
        margin: 0 5px;
      }

        .panoedit .links li i {
          display: block;
        }

        .panoedit .links li a {
          text-align: center;
          color: white;
        }

    .panoedit .hotsoptTool {
      position: absolute;
      bottom: 0;
      padding: 1rem;
      width: 100%;
      background-color: #fff7;
    }

    .panoedit .rangeBar {
      background-color: #fff5;
      padding: 5px;
      margin: 0px 0 -5px;
      text-align: center;
      border-radius: 0.2rem;
    }

    .panoedit .hotspotItem {
      display: inline-block;
      margin: 5px;
      /*width: 4.5em;*/
      vertical-align: top;
    }

      .panoedit .hotspotItem.active {
        margin: 4px;
        border: 1px solid #fff;
        box-shadow: #000 0 0 2px 0;
      }

      .panoedit .hotspotItem .badge {
        position: absolute;
        right: 2px;
      }

    .panoedit .inner h4 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: 1.2rem;
      text-align: left;
    }

    .panoedit .picback {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #fffa;
      box-shadow: #0005 2px;
      box-shadow: #000 0 0 2px;
    }

      .panoedit .picback.active {
        background-color: #ffbb00aa;
      }
</style>
