<template lang="html">
  <div class="card card-primary card-outline LibList">
    <div class="overlay" v-if="$store.state.loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div class="card-header p-1">
      <div class="card-title">
        <button class="btn btn-primary btn-sm mx-1" @click="showUploadDialog()" title="添加"><i class="fa fa-plus"></i></button>
        <button class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        <router-link v-if="query.type=='audio'" class="btn btn-primary btn-sm mx-1" :to="{name:'Recorder'}" title="录音">录音上传</router-link>
      </div>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- /.box-tools -->
    </div>
    <div class="mailbox-controls">
      <!--<div class="form-inline d-inline-block">
          <div class="form-group form-group-sm">
              <label>类型</label>
              <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                  <option value="">所有</option>
                  <option v-for="i in types" v-bind:value="i.value">{{i.name}}</option>
              </select>
          </div>
      </div>-->
      <div class="row justify-content-end">
        <div class="col-auto col-xl-2 col-sm-5 order-1 order-sm-12 ">
          <div class="input-group input-group-sm ">
            <div class="input-group-prepend">
              <span class="input-group-text" style="font-weight:700">标签</span>
            </div>
            <select class="form-control" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
              <option :value="null">所有</option>
              <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-xl col-sm-7 order-12 order-sm-1">
          <ul class="nav nav-pills">
            <li class="nav-item" v-for="i in types">
              <a class="nav-link px-2 py-1 mt-1" :class="{'active':query.type==i.value}" href="#" @click="changeType(i.value)">{{i.name}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="query.type=='audio'" style="padding: 0 10px;">
      <audio :src="nowAudio?nowAudio.url:null" controls="controls" autoplay="autoplay" class="audioPlay" controlslist="nodownload" ref="audioPlayer" style="height:30px"></audio>
    </div>
    <div class="row card-body p-1">
      <div v-if="loading" class="col-12"><i class="fas fa-spinner fa-pulse"></i> 加载中…</div>
      <div v-else class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="(i,index) in list">
        <template v-if="i.isImage">
          <LibImageCard :item="i">
            <div class="text-right">
              <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                修改
              </button>
              <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                删除
              </button>
            </div>
          </LibImageCard>
        </template>
        <template v-else-if="i.isVideo">
          <LibVideoCard ref="videoCard" :item="i">
            <div class="text-right">
              <button class="btn btn-sm btn-default m-1" @click="$refs.videoCard[index].preView(i)">
                <i class="fas fa-play"></i>
              </button>
              <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                修改
              </button>
              <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                删除
              </button>
            </div>
          </LibVideoCard>
        </template>
        <template v-else-if="i.isAudio">
          <LibAudioCard :item="i">
            <div class="text-right">
              <button v-if="nowAudio!=i" class="btn btn-sm btn-default m-1" @click="nowAudio=i">
                <i class="fas fa-play"></i>
              </button>
              <button v-else class="btn btn-sm btn-default m-1" @click="nowAudio=null;$refs.audioPlayer.load()">
                <i class="fas fa-stop"></i>
              </button>
              <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                修改
              </button>
              <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                删除
              </button>
            </div>
          </LibAudioCard>
        </template>
        <template v-else>
          <LibFileCard :item="i">
            <div class="text-right">
              <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                修改
              </button>
              <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                删除
              </button>
            </div>
          </LibFileCard>
        </template>
      </div>
    </div>
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
      <div v-if="libSizeInfo" class="progress">
        <div class="progress-bar" role="progressbar" :style="{'width': libSizeInfo.percent +'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{libSizeInfo.percent}}%</div>
      </div>
    </div>
    <!--<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="uploadModal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="uploadModalLabel">上传</h4>
          </div>
          <div class="modal-body">
            <uploadBox :file-list="fileList"
                       action="/Api/InnAdmin/Files/Upload"
                       :param="param"
                       :multiple="true"
                       :maxSize="50"
                       :maxWidth="22000"
                       :maxHeight="11000"
                       :isPano="true"
                       :allowExtensions="/(.jpg|.jpeg)$/i"
                       :partSizeMB="10"
                       :surlheight="100"
                       :surlwidth="200"
                       :maxUpper="3"
                       :uploadFinished="search"
                       :changeName="changePanoName">
            </uploadBox>
          </div>
        </div>
      </div>
    </div>-->
    <Modal ref="uploadModal">
      <template slot="header">
        <ul class="nav nav-pills">
          <li class="nav-item"><a class="nav-link active" href="#image" id="btn_image" data-toggle="tab">图片</a></li>
          <!--<li class="nav-item"><a class="nav-link" href="#pano" id="btn_pano" data-toggle="tab">全景</a></li>-->
          <li class="nav-item"><a class="nav-link" href="#video" id="btn_video" data-toggle="tab">视频</a></li>
          <li class="nav-item"><a class="nav-link" href="#audio" id="btn_audio" data-toggle="tab">音频</a></li>
          <li class="nav-item"><a class="nav-link" href="#coordinate" id="btn_coordinate" data-toggle="tab">航迹</a></li>
        </ul>
      </template>

      <div class="tab-content">
        <!-- Font Awesome Icons -->
        <!--<div class="container tab-pane active" id="pano">
            <UploadBox action="/Api/InnAdmin/Panos/Upload"
                         :param="param"
                         :multiple="true"
                         :maxSize="50"
                         :maxWidth="22000"
                         :maxHeight="11000"
                         :isPano="true"
                         :allowExtensions="/(.jpg|.jpeg)$/i"
                         :partSizeMB="10"
                         :surlheight="100"
                         :surlwidth="200"
                         :maxUpper="3"
                         :uploadFinished="search"
                         :changeName="changePanoName">
            </UploadBox>
        </div>-->
        <div class="container tab-pane active" id="image">
          <UploadBox :action="`/Api/InnAdmin/Libs/${$route.params.innId}/Upload`"
                     :param="param"
                     :multiple="true"
                     :maxSize="2"
                     :maxWidth="2500"
                     :maxHeight="2500"
                     :compress="true"
                     :isPano="false"
                     :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                     :partSizeMB="10"
                     :surlheight="100"
                     :surlwidth="200"
                     :maxUpper="3"
                     :uploadFinished="search"
                     :changeName="changeName">

          </UploadBox>
        </div>
        <div class="container tab-pane" id="audio">
          <UploadBox :action="`/Api/InnAdmin/Libs/${$route.params.innId}/Upload`"
                     :param="param"
                     :multiple="true"
                     :maxSize="2"
                     :allowExtensions="/(.aac|.mp3)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     previewIcon="fas fa-file-audio"
                     :changeName="changeName">
          </UploadBox>
        </div>
        <div class="container tab-pane" id="video">
          <UploadBox :action="`/Api/InnAdmin/Libs/${$route.params.innId}/Upload`"
                     :param="param"
                     :multiple="true"
                     :maxSize="50"
                     :allowExtensions="/(.mp4)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     previewIcon="fas fa-file-video"
                     :changeName="changeName">
          </UploadBox>
        </div>
        <div class="container tab-pane" id="coordinate">
          <UploadBox :action="`/Api/InnAdmin/Libs/${$route.params.innId}/Upload`"
                     :param="param"
                     :multiple="true"
                     :maxSize="1"
                     :geoJson="true"
                     :allowExtensions="/(.kml|.gpx|.geojson)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     previewIcon="fas fa-route"
                     :changeName="changeName">
          </UploadBox>
        </div>
      </div>

      <template slot="footer">
        <span hidden>hidden</span>
      </template>
    </Modal>
    <Modal ref="editModal">
      <template slot="header">
        <h4>素材编辑</h4>
      </template>

      <div v-if="item">
        <div class="form-group">
          <label for="panoTitle">标题</label>
          <input type="text" class="form-control" id="panoTitle" placeholder="输入标题" v-model.trim:value="item.name">
        </div>
        <div class="form-group">
          <label for="panoTitle">公共标签</label>
          <CheckBox checkprop="name"
                    :checkList="publicTags"
                    :selected="item.tags"
                    :onChange="tagsCheckOnChange">
          </CheckBox>
        </div>
        <div class="form-group">
          <label for="panoTitle">私有标签</label>
          <CheckBox checkprop="name"
                    :checkList="privateTags"
                    :selected="item.tags"
                    :onChange="tagsCheckOnChange">
          </CheckBox>
        </div>
      </div>
      <template slot="footer">
        <button type="button" class="btn btn-default" @click.prevent="hideEdit">取消</button>
        <button type="button" @click.prevent="saveEdit()" class="btn btn-primary">更新</button>
      </template>

    </Modal>
    <Modal ref="delModal">
      <template slot="header">
        <h4 class="modal-title">删除素材</h4>
      </template>
      确定删除 {{(item||{}).name}} ?
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="del(item.guid)" :disabled="updateing">确定</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import {
    UploadBox,
    Modal,
    SimpleModal,
    CheckBox,
    LibImageCard,
    LibVideoCard,
    LibAudioCard,
    LibFileCard,
  } from 'common'
  //import UploadBox from '@/components/UploadBox'
  //import CheckBox from '@/components/CheckBox'
  //import Modal from '@/components/Modal'
  //import SimpleModal from '@/components/SimpleModal'
  //import LibImageCard from '@/components/LibImageCard'
  //import LibVideoCard from '@/components/LibVideoCard'
  //import LibAudioCard from '@/components/LibAudioCard'
  //import LibFileCard from '@/components/LibFileCard'

  export default {
    components: {
      UploadBox,
      Modal,
      SimpleModal,
      CheckBox,
      LibImageCard,
      LibVideoCard,
      LibAudioCard,
      LibFileCard,
    },
    data() {
      return {
        list: [],
        libSizeInfo: null,
        publicTags: [],
        privateTags: [],
        types: [{ name: '图片', value: 'image', tagtype: 1 }, { name: '视频', value: 'video', tagtype: 3 }, { name: '音频', value: 'audio', tagtype: 2 }, { name: '航迹', value: 'coordinate', tagtype: 41 }],//{ name: '全景', value: 'pano', tagtype: 11 },
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 12,
          sort: "AddTime",
          order: "desc",
          fields: 'Name,FileName',
          key: null,
          type: "image",
          tags: null
        },
        fileList: [],
        param: {},
        nowAudio: null,
        item: null,
        updateing: false,
        loading: false,
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        this.loading = true
        this.loadTags()
        var url = `/Api/InnAdmin/Libs/${this.$route.params.innId}`
        var params = {
          page: this.query.pageIndex,
          pageSize: this.query.pageSize,
          sort: this.query.sort,
          order: this.query.order,
          fields: this.query.fields,
          key: this.query.key,
          type: this.query.type,
          tags: this.query.tags
        }

        this.$axios.get(url, { params: params })
          .then((response) => {
            console.log(response)
            this.list = response.data.data
            this.pager.pageIndex = response.data.pageIndex
            this.pager.pageSize = response.data.pageSize
            this.pager.totalPages = response.data.totalPages
            this.pager.totalRecords = response.data.totalRecords
            this.loading = false
          }).catch((error) => {
            console.log(error)
            this.loading = false
          })

      },
      loadTags() {
        var tagtype = 0;
        for (var i in this.types) {
          if (this.types[i].value == this.query.type) {
            tagtype = this.types[i].tagtype;
            break;
          }
        }
        this.$axios.get(`/Api/InnAdmin/Tags/All/${this.$route.params.innId}`, { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      changeType(type) {
        //$('#btn_' + type).tab('show')
        this.query.type = type;
        this.loadData();
      },
      search() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      sortingChanged(ctx) {
        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.query.sort = ctx.sortBy;
        this.query.order = ctx.sortDesc ? "desc" : "asc";
        this.loadData();
      },
      delDialog(item) {
        this.$refs.delModal.show()
        this.item = item
      },
      del(id) {
        let _this = this
        _this.updateing = true;
        this.$axios.delete(`/Api/InnAdmin/Files/${id}`)
          .then(function (response) {
            //console.log(response)
            let d = response.data
            _this.loadData()
            _this.$message({
              type: 'success',
              message: '删除成功',
              zIndex: 9999,
              showClose: false
            })
            _this.updateing = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.$message({
              type: 'error',
              message: error.message,
              zIndex: 9999,
              showClose: false
            })
            _this.updateing = false;
          })
      },
      showUploadDialog() {
        this.$refs.uploadModal.show()

      },
      hideUploadDialog() {
        this.$refs.uploadModal.hide()
        this.fileList = []

      },
      changeName(item, res) {
        var _this = this;
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.name = item.title;
          console.log(data)
          this.$axios.put('/Api/InnAdmin/Files/' + data.guid, data).then(function (res) {
            if (res.status == 200) {
              _this.search()
            }
          })
        }
      },
      changePanoName(item, res) {
        var _this = this;
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.title = item.title;
          console.log(data)
          this.$axios.put(`/Api/InnAdmin/Panos/${data.guid}`, data).then(function (res) {
            if (res.status == 200) {
              _this.search()
            }
          })
        }
      },
      showEdit(item) {
        var _this = this
        this.$refs.editModal.show()
        this.$axios.get(`/Api/InnAdmin/Libs/${this.$route.params.innId}/${item.guid}`).then(res => {
          _this.item = res.data
        })
      },
      hideEdit() {
        this.$refs.editModal.hide()
        this.item = null
      },
      saveEdit() {
        var _this = this
        this.$refs.editModal.show()
        this.$axios.put('/Api/InnAdmin/Files/' + this.item.guid, this.item).then(res => {
          this.$refs.editModal.hide()
          this.loadData()
        })
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.item.tags = [...list]
      },
    },
    computed: {
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      },
      progress: function () {
        if (this.libSizeInfo) {
          return ((this.libSizeInfo.fileSize + this.libSizeInfo.privateSize) / (this.libSizeInfo.libBaseSize + this.libSizeInfo.awardSize) * 100).toFixed(2)
        }
        return 0;
      }
    },

  }
</script>

<style lang="css">
</style>
