<template lang="html">
  <div>
    <div class="card card-primary card-outline">
      <div class="overlay" v-if="$store.state.loading">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <div class="card-header p-1">
        <div class="card-title">
          <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="Create()" title="添加"><i class="fa fa-plus"></i></button>
          <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" :disabled="loadDataing" title="刷新"><i class="fas fa-sync"></i></button>
        </div>
        <div class="card-tools mr-0">
          <div class="input-group input-group-sm m-0">
            <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
            <div class="input-group-append">
              <button type="button" class="btn btn-primary" v-on:click="search()">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.card-tools -->
      </div>
      <!-- /.box-header -->
      <div class="card-body p-1">
        <div class="mailbox-controls">



          <!-- Check all button -->
          <!--<div class="pull-right">
            <div class="form-inline">
              <div class="form-group form-group-sm">
                <label>角色</label>
                <select class="form-control" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                  <option value="">所有</option>
                  <option v-for="i in roles" v-bind:value="i.name">{{i.name}}</option>
                </select>
              </div>
            </div>
          </div>-->
          <!-- /.pull-right -->
        </div>
        <vue-bootstrap4-table :rows="list"
                              :columns="columns"
                              :config="config"
                              :classes="classes"
                              @on-change-query="onChangeQuery">
          <template slot="sort-asc-icon">
            <i class="fas fa-sort-up"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="fas fa-sort-down"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="fas fa-sort"></i>
          </template>
          <template slot="empty-results">
            没有数据
          </template>
          <template slot="active" slot-scope="props">
            <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row.id)">
              <i class="fas fa-pen-square"></i>
            </button>
            <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
              <i class="fas fa-trash"></i>
            </button>
          </template>
          <template slot="title" slot-scope="props">
            <div :title="props.row.title" @click="view(props.row.id)">{{props.row.title}}</div>
          </template>
          <template slot="user" slot-scope="props">
            <div :title="props.row.address.fullName">{{props.row.address.fullName}}</div>
          </template>
          <template slot="closed" slot-scope="props">
            {{props.row.closed ? "是" : "否"}}
          </template>
        </vue-bootstrap4-table>
      </div>
      <!-- Loading (remove the following to stop the loading)-->
      <!--<div class="overlay" v-if="$store.state.loading">
          <i class="fa fa-refresh fa-spin"></i>
      </div>-->
      <!-- end loading -->
      <div class="card-footer p-2">
        <paging :page-index="pager.pageIndex"
                :total="pager.totalRecords"
                :page-size="pager.pageSize"
                :per-pages="3"
                :show-go="true"
                :prePageSize="pager.prePageSize"
                :onPagerChange="pageChange">
        </paging>
      </div>
    </div>
    <Modal ref="delModal">
      <template slot="header">
        <h4 class="modal-title">删除客栈</h4>
      </template>
      确定删除客栈 {{(item||{}).title}} ?
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="Delete(item.id)" :disabled="deleteing">确定</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal } from 'common'
  //import Modal from '@/components/Modal.vue'

  export default {
    components: {
      Modal,
    },
    data() {
      return {
        list: [],
        columns: [
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
          { name: 'code', label: '编码', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap" },
          { name: 'title', label: '名称', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'address.mobile', label: '手机', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'address.tel', label: '电话', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'address.areaName', label: '地区', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'user', label: '店主', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'addTime', label: '添加时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'expiryTime', label: '到期时间', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 20,
          sort: 'AddTime',
          order: 'desc',
          fields: 'Title',
          key: null,
        },
        item: null,
        loadDataing: false,
        deleteing: false,
      }

    },
    created() {
      this.loadData()
    },
    computed: {
    },
    methods: {
      loadData() {
        this.loading = true
        let _this = this
        var config = {
          params: {
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
            agentId: this.$route.params.agentId
          }
        };
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Inns` : `/Api/InnAdmin/Inns`;
        this.$axios.get(url, config).then(function (res) {
          _this.list = res.data.data
          _this.pager.pageIndex = res.data.pageIndex
          _this.pager.pageSize = res.data.pageSize
          _this.pager.totalPages = res.data.totalPages
          _this.pager.totalRecords = res.data.totalRecords
          _this.loading = false
        }).catch(function (error) {
          console.log(error)
          _this.loading = false
        })
      },
      search() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      onChangeQuery(queryParams) {
        console.log(queryParams.sort[0])
        var sort = queryParams.sort[0]
        this.query.sort = sort.name
        this.query.order = sort.order
        this.loadData()
      },
      delDialog(item) {
        this.$refs.delModal.show()
        this.item = item
      },
      Delete(id) {
        let _this = this
        _this.deleteing = true;
        this.$axios.delete('/Api/InnAdmin/Inns/' + id)
          .then(function (response) {
            console.log(response)
            _this.loadData()
            _this.deleteing = true;
            _this.$refs.delModal.hide()
          })
          .catch(function (error) {
            _this.deleteing = true;
            console.log(error)
          })
      },
      Create() {
        this.$router.push({ name: 'InnCreate' })
      },
      edit(id) {
        this.$router.push({ name: 'AgentInnEdit', params: { innId: id } })
      },
      view(id) {
        this.$router.push({ name: 'AgentInnView', params: { innId: id } })
      },
      Room(id) {
        this.$router.push({ name: 'InnRoomList', params: { innId: id } })
      },
      rowClass(item, type) {
        if (!item) return
        console.log(item.expiryTime)
        if (item.expiryTime) {
          var idate = new Date(item.expiryTime)
          console.log(idate)
          if (idate < new Date()) {
            console.log('guoqi')
            return 'danger'
          }
        }
      }
    },
  }
</script>
