<template lang="html">
    <div>
        <section class="content-header">
            <div class="container-fluid">
                <div class="mb-2">
                    <h1 v-if="$parent.$parent.agent">{{$parent.$parent.agent.title}}</h1>
                </div>
            </div>
        </section>
        <div class="row">
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>150</h3>

                        <p>房间</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-user-plus"></i>
                    </div>
                    <router-link :to="{ name: 'InnRoomList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>53</h3>

                        <p>全景</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-image"></i>
                    </div>
                    <router-link :to="{ name: 'InnPanoList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-secondary">
                    <div class="inner">
                        <h3>44</h3>

                        <p>博文</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-file-image"></i>
                    </div>
                    <router-link :to="{ name: 'InnBlogList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>65</h3>

                        <p>漫游</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-globe"></i>
                    </div>
                    <router-link :to="{ name: 'InnTourList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
        </div>
        <!-- /.row -->

        <div class="card">
            <div class="card-header border-0">
                <h3 class="card-title">信息列表</h3>
                <div class="card-tools">
                    <a href="#" class="btn btn-tool btn-sm">
                        <i class="fas fa-bars"></i>
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>分类</th>
                            <th>标题</th>
                            <th>时间</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i in list">
                            <td>
                                <router-link :to="{ name: 'InnInfoList', params: { id: i.categoryGUID } }">
                                    {{i.category.name}}
                                </router-link>
                            </td>
                            <td>
                                <router-link :to="{ name: 'InnInfoView', params: { infoId: i.guid } }">
                                    {{i.title}}
                                </router-link>
                            </td>
                            <td>
                                <time :datetime="i.addTime" :title="i.addTime">{{$formatTime(i.addTime)}}</time>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'

    export default {
        data() {
            return {
                list: null,
                loading: false
            }
        },
        created() {
            //this.getUser()
            //this.setPageInfo("主页")
            this.loadData()
        },
        computed: {
            ...mapGetters([
            ]),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions([
                //'getUser',
                // 'setPageInfo'
            ]),
            loadData() {
                var _this = this;
                this.loading = true;
                var config = {
                    params: {
                        category: '',
                        page: 1,
                        pageSize: 10,
                    }
                };
                this.$axios.get('/Api/InnAdmin/InnInfos', config).then(function (res) {
                    //console.log(res.data)
                    _this.list = res.data.data
                    _this.loading = false;
                }).catch(e => {
                    _this.loading = false;
                })

            },
        },
        components: {
        }
    }
</script>

<style lang="css">
</style>
