<template lang="html">
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="mb-2">
          <template v-if="setting&&$parent.$parent.inn">
            <a :href="setting.siteUrl+'/Inn/'+$route.params.innId" target="_blank" title="打开客栈首页">
              <h1 v-if="$parent.$parent.inn">{{$parent.$parent.inn.title}} <i class="fas fa-share-square"></i></h1>
            </a>
          </template>
        </div>
      </div>
    </section>
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">工作状态</h3>

        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
        </div>
        <!-- /.card-tools -->
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="stepwizard">
          <div class="stepwizard-row setup-panel">
            <div v-for="i in workStates" class="stepwizard-step col-xs-3">
              <button href="#step-1" type="button" class="btn btn-circle" :class="{'btn-success':workState>=i.value,'btn-default':i.value>workState}">{{i.value+1}}</button>
              <p><small>{{i.key}}</small></p>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">项目状态</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab">相关证件</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab">联系信息</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-nowrap">
                  <tr>
                    <th class="text-center">项目</th>
                    <th class="text-center">数量</th>
                  </tr>
                </thead>
                <tbody class="text-nowrap">
                  <tr>
                    <td class="text-center">房间数量</td>
                    <td class="text-center">
                      <span v-if="roomCountLoading"><i class="fas fa-spinner fa-pulse"></i></span>
                      <span v-else>{{roomCount}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">全景数量</td>
                    <td class="text-center">
                      <span v-if="panoCountLoading"><i class="fas fa-spinner fa-pulse"></i></span>
                      <span v-else>{{panoCount}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">漫游场景数量</td>
                    <td class="text-center">
                      <span v-if="tourCountLoading"><i class="fas fa-spinner fa-pulse"></i></span>
                      <span v-else>{{tourItemCount}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">漫游分组数量</td>
                    <td class="text-center">
                      <span v-if="tourCountLoading"><i class="fas fa-spinner fa-pulse"></i></span>
                      <span v-else>{{tourGroupCount}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-nowrap">
                  <tr>
                    <th class="text-center">证件</th>
                    <th class="text-center">状态</th>
                  </tr>
                </thead>
                <tbody v-if="inn" class="text-nowrap">
                  <tr>
                    <td class="text-center">店主身份证</td>
                    <td class="text-center">{{inn.idCard?'Ok':'未上传'}}</td>
                  </tr>
                  <tr>
                    <td class="text-center">营业执照</td>
                    <td class="text-center">{{inn.businessLicense?'Ok':'未上传'}}</td>
                  </tr>
                  <tr>
                    <td class="text-center">特殊行业许可证</td>
                    <td class="text-center">{{inn.specialLicense?'Ok':'未上传'}}</td>
                  </tr>
                  <tr>
                    <td class="text-center">消防许可证</td>
                    <td class="text-center">{{inn.fireLicense?'Ok':'未上传'}}</td>
                  </tr>
                  <tr>
                    <td class="text-center">卫生许可证</td>
                    <td class="text-center">{{inn.healthLicense?'Ok':'未上传'}}</td>
                  </tr>
                </tbody>
                <tbody v-else class="text-nowrap">
                  <tr>
                    <td colspan="2" class="text-center"><i class="fas fa-spinner fa-pulse"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-nowrap">
                  <tr>
                    <th class="text-center">角色</th>
                    <th class="text-center">姓名</th>
                    <th>昵称</th>
                    <th>手机</th>
                  </tr>
                </thead>
                <tbody v-if="inn" class="text-nowrap">
                  <tr v-if="inn.user">
                    <td class="text-center">店主</td>
                    <td class="text-center">{{inn.user.name}}</td>
                    <td>{{inn.user.nickName}}</td>
                    <td>{{inn.user.phoneNumber}}</td>
                  </tr>
                  <tr v-else>
                    <td class="text-center">店主</td>
                    <td class="text-center">无</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-if="inn.agentUser">
                    <td class="text-center">代理</td>
                    <td class="text-center">{{inn.agentUser.name}}</td>
                    <td>{{inn.agentUser.nickName}}</td>
                    <td>{{inn.agentUser.phoneNumber}}</td>
                  </tr>
                  <tr v-else>
                    <td class="text-center">代理</td>
                    <td class="text-center">无</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-if="inn.editorUser">
                    <td class="text-center">摄影师</td>
                    <td class="text-center">{{inn.editorUser.name}}</td>
                    <td>{{inn.editorUser.nickName}}</td>
                    <td>{{inn.editorUser.phoneNumber}}</td>
                  </tr>
                  <tr v-else>
                    <td class="text-center">摄影师</td>
                    <td class="text-center">无</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for="i in inn.employees">
                    <td class="text-center">店员</td>
                    <td class="text-center">{{i.name}}</td>
                    <td>{{i.nickName}}</td>
                    <td>{{i.phoneNumber}}</td>
                  </tr>
                </tbody>
                <tbody v-else class="text-nowrap">
                  <tr>
                    <td colspan="4" class="text-center"><i class="fas fa-spinner fa-pulse"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="text-center mt-3">
          <template v-if="workState>0&&workState<4&&($inRole('管理员')||$inRole('客栈编辑'))">
            <button type="button" class="btn btn-primary mr-2" @click="exportRooms" :disabled="downloading">导出房间</button>
          </template>

          <button v-if="workState==0&&($inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员'))" type="button" class="btn btn-primary" @click="showSetWorkState(1)" :disabled="settingWorkState">创建完成</button>
          <button v-if="workState==1&&($inRole('管理员')||$inRole('客栈编辑'))" type="button" class="btn btn-primary" @click="showSetWorkState(2)" :disabled="settingWorkState">开始拍摄</button>
          <button v-if="workState==2&&($inRole('管理员')||$inRole('客栈编辑'))" type="button" class="btn btn-primary" @click="showSetWorkState(3)" :disabled="settingWorkState">拍摄完成</button>
          <button v-if="workState==3&&($inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员'))" type="button" class="btn btn-primary" @click="showSetWorkState(4)" :disabled="settingWorkState">确认验收</button>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <div v-if="false" class="row">
      <div class="col-lg-3 col-6">
        <!-- small card -->
        <div class="small-box bg-info">
          <div class="inner">
            <h3>150</h3>

            <p>房间</p>
          </div>
          <div class="icon">
            <i class="fa fa-user-plus"></i>
          </div>
          <router-link :to="{ name: 'InnRoomList'}" class="small-box-footer">
            管理 <i class="fas fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small card -->
        <div class="small-box bg-success">
          <div class="inner">
            <h3>53</h3>

            <p>全景</p>
          </div>
          <div class="icon">
            <i class="fa fa-image"></i>
          </div>
          <router-link :to="{ name: 'InnPanoList'}" class="small-box-footer">
            管理 <i class="fas fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small card -->
        <div class="small-box bg-secondary">
          <div class="inner">
            <h3>44</h3>

            <p>博文</p>
          </div>
          <div class="icon">
            <i class="fa fa-file-image"></i>
          </div>
          <router-link :to="{ name: 'InnBlogList'}" class="small-box-footer">
            管理 <i class="fas fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small card -->
        <div class="small-box bg-danger">
          <div class="inner">
            <h3>65</h3>

            <p>漫游</p>
          </div>
          <div class="icon">
            <i class="fa fa-globe"></i>
          </div>
          <router-link :to="{ name: 'InnTourList'}" class="small-box-footer">
            管理 <i class="fas fa-arrow-circle-right"></i>
          </router-link>
        </div>
      </div>
      <!-- ./col -->
    </div>
    <!-- /.row -->

    <div class="card">
      <div class="card-header border-0">
        <h3 class="card-title">信息列表</h3>
        <div class="card-tools">
          <a href="#" class="btn btn-tool btn-sm">
            <i class="fas fa-bars"></i>
          </a>
        </div>
      </div>
      <div class="card-body p-0">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>分类</th>
              <th>标题</th>
              <th>时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in list">
              <td>
                <router-link :to="{ name: 'InnInfoList', params: { id: i.categoryGUID } }">
                  {{i.category.name}}
                </router-link>
              </td>
              <td>
                <router-link :to="{ name: 'InnInfoView', params: { infoId: i.guid } }">
                  {{i.title}}
                </router-link>
              </td>
              <td>
                <time :datetime="i.addTime" :title="i.addTime">{{$formatTime(i.addTime)}}</time>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <SimpleModal ref="setWorkStateModal" :zIndex="1040">
      <div>
        <h5>确认提交</h5>
      </div>
      <p v-if="workState==0">请确认客栈基础资料及房间信息都设置完成（确保房间数量与实际房间数量相吻合），提交摄影师开始拍摄任务。</p>
      <p v-if="workState==1">请确认开始拍摄任务。</p>
      <p v-if="workState==2">请确认所有房间及公共区域都拍摄完成并设置完成客栈漫游（确保漫游分组数量与房间数量相吻合），提交店主验收审核。</p>
      <p v-if="workState==3">请确认摄影师已经拍摄完成所有区域并设置好客栈漫游（确保漫游分组数量与房间数量相吻合），验收完成。</p>
      <div style="display:flex;justify-content:flex-end;">
        <button type="button" class="btn btn-default mr-2" @click="$refs.setWorkStateModal.show=false">取消</button>
        <!--<button class="btn btn-primary btn-sm" @click="setWorkState(workState+1)">确定</button>-->
        <button v-if="workState==0&&($inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员'))" type="button" class="btn btn-primary" @click="setWorkState(1)" :disabled="settingWorkState">创建完成</button>
        <button v-if="workState==1&&($inRole('管理员')||$inRole('客栈编辑'))" type="button" class="btn btn-primary" @click="setWorkState(2)" :disabled="settingWorkState">开始拍摄</button>
        <button v-if="workState==2&&($inRole('管理员')||$inRole('客栈编辑'))" type="button" class="btn btn-primary" @click="setWorkState(3)" :disabled="settingWorkState">拍摄完成</button>
        <button v-if="workState==3&&($inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员'))" type="button" class="btn btn-primary" @click="setWorkState(4)" :disabled="settingWorkState">确认验收</button>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import { SimpleModal } from 'common'
  //import SimpleModal from '@/components/SimpleModal.vue'

  export default {
    components: {
      SimpleModal,
    },
    data() {
      return {
        list: null,
        workStates: [],
        workState: null,
        settingWorkState: false,
        downloading: false,
        loading: false,
        inn: null,
        roomCount: 0,
        panoCount: 0,
        tourItemCount: 0,
        tourGroupCount: 0,
        roomCountLoading: false,
        panoCountLoading: false,
        tourCountLoading: false,
        innLoading: false,
      }
    },
    created() {
      //this.getUser()
      //this.setPageInfo("主页")
      this.loadData()
      this.loadWorkStates()
      this.loadWorkState()
      this.getInn()
      this.getInnTourCount()
      this.getRoomCount()
      this.getPanoCount()
    },
    computed: {
      ...mapGetters([
        'setting',
      ]),
      ...mapState({
      }),
    },
    methods: {
      ...mapActions([
        //'getUser',
        // 'setPageInfo'
      ]),
      loadData() {
        this.loading = true;
        var config = {
          params: {
            category: '',
            page: 1,
            pageSize: 10,
          }
        };
        this.$axios.get('/Api/InnAdmin/InnInfos', config).then((res) => {
          //console.log(res.data)
          this.list = res.data.data
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        })
      },
      getInn() {
        this.innLoading = true;
        var config = {
          params: {
          }
        };
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Inns/${this.$route.params.innId}` : `/Api/InnAdmin/Inns/${this.$route.params.innId}`;
        this.$axios.get(url, config).then((res) => {
          //console.log(res.data)
          this.inn = res.data
          this.innLoading = false;
        }).catch(e => {
          this.innLoading = false;
        })
      },
      getRoomCount() {
        this.roomCountLoading = true;
        var config = {
          params: {
            //innId: this.$route.params.innId
          }
        };
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/InnRooms/${this.$route.params.innId}` : `/Api/InnAdmin/Rooms/${this.$route.params.innId}`;
        this.$axios.get(url, config).then((res) => {
          //console.log(res.data)
          this.roomCount = res.data.totalRecords
          this.roomCountLoading = false;
        }).catch(e => {
          this.roomCountLoading = false;
        })
      },
      getPanoCount() {
        this.getPanoCount = true;
        var config = {
          params: {
            innId: this.$route.params.innId
          }
        };
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Panos` : `/Api/InnAdmin/Panos`;
        this.$axios.get(url, config).then((res) => {
          //console.log(res.data)
          this.panoCount = res.data.totalRecords
          this.getPanoCount = false;
        }).catch(e => {
          this.getPanoCount = false;
        })
      },
      getInnTourCount() {
        this.tourCountLoading = true;
        var config = {
          params: {
          }
        };
        this.$axios.get(`/Api/InnAdmin/InnTours/${this.$route.params.innId}`, config).then((res) => {
          console.log(res.data)
          this.tourItemCount = res.data.items.length
          var group = {}
          var tourGroupCount = 0
          for (var i in res.data.items) {
            if (res.data.items[i].roomId) {
              if (!group[res.data.items[i].roomId]) {
                tourGroupCount++
                group[res.data.items[i].roomId] = true
              }
            }
          }
          this.tourGroupCount = tourGroupCount
          this.tourCountLoading = false;
        }).catch(e => {
          this.tourCountLoading = false;
        })
      },
      loadWorkStates() {
        //this.loading = true;
        var config = {
          params: {
          }
        };
        this.$axios.get('/Api/InnAdmin/Inns/WorkStates', config).then((res) => {
          //console.log(res.data)
          this.workStates = res.data
          //this.loading = false;
        }).catch(e => {
          //this.loading = false;
        })
      },
      loadWorkState() {
        //this.loading = true;
        var config = {
          params: {
          }
        };
        this.$axios.get(`/Api/InnAdmin/Inns/WorkState/${this.$route.params.innId}`, config).then((res) => {
          //console.log(res.data)
          this.workState = res.data
          //this.loading = false;
        }).catch(e => {
          //this.loading = false;
        })
      },
      showSetWorkState(state) {
        this.$refs.setWorkStateModal.show = true
      },
      setWorkState(state) {
        this.settingWorkState = true;
        var data = {
          WorkState: state
        };
        this.$axios.post(`/Api/InnAdmin/Inns/WorkState/${this.$route.params.innId}`, data).then((res) => {
          //console.log(res.data)
          this.workState = res.data
          this.settingWorkState = false;
          this.$refs.setWorkStateModal.show = false
        }).catch(e => {
          this.settingWorkState = false;
          this.$refs.setWorkStateModal.show = false
        })
      },
      exportRooms() {
        if (this.$isWeixin()) {
          this.$message({
            message: '在微信中不支持导出数据，请在手机浏览器或电脑中导出！',
            type: 'info',
            zIndex: 9999,
          })
          return
        }

        this.downloading = true;
        //this.$axios.get(`/Api/InnAdmin/Rooms/Export/${this.$route.params.innId}`).then((res) => {
        //    this.downloading = false;
        //}).catch(e => {
        //    this.downloading = false;
        //})
        this.$axios({
          method: 'get',
          url: `/Api/InnAdmin/Rooms/Export/${this.$route.params.innId}`,
          data: {},
          responseType: 'arraybuffer',
        }).then((res) => {
          this.downloading = false;
          var disposition = res.headers['content-disposition']
          //var fileName = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length))
          let fileName = `${this.$parent.$parent.inn.title}.xlsx`
          let blob = new Blob([res.data], { type: res.headers['content-type'] })
          let objectUrl = URL.createObjectURL(blob)
          //let file = new File([res.data], fileName, { type: res.headers['content-type'] });
          //let objectUrl = URL.createObjectURL(file)
          //window.location.assign(objectUrl)
          let downloadElement = document.createElement('a')
          downloadElement.href = objectUrl;
          downloadElement.download = fileName; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); //释放blob对象
        }).catch(e => {
          this.downloading = false;
        })
      }
    },
  }
</script>

<style lang="css" scoped>
  .stepwizard-step p {
    margin-top: 0px;
    color: #666;
  }

  .stepwizard-row {
    display: table-row;
  }

  .stepwizard {
    display: table;
    width: 100%;
    position: relative;
  }

  .stepwizard-step button[disabled] {
    /*opacity: 1 !important;
    filter: alpha(opacity=100) !important;*/
  }

  .stepwizard .btn.disabled, .stepwizard .btn[disabled], .stepwizard fieldset[disabled] .btn {
    opacity: 1 !important;
    color: #bbb;
  }

  .stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-index: 0;
  }

  .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }
</style>
