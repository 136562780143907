<template lang="html">
  <div>
    <div class="card card-primary card-outline box-primary">
      <!--<div class="overlay" v-if="$store.state.loading">
          <i class="fa fa-refresh fa-spin"></i>
      </div>-->
      <div class="card-header p-1">
        <h3 class="card-title">信息编辑</h3>
      </div>
      <div class="card-body">
        <span v-if="updateing">加载中</span>
        <template>
          <div class="form-group">
            <label for="category">分类</label>
            <select class="form-control" :class="{ 'is-invalid': $v.form.categoryGUID.$error }" id="category" v-model="$v.form.categoryGUID.$model">
              <option :value="null">-=请选择=-</option>
              <option v-for="i in categorys" :value="i.id">{{i.text}}</option>
            </select>
            <div class="invalid-feedback">
              <span v-if="!$v.form.categoryGUID.required">请选择分类</span>
            </div>
          </div>
          <div class="form-group">
            <label for="title">标题</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入标题">
            <div class="invalid-feedback">
              <span v-if="!$v.form.title.required">请输入标题</span>
              <span v-if="!$v.form.title.minLength">标题至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.form.title.maxLength">标题最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <editor ref="editor"
                  v-model="form.text"
                  :onUploadComplete="onUploadComplete"
                  :toolbarItems="toolbarItems"
                  placeholder="在这里输入内容!" />
          <EditorFileList :editor="$refs.editor" :followEl="$refs.editor" :images="form.images" :attachments="form.attachments"></EditorFileList>
          <!--<div class="form-group">
              <label for="description">摘要</label>
              <textarea type="text" class="form-control" id="description" placeholder="输入摘要" v-model:text="form.description"></textarea>
          </div>
          <div class="form-group">
              <label for="writer">作者</label>
              <input type="text" class="form-control" id="writer" placeholder="输入作者" v-model:text="form.writer" />
          </div>
          <div class="form-group">
              <label for="writerFrom">来源</label>
              <input type="text" class="form-control" id="writerFrom" placeholder="输入来源" v-model:text="form.writerFrom" />
          </div>-->
          <!--<div class="form-group">
              <label for="panoTitle">标签</label>
              <CheckBox checkprop="name"
                        :checkList="tags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
          </div>-->
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="form.closed">
              关闭
            </label>
          </div>
        </template>
      </div>
      <div class="card-footer">
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default" @click.prevent="Close">取消</button>
      <button type="button" @click.prevent="save()" class="btn btn-primary" :disabled="updateing">保存</button>
    </div>
    <BreakLeave v-if="cantleave"></BreakLeave>
  </div>
</template>

<script>
  import Editor from '@/components/Editor.vue'
  import { EditorFileList, CheckBox, BreakLeave } from 'common'
  //import EditorFileList from '@/components/EditorFileList.vue'
  //import CheckBox from '@/components/CheckBox.vue'
  //import BreakLeave from '@/components/BreakLeave'
  import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
  export default {
    components: {
      Editor,
      CheckBox,
      EditorFileList,
      BreakLeave,
    },
    data() {
      return {
        id: this.$route.params.infoId,
        form: {
          guid: null,
          categoryGUID: null,
          innId: this.$route.params.innId,
          title: '',
          text: '',
          description: '',
          writer: '',
          writerFrom: '',
          addTime: null,
          tags: [],
          images: [],
          attachments: [],
          cover: null,
          closed: false,
        },
        categorys: [],
        coverImg: null,
        fov: 120,
        hLookAt: 0,
        vLookAt: 0,
        hotspots: [],
        //distortion: 0,
        tags: [{ name: '旅行' }, { name: '自驾' }, { name: '户外' }, { name: '生活' }],
        updateing: false,
        imageOnDrag: false,
        imageDropIndex: null,
        cantleave: true,
        toolbarItems: [
          //'heading',
          //'|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'alignment',
          'outdent', 'indent',
          'bulletedList', 'numberedList',
          //'highlight',
          '|',
          'link',
          'imageUpload',
          //'ckfinder',
          //'mediaEmbed',
          'insertTable',
          '|',
          //'blockQuote',
          //'code',
          'removeFormat',
          'undo',
          'redo'
        ]
      }
    },
    validations: {
      form: {
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        },
        categoryGUID: {
          required
        },
      }
    },
    created() {
      this.loadData()

    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
      loadData() {
        this.loadCategorys()
        var _this = this
        console.log(this.id)
        if (this.id) {
          this.$axios.get('/Api/InnAdmin/InnInfos/' + this.id)
            .then(function (response) {
              //console.log(response)
              let val = response.data
              _this.form = val;
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      },
      loadCategorys() {
        let self = this
        this.$axios.get('/Api/Categorys/InnInfo')
          .then(function (response) {
            //console.log(response)
            self.categorys = response.data
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      onUploadComplete(date) {
        console.log(date)
        this.form.images = [...this.form.images, date]
      },
      save() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let _this = this
        this.updateing = true;
        if (this.id) {
          this.$axios.put('/Api/InnAdmin/InnInfos/' + _this.form.guid, _this.form)
            .then(function (response) {
              _this.cantleave = false
              _this.Close()
              _this.updateing = false;
            })
            .catch(function (error) {
              console.log(error)
              _this.updateing = false;
            })

        } else {
          this.$axios.post('/Api/InnAdmin/InnInfos', _this.form)
            .then(function (response) {
              _this.cantleave = false
              _this.Close()
              _this.updateing = false;
            })
            .catch(function (error) {
              console.log(error)
              _this.updateing = false;
            })
        }
      },
      Close() {
        this.$router.push({ name: 'InnInnInfoList', params: {} })
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
    },
    watch: {
    },

  }
</script>
