<template lang="html">
    <div class="blogView">
        <component :is="blogComp" :blog="form" :toPano="toPano"></component>
    </div>
</template>

<script>
    import blog from 'common/src/components/BlogSkins/Default'

    export default {

        data() {
            return {
                form: null,
                blogComp: blog,
            }
        },
        created() {
            this.loadData()
        },
        mounted() {
        },
        computed: {
        },
        watch: {
        },
        methods: {

            loadData() {
                var _this = this
                if (this.$route.params.blogId) {
                    this.$axios.get('/Api/InnAdmin/Blogs/' + _this.$route.params.blogId)
                        .then(res => {
                            _this.form = res.data;
                        })
                }
            },
            toPano(guid) {
                this.$router.push({ name: 'PanoFullScreen', params: { panoId: guid } })
            }
        },
        computed: {
        },

        watch: {
        },
        components: {
        },
    }
</script>
<style>
</style>
