<template>
  <div class="PanoFullScreen" :class="leaveclass">
    <div id="pano" style="width:100%;height:100%;">
    </div>
    <div class="textbox" v-if="textbox&&textbox.show" @click="textbox.show=false">
      <template v-if="textbox.type=='text'">
        <div class="textBoxtext" @click="(event)=>{event.stopPropagation()}">
          <div class="textArea">
            <button class="closetxt" @click="textbox.show=false">关闭</button>
            <div style="">
              <h3 style="display: inline-block;">{{textbox.title}}</h3>
            </div>
            <hr style="margin-top: 5px;margin-bottom: 5px;border: 0;border-top: 1px solid #eee;" />
            <div class="textboxbody">
              <p>{{textbox.val}}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-if="textbox.type=='image'">
        <img class="textBoximage" :src="textbox.val" @click="(event)=>{event.stopPropagation()}" />
      </template>
      <template v-if="textbox.type=='video'">
        <video class="textBoximage" :src="textbox.val" :poster="textbox.val.replace('/0/0/0/0/', '/600/0/0/0/')" preload="none" controls controlslist="nodownload" @click="(event)=>{event.stopPropagation()}"></video>
      </template>
    </div>
  </div>
</template>
<script>
  import remoteLoad from 'common/src/libs/remoteLoad.js'
  export default {
    data() {
      return {
        panoId: this.$route.params.panoId,
        krpano: null,
        pano: null,
        textbox: {
          show: false,
          title: '',
          type: 'text',
          val: null
        },
        leaveclass: '',
      }
    },
    created() {
    },
    mounted() {
      var _this = this;
      console.log('mounted', this.panoId)

      remoteLoad(`/lib/krpano/krpano.js`).then(function () {
        _this.loadPano(_this.panoId)
      })
      window.boxPanoHotspotOnnewscene = () => {
        console.log('s1');
        _this.setAllHotspot();
        if (_this.$route.query.fov)
          _this.krpano.set("view.fov", _this.$route.query.fov);
        if (_this.$route.query.hLookAt)
          _this.krpano.set("view.hlookat", _this.$route.query.hLookAt);
        if (_this.$route.query.vLookAt)
          _this.krpano.set("view.vlookat", _this.$route.query.vLookAt);
      }
      window.loadPanoFullScreen = (panoId, fov, hLookAt, vLookAt) => {
        //_this.leaveclass = 'leave'
        _this.$router.push({ name: 'PanoFullScreen', params: { panoId: panoId }, query: { fov, hLookAt, vLookAt } });
        //setTimeout(() => {
        //    //_this.leaveclass = '';
        //}, 2000)
        //console.log('krpano', newval)
        //if (this.krpano) {
        //    this.krpano.call("loadpanoscene(" + window.global.API_BASE_URL + "/Api/Panos/Xml/" + newval + "?noskin=true&r=" + Math.random() + ",Scene_" + newval + ");");
        //}
      }
      window.PanoFullScreenShowTextBox = (title, type, val) => {
        this.showTextbox(title, type, val)
      }
      window.PanoFullScreenViewChange = (fov, h, v) => {
        //console.log(fov, h, v)
        //var nextUpdatedQuery;
        //if (window.location.search) {
        //    console.log(urlQuery())
        //    nextUpdatedQuery = urlQueryUpdater({
        //        query: urlQuery(),
        //        updates: {
        //            fov: fov,
        //            h: h,
        //            v: v,
        //        },
        //        shouldIncludePrefix: true
        //    });
        //} else {
        //    nextUpdatedQuery = urlQueryUpdater({
        //        query: {
        //            fov: fov,
        //            h: h,
        //            v: v,
        //        },
        //        shouldIncludePrefix: true
        //    });
        //}
        //console.log(nextUpdatedQuery)

        //let q = new URLQueryBuilder(window.location.href)
        //q.set({
        //    fov: fov,
        //    h: h,
        //    v: v,
        //})
        //console.log(q.get())


        //window.location.search = nextUpdatedQuery
        //this.$route.query.hlookat = h;
        //this.$route.query.vlookat = v;
      }
    },
    methods: {
      showTextbox(title, type, val) {
        var _this = this;
        _this.textbox.show = true;
        _this.textbox.title = title;
        _this.textbox.type = type;
        _this.textbox.val = val;
      },
      loadPano(panoId) {
        var _this = this
        this.$axios.get('/Api/Panos/' + this.panoId)
          .then(function (response) {
            _this.pano = response.data;
            console.log('s4');
            _this.setAllHotspot();
            if (_this.pano.text && _this.pano.text != '')
              _this.showTextbox(_this.pano.title, 'text', _this.pano.text)
          })
        console.log("123", panoId);
        var settings = {}
        //settings["onstart"] = "copy(area.width,stagewidth);copy(area.height,stageheight);";
        settings["showerrors"] = true;
        //settings["view.fov"] = _this.fov;
        //settings["view.hlookat"] = _this.hlookat;
        //settings["view.vLookAt"] = _this.vLookAt;
        //settings["autorotate.enabled"] = true;
        //settings["autorotate.waittime"] = 5;
        //settings["autorotate.speed"] = 5;
        //settings["events.onmousedown"] = "events.onviewchange();";
        //settings["events.onviewchange"] = "js( getPanoView() );";
        settings["events.onloadcomplete"] = 'js(boxPanoHotspotOnnewscene())';
        //settings["events.onviewchange"] = "js(PanoFullScreenViewChange(get('view.fov'),get('view.hlookat'),get('view.vlookat')))";
        settings["lockmultireslevel.mobile"] = 1;
        settings["view.maxpixelzoom.mobile"] = 0.75;
        settings["loadscene_blend_prev"] = "ZOOMBLEND(2.0, 2.0, easeInOutSine)";
        settings["loadscene_blend_next"] = "ZOOMBLEND(2.0, 2.0, easeInOutSine)";
        console.log(panoId, typeof embedpano)
        if (panoId && typeof embedpano == 'function') {

          console.log(embedpano)
          embedpano({
            swf: "/lib/krpano/krpano.swf",
            xml: window.global.API_BASE_URL + "/Api/Panos/Xml/" + panoId + "?noskin=true&r=" + Math.random(),
            target: 'pano',
            id: 'panoview',
            html5: "prefer",
            vars: settings,
            mobilescale: _this.mobilescale(),
            initvars: { skin: "%SWFPATH%/skin/panorover.src.xml?r=" + Math.random() },
            consolelog: true,
            passQueryParameters: true,
            onready: krpanoReady,
            onerror: krpanoError
          });
        }

        function krpanoReady(krpano) {
          _this.krpano = krpano;
          console.log('s2')
          _this.setAllHotspot();
          //setTimeout(function () {
          //  _this.fov = _this.form.fov
          //  _this.hLookAt = _this.form.hLookAt
          //  _this.vLookAt = _this.form.vLookAt
          //  krpano.set("view.fov", _this.fov);
          //  krpano.set("view.hlookat", _this.hLookAt);
          //  krpano.set("view.vlookat", _this.vLookAt);
          //}, 500);
        }
        function krpanoError(krpano) {
          console.log(krpano)
          //krpano.call("trace(krpano is error...)");
        }

      },
      mobilescale() {
        var scale = 0.55;
        if (navigator.userAgent.indexOf("Android") >= 0 && navigator.userAgent.indexOf("MQQBrowser") >= 0) {
          if (navigator.userAgent.indexOf("TBS") >= 0) {
            var value = scale * 100 / (screen.width * window.devicePixelRatio / 10);
            if (value > scale) return value * scale;
            if (value < scale) return scale;
            return value;
          }
        }
        if (navigator.userAgent.indexOf("UCBrowser") >= 0) {
          var value = scale * 100 / (screen.width * window.devicePixelRatio / 10);
          if (value > scale) return value * scale;
          return value;
        }
        return scale;
      },
      setAllHotspot() {
        if (this.pano) {
          for (var i in this.pano.hotspots) {
            console.log(i)
            this.setHotspot(this.pano.hotspots[i])
          }
        }
      },
      setHotspot(hotspot) {
        if (this.krpano) {
          console.log('setHotspot')
          if (hotspot.icon == null) {
            hotspot.icon = {
              guid: null,
              width: 0,
              height: 0,
              fps: 0,
              isAnimation: false,
              edge: 'top',
              imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            }
          }
          var name = "hotspot_" + hotspot.guid
          var oldval = this.krpano.get("hotspot[" + name + "]")
          if (oldval) {
            this.krpano.call("removehotspot(" + name + ")");
          }
          this.krpano.call("addhotspot(" + name + ")")
          //console.log(hotspot, oldval);
          //this.krpano.call("hotspot[" + hotspot.name + "].loadstyle(skin_hotspotstyle);");
          //this.krpano.set("hotspot[" + name + "].ondown", "dragHotspot();");
          //console.log("hotspot", hotspot.linkItem)
          this.krpano.set("hotspot[" + name + "].onclick", this.getLinkClickStr(hotspot));

          //if (hotspot.linkItem) {
          //    //    this.krpano.set("hotspot[" + name + "].onclick", "loadpanoscene(" + window.global.API_BASE_URL + "/Api/Panos/Xml/" + hotspot.linkItem.guid + "?noskin=true&r=" + Math.random() + ",Scene_" + hotspot.linkItem.guid + ");");
          //    this.krpano.set("hotspot[" + name + "].onclick", "js(loadPanoFullScreen(" + hotspot.linkItem.guid + "))");
          //}
          this.krpano.set("hotspot[" + name + "].id", hotspot.guid);
          if (hotspot.icon.isAnimation) {
            this.krpano.set("hotspot[" + name + "].onloaded", "do_crop_animation(" + hotspot.icon.width + "," + hotspot.icon.height + "," + hotspot.icon.fps + ")");
          }
          this.krpano.set("hotspot[" + name + "].ath", hotspot.ath);
          this.krpano.set("hotspot[" + name + "].atv", hotspot.atv);
          this.krpano.set("hotspot[" + name + "].distorted", hotspot.distorted);

          this.krpano.set("hotspot[" + name + "].url", hotspot.icon.imageUrl);
          this.krpano.set("hotspot[" + name + "].scale", hotspot.scale);
          this.krpano.set("hotspot[" + name + "].zoom", hotspot.zoom);
          this.krpano.set("hotspot[" + name + "].rotate", hotspot.rotate);
          //console.log(hotspot.icon.edge, 'edge')
          this.krpano.set("hotspot[" + name + "].edge", hotspot.icon.edge);
          //console.log(hotspot.icon.enableText, hotspot.title)
          if (hotspot.showTitle && hotspot.title != null && hotspot.title != '') {
            var oldtxtval = this.krpano.get("layer[" + name + "txt]")
            if (oldtxtval) {
              this.krpano.call("removelayer(" + name + "txt)");
            }
            this.krpano.call("addlayer(" + name + "_txt)")
            this.krpano.set("layer[" + name + "_txt].id", hotspot.guid + 'txt');
            this.krpano.set("layer[" + name + "_txt].type", 'text');
            this.krpano.set("layer[" + name + "_txt].html", hotspot.title);
            this.krpano.set("layer[" + name + "_txt].vcenter", "true");
            this.krpano.set("layer[" + name + "_txt].padding", "0");
            this.krpano.set("layer[" + name + "_txt].wordwrap", "false");
            this.krpano.set("layer[" + name + "_txt].mergedalpha", "true");
            this.krpano.set("layer[" + name + "_txt].mergedalpha", "true");
            this.krpano.set("layer[" + name + "_txt].distorted", hotspot.distorted);
            this.krpano.set("layer[" + name + "_txt].css", 'font-size:16px; color:#FFF;text-shadow:1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000;');
            // this.krpano.set("layer[" + name + "_txt].txtshadow", '1 1 0 0xFFFFFF 1');
            this.krpano.set("layer[" + name + "_txt].bg", 'false');
            this.krpano.set("layer[" + name + "_txt].parent", "hotspot[" + name + "]");
            //this.krpano.set("hotspot[" + name + "].ondown", "dragHotspot();");
            this.krpano.set("layer[" + name + "_txt].onclick", this.getLinkClickStr(hotspot));

            //switch (hotspot.linkType) {
            //    case 0:
            //        if (hotspot.linkItem) {
            //            this.krpano.set("layer[" + name + "_txt].onclick", "js(loadPanoFullScreen(" + hotspot.linkItem.guid + "))");
            //        }
            //        break;
            //    case 1:
            //        break;
            //    case 2:
            //        this.krpano.set("layer[" + name + "_txt].onclick", "js(PanoFullScreenShowTextBox(" + hotspot.title + ",'text'," + hotspot.description + "))");
            //        break;
            //    default:
            //}
            //this.krpano.set("layer[" + name + "_txt].scale", hotspot.scale);
            //this.krpano.set("layer[" + name + "_txt].ox", hotspot.icon.textOffsetX * hotspot.scale);
            //this.krpano.set("layer[" + name + "_txt].oy", hotspot.icon.textOffsetY * hotspot.scale);

            var p = [hotspot.icon.textOffsetX, hotspot.icon.textOffsetY, (parseFloat(hotspot.rotate)) * Math.PI / 180]
            //console.log(p)
            p = this.changeAngle(p)
            //console.log(p)
            this.krpano.set("layer[" + name + "_txt].ox", p[0] * hotspot.scale);
            this.krpano.set("layer[" + name + "_txt].oy", p[1] * hotspot.scale);
            this.krpano.set("layer[" + name + "_txt].zoom", hotspot.zoom);
            this.krpano.set("layer[" + name + "_txt].rotate", -hotspot.rotate);
            var edge = this.calcEdge(hotspot.icon.textEdge, parseFloat(hotspot.rotate))
            //console.log(edge, 'layeredge')
            this.krpano.set("layer[" + name + "_txt].edge", edge);
            //console.log(this.calcEdge(hotspot.icon.edge, 180), 'align')
            this.krpano.set("layer[" + name + "_txt].align", this.calcEdge(hotspot.icon.edge, 0));
            //var ps = {
            //    'top': ['0%', '-50%'],
            //    'righttop': ['50%', '-50%'],
            //    'right': ['50%', '0%'],
            //    'rightbottom': ['50%', '50%'],
            //    'bottom': ['0%', '50%'],
            //    'leftbottom': ['-50%', '50%'],
            //    'left': ['-50%', '0%'],
            //    'lefttop': ['-50%', '-50%']
            //}
            //this.krpano.set("layer[" + name + "_txt].x", ps[edge][0]);
            //this.krpano.set("layer[" + name + "_txt].y", ps[edge][1]);
          } else {
            console.log(name, 'delete')
            this.krpano.call("removelayer(" + name + "_txt)");
          }
        }
      },
      getLinkClickStr(hotspot) {
        var str = ''
        switch (hotspot.linkType) {
          case 0:
            if (hotspot.linkItem) {
              str = "js(loadPanoFullScreen(" + hotspot.linkItem.guid + "," + hotspot.linkFOV + "," + hotspot.linkHLookAt + "," + hotspot.linkVLookAt + "))"
            }
            break;
          case 1:
            if (hotspot.file) {
              if (hotspot.file.isImage) {
                str = "js(PanoFullScreenShowTextBox('" + hotspot.title + "','image','" + hotspot.file.url + "'))"
              } else if (hotspot.file.isVideo) {
                str = "js(PanoFullScreenShowTextBox('" + hotspot.title + "','video','" + hotspot.file.url + "'))"
              }
            }
            break;
          case 2:
            str = "js(PanoFullScreenShowTextBox('" + hotspot.title + "','text','" + hotspot.description + "'))"
            break;
          default:
        }
        console.log('getLinkClickStr', str)
        return str;
      },
      changeAngle(param) {
        //console.log(param[1] != 0, 1)
        if (param[0] != 0 || param[1] != 0) {
          //console.log(param, 2)
          var x = param[0];
          var y = param[1];
          var tha1 = param[2];

          var value = Math.sqrt(x * x + y * y);

          var cos1 = x / value;
          var sin1 = y / value;

          var cos2 = Math.cos(tha1);
          var sin2 = Math.sin(tha1);

          var cos3 = cos1 * cos2 - sin1 * sin2;
          var sin3 = sin1 * cos2 + cos1 * sin2;
          param[0] = (value * cos3).toFixed(2);
          param[1] = (value * sin3).toFixed(2);
          return param;
        }
        return param
      },
      calcEdge(edge, angle) {
        var e;
        var fx = ['top', 'righttop', 'right', 'rightbottom', 'bottom', 'leftbottom', 'left', 'lefttop']
        e = fx.findIndex((val) => val == edge)
        if (e < 0) {
          return edge;
        }
        var offset = 22.5
        var c = parseFloat(angle) + offset
        var f = Math.floor(c / 45);
        f = e + f
        do {
          if (f >= 0 && f < 8) {
            break;
          }
          if (f < 0) {
            f += 8;
          }
          if (f >= 8) {
            f -= 8;
          }
        } while (true);
        return fx[f]
      },

      //全屏
      fullScreen() {
        var el = document.documentElement;
        var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
        };
        return;
      },
      //退出全屏
      exitScreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        //if (typeof cfs != "undefined" && cfs) {
        //    cfs.call(el);
        //}
      },
    },

    watch: {

    },
    beforeRouteUpdate(to, from, next) {
      var _this = this
      if (this.krpano) {
        this.panoId = to.params.panoId;
        setTimeout(() => {
          this.krpano.call("loadpanoscene(" + window.global.API_BASE_URL + "/Api/Panos/Xml/" + to.params.panoId + "?noskin=true&r=" + Math.random() + ",Scene_" + to.params.panoId + ",null,null,ZOOMBLEND(2.0, 2.0, easeInOutSine));");
          //this.krpano.call("loadpano(" + window.global.API_BASE_URL + "/Api/Panos/Xml/" + to.params.panoId + "?noskin=true&r=" + Math.random() + ",Scene_" + to.params.panoId + ");");
          //this.krpano.call("skin_nextscene_loop(+1);");
          this.$axios.get('/Api/Panos/' + this.panoId)
            .then(function (response) {
              _this.pano = response.data;
              console.log('s4');
              _this.setAllHotspot();

            })
        }, 1)
        next();
      }
    },
  }
</script>
<style>


  .PanoFullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }

    .PanoFullScreen .closetxt {
      background-color: transparent;
      border: none;
      font-size: 12px;
      opacity: 1;
      float: right;
      color: white;
    }

    .PanoFullScreen.leave {
      transition: opacity 2s,transform 2s;
      transform: scale(1.1);
      opacity: 0;
    }

    .PanoFullScreen .textbox {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .PanoFullScreen .textBoxtext {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 450px;
      max-width: 85%;
      height: 350px;
      max-height: 60%;
      background-color: rgba(0, 0, 0, 0.37);
      padding: 10px;
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.5);
      /* text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000; */
      -webkit-box-shadow: rgba(255, 255, 255, 0.19) 5px 5px 5px;
      box-shadow: rgba(0, 0, 0, 0.19) 5px 5px 5px;
    }

    .PanoFullScreen .textBoximage {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 75%;
      max-width: 85%;
      background-color: rgba(0, 0, 0, 0.45)
    }

    .PanoFullScreen .textBoximage {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 600px;
      max-height: 75%;
      max-width: 85%;
      background-color: rgba(0, 0, 0, 0.45)
    }

    .PanoFullScreen .textArea {
      width: 100%;
      height: 100%;
      padding: 5px;
    }

    .PanoFullScreen .textboxbody {
      height: calc(100% - 55px);
      overflow-wrap: break-word;
      overflow-y: auto;
    }
</style>
