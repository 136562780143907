<template lang="html">
    <div>
        <div class="alert alert-info" role="alert">
            <h5 class="alert-heading">联系与帮助</h5>
            <p style="text-align:justify;text-indent:2em;">
                可以通过本页面直接会话与本站交流，实时会话窗口可能因为人员不在电脑前而回复滞后，承诺24小时内给予答复，注意登录后台查看信息。
                欢迎寻求使用帮助、提供建议意见及探讨商务可能。
            </p>
        </div>
        <div v-if="setting" class="row">
            <div class="col-md-3 col-sm-6 col-12">
                <div class="info-box">
                    <span class="info-box-icon bg-info"><i class="fas fa-envelope"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">联系与帮助</span>
                        <a :href="setting.myUrl+'/Chat/kmnet'" class="info-box-number text-right">
                            <span class="btn btn-sm btn-outline-info">
                                <i class="fas fa-comments"></i>
                                kmnet
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="info-box">
                    <span class="info-box-icon bg-info"><i class="fas fa-bug"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Bug 报告</span>
                        <a :href="setting.myUrl+'/Chat/SNS'" class="info-box-number text-right">
                            <span class="btn btn-sm btn-outline-info">
                                <i class="fas fa-comments"></i>
                                SNS
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="setting" class="row">
            <div class="col-md-3 col-sm-6 col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-info"></i>
                            使用帮助
                        </h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <ul>
                            <li v-for="i in list">
                                <a :href="setting.siteUrl+'/B/'+i.key" target="_blank">
                                    {{i.title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                list: [],
                pager: {
                    prePageSize: [10, 20, 50],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 10,
                    sort: "Id",
                    order: "desc",
                    fields: 'Title',
                    key: null,
                    tag: null,
                    category: '使用帮助',
                    state: null,
                    private: null,
                    fine: null,
                    featured: null,
                },
          }
        },
        created() {
        },
        mounted() {
            this.loadData() 
        },
        destroyed() {
        },
        computed: {
            ...mapGetters([
                'setting',
            ]),
      },
        methods: {
            loadData() {
                this.$axios.get("/Api/Blogs", {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key,
                        tag: this.query.tag,
                        category: this.query.category,
                        state: this.query.state,
                        private: this.query.private ? true : null,
                        fine: this.query.fine ? true : null,
                        featured: this.query.featured ? true : null,
                    }
                }).then((res) => {
                    this.pager.pageIndex = res.data.pageIndex;
                    this.pager.pageSize = res.data.pageSize;
                    this.pager.totalPages = res.data.totalPages;
                    this.pager.totalRecords = res.data.totalRecords;
                    this.list = res.data.data;
                })
            },
        },
        components: {
        }
    }
</script>

<style lang="css">
</style>
